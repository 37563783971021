import axios from "axios";

const logout = async (): Promise<boolean | void> => {
    try {
      await axios(`/api/oauth/logout`,
        {
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          }
        }
      );

      return true;
    } catch (e) {
      throw e;
    }
  }


export default logout;
