'use client';
import React, { useState, useRef, useEffect } from 'react';

// Components
import Icon from '@/common/Icon/index.client';
import Text from '@/common/Text/index.server';

// Styles
import style from './index.module.sass';

interface IOption {
    value: string;
    label: string;
}

interface ICustomSelect {
    label?: string;
    options: IOption[];
    defaultOption?: IOption;
    onChange: (option: IOption) => void;
    selectPlaceholder?: string;
    bordered?: boolean;
    classNames?: {
        wrapper?: string;
        label?: string;
    };
}

const CustomSelect = (props: ICustomSelect): JSX.Element => {
    const {
        label = '',
        options,
        defaultOption = null,
        onChange,
        selectPlaceholder = '',
        bordered = false,
        classNames: {
            wrapper = '',
            label: labelClass = ''
        } = {}
    } = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<IOption | null>(defaultOption);
    const selectRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => setIsOpen((prev) => !prev);

    const handleOptionClick = (option: IOption) => {
        setSelectedOption(option);
        setIsOpen(false);
        onChange(option);
    };

    return (
        <div>
            {label ? (
                <label className={`${labelClass} fl font--family-copperplate font--400 font--uppercase text--small color--extra-gray margin--b-8`}>
                    {label}
                </label>
            ) : null}
            <div className={`${style['custom-select']} ${bordered ? style['custom-select--bordered'] : ''} ${wrapper} ${isOpen ? style['custom-select--open'] : ''}`} ref={selectRef}>
                <div
                    className={`${style['custom-select__selected']} ${bordered ? style['custom-select__selected--bordered'] : 'link--secondary'} ${isOpen && !bordered ? 'link--backlight' : ''} cursor--custom-pointer fl fl--gap-8 fl--align-c font--family-ubuntu font--capitalize`}
                    onClick={toggleDropdown}
                >
                    <Text size="small" color={isOpen ? 'light' : 'medium-gray'}>{selectedOption?.label || selectPlaceholder}</Text>
                    <Icon
                        classNames={{
                            wrapper: bordered ? 'margin--l-auto' : '',
                            icon: `${style['custom-select__icon']} ${isOpen ? style['custom-select__icon--active'] : ''}`
                        }}
                        icon='flat/arrow-down'
                        color={isOpen ? 'color--white' : 'color--medium-gray'}
                        size={16}
                    />
                </div>
                {isOpen ? (
                    <div className={`${style['custom-select__options-list']} ${bordered ? style['custom-select__options-list--bordered'] : ''} fl fl--dir-col fl--gap-18`}>
                        {options?.map((option) => (
                            <Text
                                key={option.value}
                                size='small'
                                color='light'
                                className={`${style['custom-select__option']} width--fit-content cursor--custom-pointer`}
                                onClick={() => handleOptionClick(option)}
                            >
                                {option.label}
                            </Text>
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default CustomSelect;
