import styles from './index.module.sass'

interface IProps {
    className?: string;
}

const HorizontalRule = (props: IProps) => {
    const { className } = props;
    return (
        <hr className={`${styles.hr} ${className || ''}`} />
    )
}

export default HorizontalRule;