"use client";
import { useState, useEffect } from "react";
import Link from "next/link";
import Image from 'next/image';
import { useMediaQuery } from 'react-responsive'

// Components
import Text from '@/common/Text/index.server';
import SlidesAnimation from '@/components/Animations/Slides/index.client';
import FadeInAnimation from '@/components/Animations/FadeIn/index.client';
import Button from "@/common/Button/index.client";

// Types
import { ITextContent } from "@/types/text.types";

// Helpers
import getFullPath from "@/helpers/getFullPath.hl";

// Icons
import gamePlayIcon from './gameplay.svg';
import visionIcon from './vision.svg';
import tokonomicsIcon from './tokenomics.svg';
import universeIcon from './universe.svg';

// Styles
import aboutStyles from './index.module.sass';

const About = (props: { text: ITextContent, translation: ITextContent, lang: string }) => {
    const {
        text: {
            title,
            metaData: {
                json: {
                    subtitle,
                    gameplay,
                    vision,
                    universe,
                    tokenomics
                } = {}
            } = {}
        } = {},
        translation: {
            metaData: {
                json: {
                    discoverTheGame = ''
                } = {}
            } = {}
        } = {},
        lang = ''
    } = props;
    const [isMounted, setIsMounted] = useState(false);

    const isLowerMode = useMediaQuery({
        query: '(max-width: 1440px)'
    })

    useEffect(() => {
        setIsMounted(true);
    }, [])

    return (
        <div id="about" className={aboutStyles.about}>
            <span className={aboutStyles.about__sun}></span>
            <div className="container fl fl--justify-c fl--dir-col fl--align-c">
                <div className={`${aboutStyles.about__content} width--100 fl fl--justify-b`}>
                    <FadeInAnimation delay={0.3}>
                        <Text tag="h1" size="x-default-subtitle" className={`${aboutStyles.about__subtitle} font--family-copperplate font--uppercase`}>{subtitle}</Text>
                    </FadeInAnimation>
                    <FadeInAnimation delay={0.3}>
                        <Text size="z-large" tag="h2" className={`${aboutStyles.about__title} font--family-copperplate font--uppercase`}>
                            {title?.split('|')?.[0]}
                            <span className={aboutStyles['about__title-second-part']}>
                                {title?.split('|')?.[1]}
                            </span>
                        </Text>
                    </FadeInAnimation>
                </div>
                {
                    isMounted ? (
                        <div className={`${aboutStyles.about__wrapper} width--100 fl fl--justify-b fl--align-c`}>
                            <SlidesAnimation
                                delay={0.4}
                                coordinates={{ x: isLowerMode ? 0 : -100, y: isLowerMode ? 100 : 0 }}
                            >
                                <div className={`${aboutStyles.about__card} ${aboutStyles['about__card--gameplay']}`}>
                                    <Image className={aboutStyles.about__icons} src={gamePlayIcon} alt={gameplay?.title}></Image>
                                    <Text size="big" tag="h3" className={`${aboutStyles['about__card-title']} font--uppercase font--family-copperplate`}>{gameplay?.title}</Text>
                                    <Text color="text-white">{gameplay?.description}</Text>
                                </div>
                            </SlidesAnimation>
                            <div>
                                <SlidesAnimation
                                    delay={isLowerMode ? 0.5 : 0.4}
                                    margin={isLowerMode ? "0px" : "-200px"}
                                    coordinates={{ y: isLowerMode ? 100 : -100 }}
                                >
                                    <div className={`${aboutStyles.about__card} ${aboutStyles['about__card--vision']}`}>
                                        <Image className={aboutStyles.about__icons} src={visionIcon} alt={vision?.title}></Image>
                                        <Text size="big" tag="h3" className={`${aboutStyles['about__card-title']} font--uppercase font--family-copperplate`}>{vision?.title}</Text>
                                        <Text color="text-white">{vision?.description}</Text>
                                    </div>
                                </SlidesAnimation>
                                <SlidesAnimation
                                    delay={isLowerMode ? 0.6 : 0.4}
                                    margin={isLowerMode ? "0px" : "200px"}
                                    coordinates={{ y: 100 }}
                                >
                                    <div className={`${aboutStyles.about__card} ${aboutStyles['about__card--tokenomics']}`}>
                                        <Image className={aboutStyles.about__icons} src={tokonomicsIcon} alt={tokenomics?.title}></Image>
                                        <Text size="big" tag="h3" className={`${aboutStyles['about__card-title']} font--uppercase font--family-copperplate`}>{tokenomics?.title}</Text>
                                        <Text color="text-white">{tokenomics?.description}</Text>
                                    </div>
                                </SlidesAnimation>
                            </div>
                            <SlidesAnimation
                                delay={isLowerMode ? 0.8 : 0.4}
                                coordinates={{ x: isLowerMode ? 0 : 100, y: isLowerMode ? 100 : 0 }}
                            >
                                <div className={`${aboutStyles.about__card} ${aboutStyles['about__card--universe']}`}>
                                    <Image className={aboutStyles.about__icons} src={universeIcon} alt={universe?.title}></Image>
                                    <Text size="big" tag="h3" className={`${aboutStyles['about__card-title']} font--uppercase font--family-copperplate`}>{universe?.title}</Text>
                                    <Text color="text-white">{universe?.description}</Text>
                                </div>
                            </SlidesAnimation>

                        </div>
                    ) : null
                }
                <Link
                    href={getFullPath('about-game', lang)}
                    className={`${aboutStyles.about__button} cursor--custom-pointer`}
                >
                    <Button className="width--100">{discoverTheGame}</Button>
                </Link>
            </div>
        </div>
    )
}

export default About;