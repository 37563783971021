'use client';
import React, { useEffect, useState } from 'react';
import { NET_TYPES } from '@/constants/net.const';
import { useOauthStore } from '@/store/zustand/oauth.store';
import { useUpdateUrlParams } from '@/hooks/useUpdateUrlParams';
import Link from 'next/link';

interface IProps {
  children: any;
  className?: string;
  loggedOutParams?: Record<string, string>;
  loggedInParams?: Record<string, string>;
  onClick?: () => void;
}

const DynamicAccountLink = (props: IProps) => {
  const {
    children,
    className = '',
    onClick,
    loggedOutParams = {
      'oauth': 'true',
      'form': 'login'
    },
    loggedInParams = {}
  } = props;
  const { isAuthorized } = useOauthStore();
  const [netType, setNetType] = useState<string | null>(null);

  const { getUpdatedPath } = useUpdateUrlParams();

  useEffect(() => {
    const storageNetType = sessionStorage.getItem('account-net-type')
    setNetType(storageNetType || NET_TYPES.TEST_NET);
  }, []);

  const href = getUpdatedPath({
    ...(isAuthorized ? {
      newPathName: `/account/${netType}`,
      deleteParams: true,
      setParams: loggedInParams
    } : {
      setParams: loggedOutParams
    }),
  });

  return (
    <Link
      scroll={isAuthorized}
      href={href}
      className={className || ''}
      {...(onClick ? { onClick } : {})}
    >
      {children}
    </Link>
  );
};

export default DynamicAccountLink;
