"use client";
import React, { useEffect, useState, useRef } from 'react';

const Arc = (props: {
    percentageLeft: number;
    percentageRight: number;
    containerWidth: number;
    containerHeight: number;
}) => {
    const {
        percentageLeft,
        percentageRight,
        containerWidth,
        containerHeight
    } = props;

    const OFFSET = 36;

    // Radius of the circle (without offset)
    const radius = Math.min(containerWidth, containerHeight) / 2 - 20; // Reduce radius by 20px for padding

    const adjustedRadius = radius + OFFSET; // Arc radius considering a 40px offset outside

    // Center of the container
    const centerX = containerWidth / 2;
    const centerY = containerHeight / 2;

    const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
        const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
        return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians)),
        };
    };

    const describeArc = (x: number, y: number, radius: number, startAngle: number, endAngle: number) => {
        const start = polarToCartesian(x, y, radius, startAngle);
        const end = polarToCartesian(x, y, radius, endAngle);
        const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

        return [
            'M', start.x, start.y,
            'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y,
        ].join(' ');
    };

    const percentageToDegrees = (percentage: number) => (percentage / 100) * 360;

    const startAngleLeft = 232;
    const startAngleRight = -52;

    const endAngleLeft = startAngleLeft - percentageToDegrees(percentageLeft); // Counterclockwise for left arc
    const endAngleRight = startAngleRight + percentageToDegrees(percentageRight); // Clockwise for right arc

    const normalizedEndAngleLeft = (endAngleLeft + 360) % 360;
    const normalizedEndAngleRight = (endAngleRight + 360) % 360;

    const leftArcPath = describeArc(centerX, centerY, adjustedRadius, startAngleLeft, normalizedEndAngleLeft);
    const rightArcPath = describeArc(centerX, centerY, adjustedRadius, startAngleRight, normalizedEndAngleRight);

    return (
        <svg
            style={{
                overflow: 'visible',
                width: '100%',
                height: '100%'
            }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${containerWidth} ${containerHeight}`}
        >
            <defs>
                <linearGradient id="grad1"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#6C1304" stopOpacity="1" />
                    <stop offset="0.1" stopColor="#0A0909" stopOpacity="0.6" />
                    <stop offset="0.8" stopColor="#0A0909" stopOpacity="0.6" />
                    <stop offset="1" stopColor="#6C1304" stopOpacity="1" />
                </linearGradient>
            </defs>
            <path
                d={rightArcPath}
                fill="none"
                stroke="url(#grad1)"
                strokeWidth="14"
                strokeLinecap="butt"
            />
            <path
                d={leftArcPath}
                fill="none"
                stroke="url(#grad1)"
                strokeWidth="14"
                strokeLinecap="butt"
            />
        </svg>
    );
};

const ArcComponent = (props: {
    percentageLeft: number;
    className?: string;
    setPercentageLeft: React.Dispatch<React.SetStateAction<number>>;
}) => {
    const {
        className,
        percentageLeft,
        setPercentageLeft
    } = props;

    const containerRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    const updateSize = () => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.offsetWidth);
            setContainerHeight(containerRef.current.offsetHeight);
        }
    };
    useEffect(() => {
        updateSize();
    }, [percentageLeft]);

    return (
        <div ref={containerRef} className={className || ''} style={{ width: '100%', height: '100%' }}>
            <Arc
                percentageLeft={40}
                percentageRight={50}
                containerWidth={containerWidth}
                containerHeight={containerHeight}
            />
            <input
                type="range"
                min="0"
                max="50"
                value={percentageLeft}
                style={{
                    display: 'none'
                }}
                onChange={(e) => setPercentageLeft(Number(e.target.value))}
            />
            <input
                type="range"
                min="0"
                max="50"
                style={{
                    display: 'none'
                }}
                value={50}
                readOnly
            />
        </div>
    );
};

export default ArcComponent;
