'use client';

// Components
import Text from '@/common/Text/index.server';
import ClientModal from '@/common/Modal/Client/index.client';
import Button from '@/common/Button/index.client';
import Icon from '@/common/Icon/index.client';
import PinWindow from '@/components/PinModal/PinWindow/index.client';
import TransactionCreatedModalWindow from '@/components/TransactionCreatedModal/TransactionCreatedModalWindow/index.client';
import InputComponent from '@/components/Form/Input/index.client';
import HorizontalRule from '@/common/HorizontalRule/index.server';
import InfoPlate from '../InfoPlate/index.client';

// Types
import { ITextContent } from '@/types/page.types';

// Decorators
import withAuthAndWalletChecks from '@/decorators/withAuthAndWalletChecks.dec';

// Styles
import styles from './index.module.sass';

interface IProps {
  unstakeAmount: string;
  setUnstakeAmount: React.Dispatch<React.SetStateAction<string>>;
  unstakeModalOpen: boolean;
  setUnstakeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  translations: ITextContent;
  isTokenExecuting: boolean;
  handleUnstaking: () => Promise<any>;
  undergroundBalance: string;
  undergroundBalanceGift: string;
  pinModalOpen: boolean;
  execUnstaking: (privateKey?: string) => Promise<void>;
  hashString: string;
  setHashString: React.Dispatch<React.SetStateAction<string>>;
  lang: string;
  connectWalletModalText: ITextContent;
  fee?: number;
}

const UnstakeModal = (props: IProps) => {
  const {
    unstakeModalOpen,
    setUnstakeModalOpen,
    isTokenExecuting,
    handleUnstaking,
    undergroundBalance,
    undergroundBalanceGift,
    translations,
    translations: {
      metaData: {
        json: {
          unstakeYourTokens = '',
          unstake = '',
          stakingNow = '',
          amount = '',
          giftForStaking = '',
          fee: feeText = ''
        } = {}
      } = {}
    } = {},
    pinModalOpen = false,
    execUnstaking,
    hashString = '',
    setHashString,
    lang,
    unstakeAmount,
    setUnstakeAmount,
    fee
  } = props;

  const handleCloseModal = () => {
    setUnstakeModalOpen(false);
    setHashString('');
  }

  return (
    <ClientModal isOpened={unstakeModalOpen} {...(!isTokenExecuting && { setIsOpened: handleCloseModal })} loading={isTokenExecuting}>
      {pinModalOpen ? (
        <PinWindow
          onComplete={execUnstaking}
          translations={translations}
        />
      ) : hashString ? (
        <TransactionCreatedModalWindow
          hash={hashString}
          translation={translations}
          lang={lang}
        />
      ) : (
        <div className={styles['unstake-modal']}>
          <Text
            tag="h5"
            size="big"
            weight="400"
            fontFamily="copperplate"
            transform="uppercase"
            className={`${styles['unstake-modal__title']} turn--center`}
          >
            {unstakeYourTokens}
          </Text>
          <HorizontalRule className={styles['unstake-modal__hr']} />
          <InputComponent
            name="amount"
            placeholder="1000"
            value={unstakeAmount}
            onChange={(_, val) => setUnstakeAmount(val)}
            label={amount}
            type="number"
            currency={{
              value: 'Pada',
              icon: 'colorful/pada'
            }}
            classNames={{
              wrapper: "margin--b-24"
            }}
          />
          <InfoPlate translations={translations} />
          <div className="fl fl--align-c fl--justify-b fl--gap-24 margin--b-16 margin--t-24">
            <Text
              size="x-small"
              fontFamily="ubuntu"
              color="extra-gray"
            >
              {stakingNow}
            </Text>
            <div className="fl fl--align-c fl--gap-8">
              <Text
                size="small"
                weight="400"
                fontFamily="copperplate"
                color="extra-gray"
              >
                {undergroundBalance || 0}
              </Text>
              <Icon
                icon="colorful/pada"
                size={16}
              />
            </div>
          </div>
          <div className="fl fl--align-c fl--justify-b fl--gap-24 margin--b-16">
            <Text
              size="x-small"
              fontFamily="ubuntu"
              color="extra-gray"
            >
              {giftForStaking}
            </Text>
            <div className="fl fl--align-c fl--gap-8">
              <Text
                size="small"
                weight="400"
                fontFamily="copperplate"
                color="extra-gray"
              >
                {undergroundBalanceGift}
              </Text>
              <Icon
                icon="colorful/pada"
                size={16}
              />
            </div>
          </div>
          <div className={`${styles['unstake-modal__last-row']} fl fl--align-c fl--justify-b fl--gap-24`}>
            <Text
              size="x-small"
              fontFamily="ubuntu"
              color="extra-gray"
            >
              {feeText}
            </Text>
            <div className="fl fl--align-c fl--gap-8">
              <Text
                size="small"
                fontFamily="copperplate"
                weight="400"
                color="extra-gray"
              >
                {fee?.toFixed(3) || 0}
              </Text>
              <Icon
                icon="colorful/pada"
                size={16}
              />
            </div>
          </div>
          <Button
            onClick={handleUnstaking}
            disabled={!undergroundBalance || isTokenExecuting}
            className={`${styles['unstake-modal__button']} width--100`}
          >
            {unstake}
          </Button>
        </div>
      )}
    </ClientModal>
  );
};

export default withAuthAndWalletChecks(UnstakeModal);
