'use client';
import { useCookies } from "react-cookie";
import { useEffect, useMemo } from "react";
import Link from "next/link";

// Components
import { LOCALES } from "@/configuration/locales";
import { usePathname, useRouter } from "next/navigation";
import Icon from "@/common/Icon/index.client";

// Styles
import languageStyles from './index.module.sass'

interface LanguagesTypes {
    lang: string;
    className: string;
    isClientArea?: boolean;
}

const Language = (props: LanguagesTypes) => {
    const pathname = usePathname();
    const [{ lang: cookiesLanguage }, setCookie] = useCookies();
    const {
        lang,
        className = '',
        isClientArea = false
    } = props;
    const language = useMemo(() => isClientArea ? cookiesLanguage : lang, [isClientArea, lang, cookiesLanguage]);
    const router = useRouter();

    const onClientAreaNavigate = (locale: string) => {
        setCookie('lang', locale, { path: '/' });
    }

    
    useEffect(() => {
        if (!isClientArea) return;
        router.refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    return (
        <div className={`${languageStyles.languages} ${className}`}>
            <div className={`${languageStyles.languages__title} relative--core color--white text--default font--300 fl fl-align-c cursor--custom-pointer`}>
                {
                    !isClientArea ?
                        <span className={languageStyles.languages__lang}>{language}</span>
                        : null
                }
                <Icon
                    size={16}
                    icon="flat/menu-down-arrow"
                />
                <span className={`${languageStyles.languages__lang} ${languageStyles['languages__lang--mobile']} ${isClientArea ? languageStyles['languages__lang--client-area'] : ''}`}>{language}</span>
            </div>
            <div
                className={`${languageStyles.languages__subcontent} absolute--core`}
            >
                <div
                    className={`${languageStyles.languages__submenu} fl fl--dir-col fl--align-c`}
                >
                    {
                        LOCALES?.map((locale) => {
                            return (
                                <Link
                                    key={locale}
                                    className={`${languageStyles['languages__link']} ${language === locale ? `${languageStyles['languages__link--active']} link--active` : ''} link--secondary text--default relative--core fl fl--align-c cursor--custom-pointer`}
                                    {...(isClientArea ? { href: pathname } : { href: `${pathname.replace(pathname!.toLowerCase().split("/")[1], locale)}` })}
                                    {...(isClientArea ? { onClick: () => onClientAreaNavigate(locale) } : {})}
                                >
                                    {locale || 'en'}
                                </Link>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Language;