import { openDB } from 'idb';

const DB_NAME = 'WalletDB';
const STORE_NAME = 'keys';

export const getDB = () => {
    return openDB(DB_NAME, 1, {
        upgrade(db) {
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME, { keyPath: 'id' });
            }
        },
    });
};

export const saveKeys = async (keys: { id: string; type: string; privateKeyEnc?: string; iv?: string; salt?: string; publicKey: string }) => {
    const db = await getDB();
    await db.put(STORE_NAME, keys);
};

export const getKeys = async (id: string) => {
    const db = await getDB();
    return db.get(STORE_NAME, id);
};
