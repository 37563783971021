'use client';

// Components
import Text from '@/common/Text/index.server';

// Types
import { ITextContent } from '@/types/page.types';

// Styles
import styles from '../index.module.sass';

interface IProps {
  text: ITextContent;
  translation: ITextContent;
  connectWalletStepActivePin: () => Promise<void>;
  connectMetamask: () => Promise<void>;
  isUpdating: boolean;
}

const ConnectWalletStep0Content = (props: IProps) => {
  const {
    text: {
      metaData: {
        json: {
          connectStep0Title = '',
          connectMetamaskTitle = '',
          connectMetamaskTextBrief = '',
          connectMetamaskTextExplanation = '',
          generateInternalTitle = '',
          generateInternalTextBrief = '',
          generateInternalTextExplanation = ''
        } = {}
      } = {}
    } = {},
    connectWalletStepActivePin,
    connectMetamask
  } = props;

  return (
    <>
      <Text
        tag="h5"
        size="big"
        transform="uppercase"
        weight="400"
        fontFamily="copperplate"
        className={`${styles['connect-wallet-modal__title']} ${styles['connect-wallet-modal__title--small']} turn--center`}
      >
        {connectStep0Title}
      </Text>
      <div className={styles['connect-wallet-modal__cards']}>
        <div
          onClick={connectMetamask}
          className={`${styles['connect-wallet-modal__card']} fl--1 padding--24 radius--16 overflow--hidden cursor--custom-pointer`}
        >
          <div className={`${styles['connect-wallet-modal__metamask-icon']} margin--center`} />
          <Text
            tag="h6"
            size="x-const-subtitle"
            transform="uppercase"
            weight="400"
            fontFamily="copperplate"
            className="turn--center margin--b-8 margin--t-24"
          >
            {connectMetamaskTitle}
          </Text>
          <Text
            size="small"
            fontFamily="ubuntu"
            color="extra-gray"
          >
            <b className="font--500">{connectMetamaskTextBrief}</b>: {connectMetamaskTextExplanation}
          </Text>
        </div>
        <div
          onClick={connectWalletStepActivePin}
          className={`${styles['connect-wallet-modal__card']} fl--1 padding--24 radius--16 overflow--hidden cursor--custom-pointer`}
        >
          <div className={`${styles['connect-wallet-modal__key-icon']} margin--center`} />
          <Text
            tag="h6"
            size="x-const-subtitle"
            transform="uppercase"
            weight="400"
            fontFamily="copperplate"
            className="turn--center margin--b-8 margin--t-24"
          >
            {generateInternalTitle}
          </Text>
          <Text
            size="small"
            fontFamily="ubuntu"
            color="extra-gray"
          >
            <b className="font--500">{generateInternalTextBrief}</b>: {generateInternalTextExplanation}
          </Text>
        </div>
      </div>
    </>
  );
};

export default ConnectWalletStep0Content;
