'use client'
import { FC, useState, ChangeEvent } from 'react';
import uniqid from 'uniqid';

// Components
import Icon from '@/common/Icon/index.client';
import Tooltip from '@/common/Tooltip/index.client';
import Text from '@/common/Text/index.server';

// styles
import styleInput from './index.module.sass';

interface InputInterface {
  index?: number;
  name?: string;
  classNames?: {
    field?: string,
    wrapper?: string,
  }
  disabled?: boolean;
  value?: string | number;
  placeholder?: string;
  onChange?: (name: string, value: string) => void;
  type?: string;
  id?: string;
  label?: string;
  meta?: {
    error?: string;
    touched?: boolean;
  };
  icon?: {
    icon?: string;
    size?: number;
    color?: string;
  };
  currency?: {
    value?: string;
    icon?: string;
  };
  maxLength?: number;
}

const InputComponent: FC<InputInterface> = ({
  index,
  name = '',
  value,
  type,
  placeholder = "",
  classNames: {
    field = '',
    wrapper = ''
  } = {},
  disabled,
  label = '',
  id = "",
  meta,
  onChange,
  icon: {
    icon,
    size: iconSize = 16,
    color: iconColor = 'color--white'
  } = {},
  currency: {
    value: currencyValue = '',
    icon: currencyIcon = ''
  } = {},
  maxLength
}): JSX.Element => {
  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { target: { value = '' } = {} } = event;
    if (maxLength && value.length > maxLength) return;
    onChange && onChange(name, value);
  };
  const [randomId] = useState(uniqid());
  const htmlFor = name ? `textarea--${name}` : randomId;
  const { touched, error } = meta || {};

  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <div className={`relative--core ${wrapper || ''}`}>
      {label ? <label htmlFor={htmlFor} className={`${styleInput['input__label']} margin--b-8 text--minimum font--400 font--uppercase font--family-copperplate color--extra-gray`}>{label}</label> : null}
      {index ?
        <Text
          size="small"
          fontFamily="ubuntu"
          className={`${styleInput.input__index} ${field || ''}`}
        >
          {index}.
        </Text> : null
      }
      <input
        id={id || htmlFor}
        name={name}
        value={value}
        disabled={disabled}
        type={type === "password" && passwordVisible ? "text" : type === "search" ? "text" : type}
        placeholder={placeholder}
        className={`
          relative--core width--100 font--300 ${styleInput.input__field} ${field || ''} ${touched && error ? styleInput['input__field--error'] : ''}
          padding--r-${24 + 32 * [icon, currencyValue, touched && error, type === 'password', type === 'search', type === 'search' && value].filter(Boolean).length}
          ${index ? index >= 10 ? "padding--l-46" : "padding--l-42" : "padding--l-24"}
        `}
        onChange={onChangeHandler}
        autoComplete="off"
      />
      {type === 'search' ? (
        <div className={`fl fl--align-c ${styleInput['input__search-icons-wrapper']} ${touched && error ? styleInput['input__search-icons-wrapper--warning'] : ''}`}>
          {
            value ? (
              <Icon
                icon="flat/cross"
                size={24}
                color="color--extra-gray"
                onClick={() => onChange && onChange(name, '')}
                classNames={{
                  wrapper: 'margin--r-8  cursor--custom-pointer'
                }}
              />
            ) : null
          }
          <Icon
            icon="flat/search"
            size={18}
            color="color--extra-gray"
          />
        </div>
      ) : null}
      {icon ? (
        <Icon
          icon={icon}
          size={iconSize}
          color={iconColor}
          classNames={{
            wrapper: `${styleInput['input__icon-sufix']} ${touched && error ? styleInput['input__icon-sufix--warning'] : ''}`
          }}
        />
      ) : null}
      {currencyValue ? (
        <div className={`${styleInput['input__currency']} fl fl--align-c fl--gap-8`}>
          <Icon icon={currencyIcon} size={16} />
          <Text
            size="minimum"
            transform="uppercase"
            color="extra-gray"
            className="font--family-copperplate"
          >
            {currencyValue}
          </Text>
        </div>
      ) : null}
      {type === 'password' ? (
        <Icon
          icon={passwordVisible ? 'flat/eye' : 'colorful/eye-hide'}
          size={20}
          color="color--white"
          onClick={() => setPasswordVisible(!passwordVisible)}
          classNames={{
            wrapper: `${styleInput['input__visible-pass-button']} ${touched && error ? styleInput['input__visible-pass-button--warning'] : ''} cursor--custom-pointer`
          }}
        />
      ) : null}
      {touched && error ? (
        <Tooltip
          placement="right"
          text={error}
        >
          <div className={`${styleInput['input__error-wrapper']}`}>
            <Icon icon="colorful/warning" size={20} />
          </div>
        </Tooltip>
      )
        : null}
    </div >
  );
};

export default InputComponent;
