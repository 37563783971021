/* eslint-disable react-hooks/exhaustive-deps */
import { IAsset } from "@/types/crm/coreBanking/assets.types";
import { useAssetsStore } from "../../assets.store";
import { useEffect } from "react";
import { getAssets } from "@/actions/assets";
import { BALANCE_POLLING_INTERVAL } from "@/constants/token.const";

const AssetsStoreInitializer = (props: { data: IAsset[] }) => {
    const {
        data
    } = props;
    const {
        set
    } = useAssetsStore();

    let interval: any;

    useEffect(() => {
        if (!data) return;
        set(data);

        if (interval) clearInterval(interval);

        interval = setInterval(async () => {
            try {
                const assets = await getAssets();
                if (assets) {
                    set(Object.values(assets).reduce((acc: any, el: any) => ({ ...acc, [el?.currency]: el }), {}));
                }
            } catch (e) {
                console.error(e);
            }
        }, BALANCE_POLLING_INTERVAL);

        return () => {
            if (interval) clearInterval(interval);
        }
    }, [data]);


    useEffect(() => {
        if (!data) return;
        set(data.reduce((acc: any, el: any) => ({ ...acc, [el?.currency]: el }), {}));
    }, [data]);

    return null;
}

export default AssetsStoreInitializer;
