'use client';
import { useUserStore } from '@/store/zustand/user.store';
import { useEffect, useState } from 'react';

// Components
import Text from '@/common/Text/index.server';
import LinkComponent from '@/common/Link/index.server';
import Icon from '@/common/Icon/index.client';

// Helpers
import { isJSON } from '@/helpers/object.hl';

// Types
import { ITextContent } from '@/types/page.types';

// Styles
import styles from '../index.module.sass';

interface IFeature {
  title: string;
  text: string;
}

interface IProps {
  text: ITextContent;
  translation: ITextContent;
  handleCloseClick: () => void;
  hash?: string;
  lang: string;
}

const ConnectWalletStep4Content = (props: IProps) => {
  const {
    translation: {
      metaData: {
        json: {
          walletCreated = '',
          whatYouCanDoNow = '',
          tokenScanner = ''
        } = {}
      } = {}
    } = {},
    text: {
      metaData: {
        json: {
          walletCreatedFeatures = [],
          newWalletCreatedFeatures = [],
          connectWalletStep4Subtitle = '',
          connectWalletStep4Caption = '',
          deployingWalletTitle = '',
          deployingWalletText = ''
        } = {}
      } = {}
    } = {},
    handleCloseClick,
    hash = '',
    lang = ''
  } = props;

  const [userMeta, setUserMeta] = useState<{ connectAddress?: string; }>({});

  const {
    user: {
      externalId = '',
      meta = ''
    } = {}
  } = useUserStore();

  useEffect(() => {
    if (isJSON(meta)) {
      setUserMeta(JSON.parse(meta));
    }
  }, [meta])

  return (
    <> {
      externalId && userMeta.connectAddress ? (
        <>
          <Text
            tag="h5"
            size="big"
            transform="uppercase"
            weight="400"
            fontFamily="copperplate"
            className={`${styles['connect-wallet-modal__subtitled-title']} turn--center`}
          >
            {walletCreated}
          </Text>
          <Text
            size="minimum"
            fontFamily="ubuntu"
            color="extra-gray"
            className={`${styles['connect-wallet-modal__subtitle']} turn--center`}
          >
            {connectWalletStep4Subtitle} <LinkComponent
              target="_blank"
              href={`/${lang}/token-scanner?page=1&search=${hash}`}
              handleClick={handleCloseClick}
            >
              {tokenScanner}
            </LinkComponent>.
          </Text>
          <hr className={styles['connect-wallet-modal__hr']} />
          <Text
            tag="h6"
            size="x-const-subtitle"
            transform="uppercase"
            weight="400"
            fontFamily="copperplate"
            className="margin--b-24"
          >
            {whatYouCanDoNow}:
          </Text>
          {newWalletCreatedFeatures?.length ? (
            <div className={`${styles['connect-wallet-modal__features']} fl fl--dir-col fl--gap-24`}>
              {newWalletCreatedFeatures?.map((feature: string[], i: number) => (
                <div key={`${i} ${feature}`} className={styles['connect-wallet-modal__feature']}>
                  <Text
                    size="small"
                    transform="uppercase"
                    weight="400"
                    fontFamily="copperplate"
                  >
                    {feature}
                  </Text>
                </div>
              )
              )}
            </div>
          ) : null}
          {/* {walletCreatedFeatures?.length ? (
        <div className={`${styles['connect-wallet-modal__features']} fl fl--dir-col fl--gap-24`}>
          {walletCreatedFeatures?.map((feature: IFeature, i: number) => {
            const { title = '', text = '' } = feature;

            return (
              <div key={`${i} ${title}`} className={styles['connect-wallet-modal__feature']}>
                <Text
                  size="small"
                  transform="uppercase"
                  weight="400"
                  fontFamily="copperplate"
                  className="margin--b-4"
                >
                  {title}
                </Text>
                <Text
                  size="small"
                  fontFamily="ubuntu"
                >
                  {text}
                </Text>
              </div>
            )
          })}
        </div>
      ) : null} */}
          <Text
            size="minimum"
            fontFamily="ubuntu"
            color="extra-gray"
            className="turn--center"
          >
            {connectWalletStep4Caption}
          </Text>
        </>
      ) : (
        <>
          <div className="width--100">
            <Icon
              icon="flat/clock"
              color="color--white"
              size={32}
              classNames={{
                wrapper: `${styles['connect-wallet-modal__clock-icon']} width--fit-content`
              }}
            />
            <Text
              tag="h5"
              size="x-const-subtitle"
              transform="uppercase"
              weight="400"
              fontFamily="copperplate"
              className="margin--b-8"
            >
              {deployingWalletTitle}
            </Text>
            <Text
              size="small"
              fontFamily="ubuntu"
              color="extra-gray"
            >
              {deployingWalletText}
            </Text>
          </div>
        </>
      )
    }

    </>
  );
};

export default ConnectWalletStep4Content;
