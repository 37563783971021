import React from 'react';
import { ButtonType } from './types';
import buttonStyle from './index.module.sass';

const Button = (props: ButtonType): JSX.Element => {
    const {
        id,
        type = 'primary',
        radius = 100,
        noPadding = false,
        size = 'default',
        htmlType = 'button',
        children,
        className = '',
        disabled,
        active = false,
        onClick = () => null,
        style
    } = props;

    return (
        <button
            id={id}
            style={style}
            className={`
                ${buttonStyle.button} ${buttonStyle[`button--${type}`]} ${active ? buttonStyle[`button--${type}-active`] || '' : ''} ${className || ''} 
                ${`radius--${radius}`} ${noPadding ? buttonStyle['button--no-padding'] : ''} ${buttonStyle[`button--size-${size}`] || ''} fl fl--align-c fl--justify-c`}
            type={htmlType}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default Button;
