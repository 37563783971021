import(/* webpackMode: "eager" */ "/poliada/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/poliada/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/poliada/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/poliada/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/poliada/node_modules/next/font/local/target.css?{\"path\":\"src/app/[lang]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../fonts/copperplate.otf\",\"display\":\"swap\",\"variable\":\"--font-copperplate\"}],\"variableName\":\"Copperplate\"}");
import(/* webpackMode: "eager" */ "/poliada/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/layout.tsx\",\"import\":\"Ubuntu\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-ubuntu\"}],\"variableName\":\"ubuntu\"}");
import(/* webpackMode: "eager" */ "/poliada/src/app/preload-resources.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/About/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/ClientBalances/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/ConnectWalletModal/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Investors/Process/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Investors/Tokenomics/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Loader/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/LottieAnimation/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Menu/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/ModalsController/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/SideBar/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/SidebarMenu/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/TestnetPlug/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/components/Underground/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/providers/ReactQuery/index.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/store/zustand/Initializers/ca.client.tsx");
import(/* webpackMode: "eager" */ "/poliada/src/styles/build.sass")