'use client'
import React, { useEffect, useState } from 'react';
import { usePathname } from "next/navigation";
import Link from 'next/link';
import Image from 'next/image';

// Components
import Text from '@/common/Text/index.server';
import Icon from "@/common/Icon/index.client";
import { SidebarMenu } from '@/components/index.client';
import Logo from '../Logo/index.server';
import Language from '../Language/index.client';
import LoaderBlur from '@/common/LoaderBlur/index.client';

// Hooks
import useLogout from '@/hooks/useLogout';

// Constants
import { NET_TYPES } from '@/constants/net.const';

// Types
import { IMenuSingleLanguage } from '@/types/menu.types';

// Store
import { useOauthStore } from '@/store/zustand/oauth.store';

// style
import styles from './index.module.sass';

interface SideBarTypes {
    menu: IMenuSingleLanguage[],
    lang: string
    translations: { [key: string]: string },
}

const SideBar = (props: SideBarTypes) => {
    const {
        menu,
        lang,
        translations: {
            login = '',
            supportCentre = '',
            personalArea = '',
            restorePassword = '',
            logOut = ''
        },
    } = props;
    const {
        isAuthorized,
        email,
    } = useOauthStore();
    const [openMenu, setOpenMenu] = useState(false);
    const [netType, setNetType] = useState<string | null>(null);
    const pathname = usePathname();
    const { isLoggingOut, logoutHandler } = useLogout();

    const openMenuHandleClick = () => setOpenMenu((prev) => !prev);

    useEffect(() => {
        setOpenMenu(false);
        const storageNetType = sessionStorage.getItem('account-net-type')
        setNetType(storageNetType || NET_TYPES.TEST_NET);
    }, [pathname])

    return (
        <>
            <div className="fl fl--align-st">
                <div
                    className={`${styles['side-bar__burger']} cursor--custom-pointer`}
                    onClick={() => openMenuHandleClick()}
                >
                    <Icon
                        icon="flat/menu-burger"
                        size={48}
                        color="white"
                    />
                </div>
            </div>
            {openMenu ? <div className={styles['side-bar__mobile-backdrop']} onClick={openMenuHandleClick} role="button" tabIndex={0} /> : null}
            <div className={`${styles['side-bar']} ${openMenu ? styles['side-bar--active'] : ''} ${isAuthorized ? styles['side-bar--authorized'] : ''} fl fl--dir-col fl--align-c scrollbar--invisible background--stone`}>
                <LoaderBlur fetch={isLoggingOut} />
                <Logo
                    lang={lang}
                    classNames={{
                        wrapper: styles['side-bar__logo']
                    }}
                />
                <Language lang={lang} className={styles['side-bar__lang']} />
                <span
                    role="button"
                    tabIndex={-1}
                    onClick={openMenuHandleClick}
                    className={`${styles['side-bar__button-close']} cursor--custom-pointer`}
                >
                    <Icon
                        size={48}
                        icon="flat/side-bar-close"
                        color="white"
                    />
                </span>
                {
                    isAuthorized ? (
                        <div className={`${styles['side-bar__profile']} fl fl--dir-col fl--align-c padding--b-32 margin--b-32 fl--gap-16`}>
                            <Image
                                src="/img/profile.svg"
                                alt="profile"
                                width={48}
                                height={48}
                            />
                            <div className={`${styles['side-bar__profile-menu']} fl fl--dir-col fl--align-c fl--gap-32`}>
                                <Text size="small" color="middle-gray" className="font--300 text--small font--family-ubuntu">
                                    {email}
                                </Text>
                                <Link href={`/account/${netType || NET_TYPES.TEST_NET}`} className={`${styles['side-bar__profile-menu-item']} link--secondary font--300 text--small fl font--family-ubuntu cursor--custom-pointer`}>
                                    <Icon
                                        size={16}
                                        icon="flat/personal-area"
                                        classNames={{ wrapper: 'margin--r-8' }}
                                        color="white"
                                    />
                                    {personalArea}
                                </Link>
                                <Link href={`/account/${netType}/support`} className={`${styles['side-bar__profile-menu-item']} link--secondary font--300 text--small fl fl--align-c font--family-ubuntu cursor--custom-pointer`}>
                                    <Icon
                                        size={16}
                                        icon="flat/support"
                                        classNames={{ wrapper: 'margin--r-8' }}
                                        color="white"
                                    />
                                    {supportCentre}
                                </Link>

                                <Link href={{ query: { oauth: true, form: 'restore-password-by-old' } }} className={`${styles['side-bar__profile-menu-item']} link--secondary font--300 text--small fl fl--align-c font--family-ubuntu cursor--custom-pointer`}>
                                    <div className={`${styles['side-bar__restore-icon']} margin--r-8 margin--t-2`} />
                                    {restorePassword}
                                </Link>
                                <span onClick={logoutHandler} className={`${styles['side-bar__profile-menu-item']} link--secondary font--300 fl font--300 text--small font--family-ubuntu cursor--custom-pointer`}>
                                    <Icon
                                        size={16}
                                        icon="flat/logout"
                                        classNames={{ wrapper: 'margin--r-8' }}
                                        color="white"
                                    />
                                    {logOut}
                                </span>
                            </div>
                        </div>
                    ) : null
                }
                <SidebarMenu
                    onCloseMenu={() => setOpenMenu(false)}
                    main={menu}
                    lang={lang}
                />
                {
                    !isAuthorized ? (
                        <Link href={{ query: { oauth: true } }} className={`${styles['side-bar__link-login']} link--primary fl fl--align-c fl--justify-c font--uppercase font--family-copperplate text--small cursor--custom-pointer margin--t-32`}>
                            {login}
                        </Link>

                    ) : null
                }
            </div>
        </>
    )
}

export default SideBar;
