const AGREEMENT_DOCUMENTS = ['Private policy', 'User agreement'];

const CLIENT_POLLING_INTERVAL = 5000;

const CLIENT_STATE_VALUES = {
    ACTIVE: 'active',
    DEACTIVATED: 'deactivated',
    SUSPENDED: 'suspended'
};

const CLIENT_VERIFICATION_LEVEL  = {
    KYC: 'KYC'
}

const CLIENT_VERIFICATION_STATUS = {
    INIT: 'init',
    VERIFIED: 'verified',
    REJECTED: 'rejected'
}

export {
    CLIENT_VERIFICATION_LEVEL,
    CLIENT_VERIFICATION_STATUS,
    AGREEMENT_DOCUMENTS,
    CLIENT_POLLING_INTERVAL,
    CLIENT_STATE_VALUES
}