import React from 'react';

// Components
import Text from '@/common/Text/index.server';

// Helpers
import { formatNumberValue } from '@/helpers/token.hl';

// Styles
import styles from './index.module.sass';

interface IChartTooltipProps {
    payload?: any[];
    translations?: { [key: string]: string };
    formatNumber?: boolean;
}

const ChartTooltip: React.FC<IChartTooltipProps> = ({ payload, translations, formatNumber }) => {

    return (
        <ul className={styles.tooltip}>
            {payload?.map((entry, index) => (
                <li key={`item-${index}`} className="fl fl--gap-12 fl--align-c">
                    <Text size="small" color="light" fontFamily="ubuntu">{translations?.[entry.name] || entry.name || ''}: </Text>
                    <Text size="small" color="light" fontFamily="ubuntu">{formatNumber && translations ? formatNumberValue({
                        value: entry?.value,
                        precision: 3,
                        translations: {
                            billionSuffix: translations?.billionSuffix || '',
                            millionSuffix: translations?.millionSuffix || '',
                            thousandSuffix:  translations?.thousandSuffix || ''
                        }
                        }) : entry?.value || '0'}</Text>
                </li>
            ))}
        </ul>
    );
};

export default ChartTooltip;
