'use client';
import { useSearchParams } from 'next/navigation';
import { useWalletStore } from '@/store/zustand/wallet.store';

// Components
import OauthModal from '../OauthModal/index.client';
import ConnectWalletErrorModal from '../ConnectWalletErrorModal/index.client';
import { ConnectWalletModal } from '../index.client';

// Types
import { ITextContent } from '@/types/page.types';

interface IProps {
  translations: ITextContent;
  connectModalText: ITextContent;
  lang: string;
}

const ModalController = (props: IProps) => {
  const { translations, connectModalText, lang } = props;
  const searchParams = useSearchParams();
  const oauthParam = searchParams.get('oauth');
  const {
    isConnectWalletModalActive,
    walletProviderError
  } = useWalletStore();

  return (
    <>
      {oauthParam ? <OauthModal translations={translations} lang={lang} /> : null}
      {walletProviderError ? (
        <ConnectWalletErrorModal
          translations={translations}
        />
      ) : null}
      {isConnectWalletModalActive ? (
        <ConnectWalletModal
          text={connectModalText}
          translation={translations}
          lang={lang}
        />
      ) : null}
    </>
  );
};

export default ModalController;
