'use client'
import React from 'react';

// Components
import ClientModal from '../../common/Modal/Client/index.client';
import LoaderBlur from '@/common/LoaderBlur/index.client';
import Text from '@/common/Text/index.server';
import DynamicAccountLink from '../DynamicAccountLink/index.client';

// Hooks
import useTexts from '@/hooks/useTexts';

// Constants
import TEXTS from '@/constants/texts.const';

// Styles
import styles from './index.module.sass';

interface IProps {
    isAccount?: boolean,
    isOpened: boolean,
    setIsOpened: (isOpened: boolean) => void
}

const TestnetInfoModal = (props: IProps) => {
    const {
        isAccount,
        isOpened,
        setIsOpened
    } = props;
    const [{
        [TEXTS.I18N]: {
            metaData: {
                json: {
                    invest = ''
                } = {}
            } = {}
        } = {},
        [isAccount ? TEXTS.TESTNET_INFO : TEXTS.SITE_TESTNET_INFO]: {
            text = '',
            metaData: {
                json: {
                    modal: {
                        title = '',
                        subtitle = ''
                    } = {}
                } = {}
            } = {}
        } = {}
    }, { loading: isTextsLoading }] = useTexts([isAccount ? TEXTS.TESTNET_INFO : TEXTS.SITE_TESTNET_INFO, TEXTS.I18N]) || {};

    return (
        <ClientModal
            isOpened={isOpened}
            setIsOpened={setIsOpened}
        >
            <>
                <LoaderBlur fetch={isTextsLoading} />
                <div className={styles['testnet-modal']}>
                    <Text
                        fontFamily="copperplate"
                        size="small"
                        className={`${styles['testnet-modal__subtitle']} turn--center`}
                    >
                        {subtitle}
                    </Text>
                    <Text
                        size="big"
                        transform="uppercase"
                        className="turn--center margin--b-18 margin--b-24"
                    >
                        {title}
                    </Text>
                    <div className={`${styles['testnet-modal__text-block']} font--family-ubuntu static-modal-small font--300 color--extra-gray padding--t-24`} dangerouslySetInnerHTML={{ __html: text }} />
                    {!isAccount ? (
                        <DynamicAccountLink
                            loggedOutParams={{
                                'oauth': 'true',
                                'form': 'signup',
                                'kyc': 'true'
                            }}
                            loggedInParams={{
                                'kyc': 'true'
                            }}
                            className={`${styles['testnet-modal__button']} fl fl--align-c fl--justify-c radius--100 color--white link--primary font--uppercase font--family-copperplate text--x-default cursor--custom-pointer`}
                            onClick={() => setIsOpened(false)}
                        >
                            {invest}
                        </DynamicAccountLink>
                    ) : null}
                </div>
            </>
        </ClientModal>
    )
}

export default TestnetInfoModal;
