'use client';
import Link from 'next/link';

// Components
import Text from '@/common/Text/index.server';

// Types
import { ITextContent } from '@/types/page.types';

// Styles
import styles from '../index.module.sass';

interface IProps {
  translation: ITextContent;
  hash?: string;
  lang: string;
}

const TransactionCreatedModalWindow = (props: IProps) => {
  const {
    translation: {
      metaData: {
        json: {
          transactionCreatedSuccessfully = '',
          transactionCreatedSuccessfullySubtitle = '',
          goToTransaction = ''
        } = {}
      } = {}
    } = {},
    hash = '',
    lang = ''
  } = props;

  return (
    <>
      <Text
        tag="h5"
        size="x-const-subtitle"
        transform="uppercase"
        weight="400"
        fontFamily="copperplate"
        className="margin--b-8"
      >
        {transactionCreatedSuccessfully}
      </Text>
      <Text
        size="minimum"
        weight="300"
        fontFamily="ubuntu"
        color="extra-gray"
        className="margin--b-24"
      >
        {transactionCreatedSuccessfullySubtitle}
      </Text>
      {
        hash ? (
          <Link
            target="_blank"
            href={`/${lang}/token-scanner?page=1&search=${hash}`}
            className={`${styles['transaction-created-modal-content__button']} width--100 link--primary fl fl--align-c fl--justify-c padding--l-40 padding--r-40 radius--100 font--uppercase font--family-copperplate text--default cursor--custom-pointer`}
          >
            {goToTransaction}
          </Link>
        ) : null
      }
    </>
  );
};

export default TransactionCreatedModalWindow;