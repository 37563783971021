'use client';
import * as Yup from 'yup';

import React, { Dispatch, SetStateAction, useState } from 'react';
import InputComponent from "@/components/Form/Input/index.client";
import Button from "@/common/Button/index.client";
import { getKeys } from "@helpers/indexedDb.hl";
import { decryptPrivateKey } from "@helpers/privateKey.hl";
import Text from '@/common/Text/index.server';
import { ITextContent } from '@/types/page.types';
import { Notification } from '@/common/Notification';

interface IProps {
    onComplete: any,
    translations: ITextContent;
}

const PinWindow = (props: IProps) => {
    const {
        onComplete,
        translations: {
            metaData: {
                json: {
                    enterPin = '',
                    submit = '',
                    pinPlaceholder = '',
                    fieldIsRequired = '',
                    validationMinThreeSymbols = '',
                    validationMaxSixSymbols = '',
                    notificationTitles = {},
                    notificationDescriptions = {}
                } = {}
            } = {}
        } = {},
    } = props;

    const [pin, setPin] = useState('');
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const pinErrorsSchema = Yup.string()
        .required(fieldIsRequired)
        .min(3, validationMinThreeSymbols)
        .max(6, validationMaxSixSymbols);

    const checkPin = async () => {
        try {
            const keys = await getKeys('userKeys');

            if (!keys) throw new Error('No keys found.');

            const privateKeyDecrypted = await decryptPrivateKey({
                encryptedDataStr: keys.privateKeyEnc,
                password: pin,
                saltStr: keys.salt,
                ivStr: keys.iv
            });

            if (!privateKeyDecrypted) throw new Error('Invalid pin.');

            onComplete(privateKeyDecrypted);
            setPin('');
        } catch (e: any) {
            Notification.warning({
                message: notificationTitles?.warning,
                description: notificationDescriptions?.incorrectPin
            });
        }
    }

    const getError = (schema: Yup.StringSchema<string, Yup.AnyObject, undefined, "">, value: string): string | undefined => {
        try {
            schema.validateSync(pin);
            return undefined;
        } catch (error) {
            return (error as Yup.ValidationError).message;
        }
    };

    const handleButtonClick = () => {
        setIsButtonClicked(true);
        checkPin();
    }

    return (
        <div>
            <Text
                tag="h5"
                size="big"
                transform="uppercase"
                weight="400"
                fontFamily="copperplate"
                className="margin--b-24"
            >
                {enterPin}
            </Text>
            <InputComponent
                name="pin"
                placeholder={pinPlaceholder}
                value={pin}
                onChange={(_, value) => setPin(value)}
                maxLength={6}
                meta={{ error: getError(pinErrorsSchema, pin), touched: isButtonClicked }}
                type="password"
            />
            <Button
                onClick={handleButtonClick}
                disabled={!Boolean(pin) || isButtonClicked ? !!getError(pinErrorsSchema, pin) : false}
                className="width--100 margin--t-24"
            >
                {submit}
            </Button>
        </div>
    )
};

export default PinWindow;
