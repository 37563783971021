import React from "react";

export interface TextProps {
    id?: string,
    tag?: string
    title?: string,
    weight?: string,
    size?: string,
    color?: string,
    transform?: string,
    lineHeight?: string,
    className?: string,
    children: React.ReactNode,
    fontFamily?: "copperplate" | "ubuntu",
    onClick?: (props?: any) => any
}


const TextComponent = (props: TextProps): JSX.Element => {
    const {
        tag = 'p',
        id,
        title,
        weight = '300',
        size = 'default',
        color = 'light',
        transform = 'none',
        fontFamily = '',
        className,
        onClick,
        children
    } = props;

    const getSize = () => {
        const classes = [];
        switch (size) {
            case 'tiny':
                classes.push('text--tiny')
                break;
            case 'minimum':
                classes.push('text--minimum');
                break;
            case 's-minimum':
                classes.push('text--s-minimum');
                break;
            case 'x-minimum':
                classes.push('text--x-minimum');
                break;
            case 'l-minimum':
                classes.push('text--l-minimum');
                break;
            case 'small':
                classes.push('text--small');
                break;
            case 'x-small':
                classes.push('text--x-small');
                break;
            case 'y-small':
                classes.push('text--y-small');
                break;
            case 'default':
                classes.push('text--default');
                break;
            case 'y-default':
                classes.push('text--y-default');
                break;
            case 'q-default':
                classes.push('text--q-default');
                break;
            case 's-default': 
                classes.push('text--s-default');
                break;
            case 'z-default': 
                classes.push('text--z-default');
                break;
            case 'x-default':
                classes.push('text--x-default');
                break;
            case 'x-default-subtitle':
                classes.push('text--x-default-subtitle');
                break;
            case 'x-const-subtitle':
                classes.push('text--x-const-subtitle');
                break;
            case 'x-default-subtitle-laptop-l':
                classes.push('text--x-default-subtitle-laptop-l');
                break;
            case 'x-default-subtitle-mobile':
                classes.push('text--x-default-subtitle-mobile');
                break;
            case 'x-medium':
                classes.push('text--x-medium');
                break;
            case 'z-medium':
                classes.push('text--z-medium');
                break;
            case 'q-medium':
                classes.push('text--q-medium');
                break;
            case 'medium':
                classes.push('text--medium');
                break;
            case 'big':
                classes.push('text--big');
                break;
            case 'x-big':
                classes.push('text--x-big');
                break;
            case 'y-big':
                classes.push('text--y-big');
                break;
            case 'xl-big':
                classes.push('text--xl-big');
                break;
            case 'xs-big':
                classes.push('text--xs-big');
                break;
            case 'z-large':
                classes.push('text--z-large');
                break;
            case 'huge':
                classes.push('text--huge');
                break;
            case 'x-huge':
                classes.push('text--x-huge');
                break;
            case 's-huge':
                classes.push('text--s-huge')
                break;
            case 'y-huge':
                classes.push('text--y-huge')
                break;
            case 'z-huge':
                classes.push('text--z-huge')
                break;
            case 'q-huge':
                classes.push('text--q-huge')
                break;
            case 'x-const-subtitle':
                classes.push('text--x-const-subtitle')
                break;
            case 'x-const':
                classes.push('text--x-const')
                break;
            case 'xl-big':
                classes.push('text--xl-big')
                break;
            case 'y-medium':
                classes.push('text--y-medium')
                break;
            case 'y-large':
                classes.push('text--y-large')
                break;
            case 'hero-card-title':
                classes.push('text--hero-card-title')
                break;
            default:
                break;
        }
        return classes;
    };
    const getWeight = () => {
        const classes = [];
        switch (weight) {
            case '100':
                classes.push('font--100');
                break;
            case '200':
                classes.push('font--200');
                break;
            case '300':
                classes.push('font--300');
                break;
            case '400':
                classes.push('font--400');
                break;
            case '500':
                classes.push('font--500');
                break;
            case '600':
                classes.push('font--600');
                break;
            case '700':
                classes.push('font--700');
                break;
            case '800':
                classes.push('font--800');
                break;
            default:
                break;
        }
        return classes;
    };

    const getColors = () => {
        const classes = [];
        switch (color) {
            case 'dark':
                classes.push('color--black');
                break;
            case 'light':
                classes.push('color--white');
                break;
            case 'gray':
                classes.push('color--gray');
                break;
            case 'extra-gray':
                classes.push('color--extra-gray');
                break;
            case 'dark-green':
                classes.push('color--dark-green');
                break;
            case 'middle-gray':
                classes.push('color--middle-gray');
                break;
            case 'red':
                classes.push('color--red');
                break;
            case 'dark-gray':
                classes.push('color--dark-gray');
                break;
            case 'pale-red':
                classes.push('color--pale-red');
                break;
            case 'brown-red':
                classes.push('color--brown-red');
                break;
            case 'text-white':
                classes.push('color--text-white');
                break;
            case 'extra-white':
                classes.push('color--extra-white');
                break;
            case 'medium-gray':
                classes.push('color--medium-gray')
                break;
            default:
                classes.push(color);
                break;
        }
        return classes;
    };
    const getTextTransform = () => {
        const classes = [];
        switch (transform) {
            case 'initial':
                classes.push('font--initial');
                break;
            case 'uppercase':
                classes.push('font--uppercase');
                break;
            case 'lowercase':
                classes.push('font--lowercase');
                break;
            case 'capitalize':
                classes.push('font--capitalize');
                break;
            case 'break-line':
                classes.push('font--break-line');
                break;
            default:
                break;
        }
        return classes;
    };

    const getFontFamily = () => {
        const classes = [];
        switch (fontFamily) {
            case 'ubuntu':
                classes.push('font--family-ubuntu');
                break;
            case 'copperplate':
                classes.push('font--family-copperplate');
                break;
            default:
                break;
        }
        return classes;
    };

    const setDefaultTextClasses = () => {
        return [
            ...getSize(),
            ...getWeight(),
            ...getColors(),
            ...getTextTransform(),
            ...getFontFamily(),
            className
        ].join(' ');
    };

    return React.createElement(
        tag,
        { onClick, title, className: setDefaultTextClasses(), 'id': id },
        children
    );
};

export default TextComponent;
