'use client';
import React, { useEffect } from 'react';
import { Contract, ethers, formatUnits } from 'ethers';
import { useMutation } from '@tanstack/react-query';
import { useUserStore } from '@/store/zustand/user.store';
import { useWalletStore } from '@/store/zustand/wallet.store';
import { useAssetsStore } from '@/store/zustand/assets.store';
import updateUser from '@/actions/user/update';
import getUserDetails from '@/actions/user/get';
import * as bip39 from "bip39";

// Components
import LoaderBlur from "@/common/LoaderBlur/index.client";
import ClientModal from '@/common/Modal/Client/index.client';
import ConnectWalletStep0Content from './ConnectWalletStep0Content/index.client';
import ConnectWalletStep1Content from './ConnectWalletStep1Content/index.client';
import ConnectWalletStep2Content from './ConnectWalletStep2Content/index.client';
import ConnectWalletStep3Content from './ConnectWalletStep3Content/index.client';
import ConnectWalletStep4Content from './ConnectWalletStep4Content/index.client';
import RestoreWalletStep0Content from './RestoreWalletStep0Content/index.client';
import RestoreWalletStep1Content from './RestoreWalletStep1Content/index.client';
import RestoreWalletStep2Content from './RestoreWalletStep2Content/index.client';
import RestoreWalletStep3Content from './RestoreWalletStep3Content/index.client';
import RestoreWalletMetamaskContent from './RestoreWalletMetamaskStep0Content/index.client';
import { Notification } from '@/common/Notification';

// Helpers
import { syncBalances } from "@helpers/token.hl";
import getProvider from "@helpers/getProvider.hl";
import { encryptPrivateKey } from "@helpers/privateKey.hl";
import { getKeys, saveKeys } from "@helpers/indexedDb.hl";
import isJSON from "@helpers/isJSON.hl";

// Constants
import { PADA_ABI, PADA_CONTRACT_ADDRESS } from '@/constants/token.const';
import USER_WALLET_TYPES from "@constants/userWallets.const";

// Types
import { ITextContent } from '@/types/page.types';

// Styles
import styles from './index.module.sass';

interface IProps {
    text: ITextContent;
    translation: ITextContent;
    lang: string;
    isModalOpen?: boolean;
    closeModalHandle?: () => void;
    onSuccessHandler?: () => void;
    isLoading?: boolean;
}

const ConnectWalletModal = (props: IProps) => {
    const {
        translation,
        translation: {
            metaData: {
                json: {
                    walletErrors: {
                        installMetamask = '',
                        universalError = ''
                    } = {},
                    error: errorTranslation = '',
                    mnemonicPhraseIsIncorrect = '',
                    notificationTitles = {},
                    notificationDescriptions = {}
                } = {}
            } = {}
        } = {},
        text,
        lang = '',
        isModalOpen,
        closeModalHandle,
        onSuccessHandler,
        isLoading
    } = props;

    const {
        isConnectWalletModalActive: open,
        setIsConnectWalletModalActive: setOpen,
        setWalletProviderError,
        setTrigger,
        type,
        status
    } = useWalletStore();

    const [active, setActive] = React.useState(true);
    const [activePin, setActivePin] = React.useState(false);
    const [activeMnemonicPhrase, setActiveMnemonicPhrase] = React.useState(false);
    const [activeCheckMnemonicPhrase, setActiveCheckMnemonicPhrase] = React.useState(false);
    const [isWalletCreated, setIsWalletCreated] = React.useState(false);
    const [isWalletRestored, setIsWalletRestored] = React.useState(false);

    const [pin, setPin] = React.useState<string>('');
    const [checkPin, setCheckPin] = React.useState<string>('');
    const [mnemonicWords, setMnemonicWords] = React.useState<string[]>([]);
    const [checkMnemonicWords, setCheckMnemonicWords] = React.useState<string[]>([]);
    const [isCreating, setIsCreating] = React.useState<boolean>(false);
    const [hash, setHash] = React.useState<string | null>(null);

    const [restoreMnemonicPhrase, setRestoreMnemonicPhrase] = React.useState<boolean>(false);
    const [restorePin, setRestorePin] = React.useState<boolean>(false);
    const [restoreMetamask, setRestoreMetamask] = React.useState<boolean>(false);

    const handleCloseClick = () => {
        setOpen(false);
        setActive(true);
        setActiveMnemonicPhrase(false);
        setActiveCheckMnemonicPhrase(false);
        setPin('');
        setCheckPin('');
        setMnemonicWords([]);
        setCheckMnemonicWords([]);
        setIsCreating(false);
        setRestoreMnemonicPhrase(false);
        setRestorePin(false);
        setIsWalletCreated(false);
    }

    const handlePinToValue = (_: string, value: string) => {
        setPin(value);
    }

    const handleCheckPinToValue = (_: string, value: string) => {
        setCheckPin(value);
    }

    const connectWalletStepActivePin = async () => {
        setActive(false);
        setActivePin(true);
    };

    const {
        currencies: {
            PADA: {
                address = ''
            } = {}
        } = {},
        set
    } = useAssetsStore();
    const {
        set: setUserDetails,
        user: {
            externalId,
            meta
        }
    } = useUserStore();

    const { isPending: isUpdating, mutateAsync } = useMutation({
        mutationKey: ['update-user'],
        mutationFn: updateUser,
        onSuccess: async () => {
            const data = await getUserDetails();
            setUserDetails(data);
        }
    });

    const connectWalletMnemonicPhrase = async () => {
        if (pin !== checkPin) {
            Notification.warning({
                message: notificationTitles?.warning,
                description: notificationDescriptions?.incorrectPin
            });
            return;
        }

        const mnemonic = bip39.generateMnemonic();
        setMnemonicWords(mnemonic.split(' '));

        setActivePin(false);
        setActiveMnemonicPhrase(true);
    };

    const restoreWalletMnemonicPhrase = () => {
        setActive(false);
        setRestoreMnemonicPhrase(true);
    }

    const restoreWalletPin = () => {
        if (!restoreWalletCheckMnemonic()) {
            const parsedUserMeta = isJSON(meta) ? JSON.parse(meta) : {};
            Notification.warning({
                message: notificationTitles?.warning,
                description: `${notificationDescriptions?.wrongMnemonic} ${parsedUserMeta?.connectAddress}`
            });
            return;
        }

        setRestoreMnemonicPhrase(false);
        setRestorePin(true);
    }

    const connectWallet = async () => {
        try {
            if (typeof (window as any).ethereum !== 'undefined') {
                const parsedUserMeta = isJSON(meta) ? JSON.parse(meta) : {};
                const provider = new ethers.BrowserProvider((window as any).ethereum);

                const signer = await provider.getSigner();
                if (await signer.getAddress() === parsedUserMeta.connectAddress) {
                    const contract = new Contract(PADA_CONTRACT_ADDRESS, PADA_ABI, provider);
                    await saveKeys({ id: 'userKeys', type: USER_WALLET_TYPES.WEB3, publicKey: await signer.getAddress() });
                    const symbol = await contract.symbol();
                    const balance = await contract.balanceOf(externalId);
                    const decimals = await contract.decimals();
                    const formattedBalance = +formatUnits(balance, decimals);
                    const prepared = { address: externalId, balance: formattedBalance, symbol };
                    set({ [symbol]: prepared });
                }
            } else {
                setWalletProviderError(installMetamask);
                setOpen(false);
            }
        } catch (error) {
            setWalletProviderError(`${universalError} error`);
            setOpen(false);
        }
    };

    const connectWalletCheckMnemonicPhrase = async () => {
        setCheckMnemonicWords(() => mnemonicWords.map((value, index) => index % 3 === 0 ? '' : value));
        setActiveMnemonicPhrase(false);
        setActiveCheckMnemonicPhrase(true);
    };

    const checkMnemonic = async () => {
        if (mnemonicWords.join(' ') === checkMnemonicWords.join(' ')) {
            await createWallet();
            setActiveCheckMnemonicPhrase(false);
        } else {
            Notification.error({
                message: errorTranslation,
                description: mnemonicPhraseIsIncorrect
            })
        }
    };

    const restoreWalletCheckMnemonic = (): boolean => {
        const provider = getProvider();
        const wallet = ethers.Wallet.fromPhrase(mnemonicWords.join(' '), provider);
        const parsedUserMeta = isJSON(meta) ? JSON.parse(meta) : {};
        return wallet.address === parsedUserMeta?.connectAddress;
    }

    const restoreWallet = async () => {
        if (pin !== checkPin) {
            Notification.warning({
                message: notificationTitles?.warning,
                description: notificationDescriptions?.incorrectPin
            });
            return;
        }

        const provider = getProvider();
        const wallet = ethers.Wallet.fromPhrase(mnemonicWords.join(' '), provider);

        const privateKey = wallet.privateKey;
        const publicKey = wallet.address;

        const {
            encryptedData,
            salt,
            iv
        } = await encryptPrivateKey({ privateKey, password: pin });

        await saveKeys({
            id: 'userKeys',
            type: USER_WALLET_TYPES.WEB2,
            privateKeyEnc: encryptedData,
            iv,
            salt,
            publicKey
        });

        setRestorePin(false);
        setIsWalletRestored(true);
        setTrigger(`f${(~~(Math.random() * 1e8)).toString(16)}`);
    }

    const createWallet = async () => {
        try {
            setIsCreating(true);
            const provider = getProvider();

            const wallet = ethers.Wallet.fromPhrase(mnemonicWords.join(' '), provider);

            const privateKey = wallet.privateKey;
            const publicKey = wallet.address;

            const {
                encryptedData,
                salt,
                iv
            } = await encryptPrivateKey({ privateKey, password: pin });

            await saveKeys({
                id: 'userKeys',
                type: USER_WALLET_TYPES.WEB2,
                privateKeyEnc: encryptedData,
                iv,
                salt,
                publicKey
            });

            const response = await fetch('/api/smart-wallet/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    publicKey
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to create smart wallet on backend');
            }

            const { hash } = await response.json();
            setHash(hash);

            await mutateAsync({
                meta: JSON.stringify({
                    ...(isJSON(meta) ? JSON.parse(meta) : {}),
                    connectType: USER_WALLET_TYPES.WEB2,
                    connectAddress: publicKey,
                })
            });
            setIsWalletCreated(true);
        } catch (error: any) {
            Notification.warning({
                message: notificationTitles?.warning,
                description: notificationDescriptions?.somethingWentWrong
            });
        } finally {
            setIsCreating(false);
        }
    };

    const connectMetamask = async () => {
        try {
            if (typeof (window as any).ethereum !== 'undefined') {
                setIsCreating(true);
                const provider = new ethers.BrowserProvider((window as any).ethereum);

                await provider.send("eth_requestAccounts", []);

                const wallet = await provider.getSigner();

                const publicKey = await wallet.getAddress();

                await saveKeys({ id: 'userKeys', type: USER_WALLET_TYPES.WEB3, publicKey });

                const response = await fetch('/api/smart-wallet/create', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        publicKey
                    }),
                });

                if (!response.ok) {
                    throw new Error('Failed to create smart wallet on backend');
                }

                const { hash } = await response.json();
                setHash(hash);

                await mutateAsync({
                    meta: JSON.stringify({
                        ...(isJSON(meta) ? JSON.parse(meta) : {}),
                        connectType: USER_WALLET_TYPES.WEB3,
                        connectAddress: publicKey
                    })
                });

                handleCloseClick();
            } else {
                setWalletProviderError(installMetamask);
                handleCloseClick();
            }
        } catch (error: any) {
            Notification.error({
                message: errorTranslation,
                description: error.message
            });
            setWalletProviderError(`${universalError} error`);
        } finally {
            setIsCreating(false);
        }
    };

    useEffect(() => {
        if (!externalId) return;
        syncBalances({
            externalId,
            set
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalId, address]);

    return (
        <>
            <ClientModal
                classNames={{
                    dialogClass: styles['connect-wallet-modal'],
                    ...(active && !externalId && {
                        innerClass: styles['connect-wallet-modal__inner--connect-step-0']
                    })
                }}
                isOpened={isModalOpen || open}
                {...(!isCreating && !isUpdating && { setIsOpened: closeModalHandle || handleCloseClick })}
            >
                <>
                    <LoaderBlur fetch={isLoading || isCreating || isUpdating} />
                    {active ?
                        externalId ? (
                            <>
                                {type === USER_WALLET_TYPES.WEB3 && !status ? (
                                    <RestoreWalletMetamaskContent
                                        text={text}
                                        translation={translation}
                                        connectWallet={connectWallet}
                                    />
                                ) : null}
                                {type === USER_WALLET_TYPES.WEB2 && !status ? (
                                    <RestoreWalletStep0Content
                                        text={text}
                                        translation={translation}
                                        restoreWalletMnemonicPhrase={restoreWalletMnemonicPhrase}
                                    />
                                ) : null}
                            </>
                        ) : (
                            <ConnectWalletStep0Content
                                text={text}
                                translation={translation}
                                connectWalletStepActivePin={connectWalletStepActivePin}
                                connectMetamask={connectMetamask}
                                isUpdating={isUpdating}
                            />
                        )
                        : null}
                    {restoreMnemonicPhrase ? (
                        <RestoreWalletStep1Content
                            translation={translation}
                            mnemonicWords={mnemonicWords}
                            setMnemonicWords={setMnemonicWords}
                            restoreWalletPin={restoreWalletPin}
                        />
                    ) : null}
                    {restorePin ? (
                        <RestoreWalletStep2Content
                            translation={translation}
                            pin={pin}
                            checkPin={checkPin}
                            handlePinToValue={handlePinToValue}
                            handleCheckPinToValue={handleCheckPinToValue}
                            restoreWallet={restoreWallet}
                        />
                    ) : null}
                    {isWalletRestored ? (
                        <RestoreWalletStep3Content
                            translation={translation}
                            handleCloseClick={onSuccessHandler || handleCloseClick}
                        />
                    ) : null}
                    {activePin ? (
                        <ConnectWalletStep1Content
                            translation={translation}
                            pin={pin}
                            checkPin={checkPin}
                            handlePinToValue={handlePinToValue}
                            handleCheckPinToValue={handleCheckPinToValue}
                            connectWalletMnemonicPhrase={connectWalletMnemonicPhrase}
                        />
                    ) : null}
                    {activeMnemonicPhrase ? (
                        <ConnectWalletStep2Content
                            translation={translation}
                            mnemonicWords={mnemonicWords}
                            connectWalletCheckMnemonicPhrase={connectWalletCheckMnemonicPhrase}
                        />
                    ) : null}
                    {activeCheckMnemonicPhrase ? (
                        <ConnectWalletStep3Content
                            translation={translation}
                            mnemonicWords={mnemonicWords}
                            checkMnemonicWords={checkMnemonicWords}
                            setCheckMnemonicWords={setCheckMnemonicWords}
                            checkMnemonic={checkMnemonic}
                        />
                    ) : null}
                    {isWalletCreated ? (
                        <ConnectWalletStep4Content
                            text={text}
                            translation={translation}
                            handleCloseClick={onSuccessHandler || handleCloseClick}
                            hash={hash || ''}
                            lang={lang}
                        />
                    ) : null}
                </>
            </ClientModal>
        </>
    );
};

export default ConnectWalletModal;
