/* eslint-disable consistent-return */
import { AxiosError, isAxiosError } from 'axios';

const errorsStringify = (e: AxiosError): string | undefined => {
    const {
        networkError,
        message
    } = e as any;

    if (isAxiosError(e)) {
        const msg = [];
        const { 
            response: {
                data: { 
                    message = '',
                    errors = []
                } = {}
            } = {}
        } = e as any;
        if (message?.errors) {
            for (const axiosError of message?.errors) {
                const { message: axiosErrorMessage } = axiosError;
                msg.push(axiosErrorMessage || axiosError);
            }
        } else {
            msg.push(`${message}. `);
            for (const axiosError of errors) {
                const { message: axiosErrorMessage } = axiosError;
                msg.push(axiosErrorMessage || axiosError);
            }
        }
        return msg.join('\n'); 
    }
    if (networkError?.result?.errors?.length) return networkError.result.errors.map(({ message: nm }: { message: string }) => nm).join('\n'); 
    return message;
};

const getErrorStatus = (e: AxiosError): number => {
    let errorStatus;
    if (isAxiosError(e)) {
        const { 
            response: {
                status = ''
            } = {}
        } = e as any;
        errorStatus = status;
    }

    return errorStatus;
};

errorsStringify.defaultProps = {
    networkError: {
        result: {
            errors: []
        }
    }
};

export {
    errorsStringify,
    getErrorStatus
};
