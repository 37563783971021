import { create } from 'zustand';

interface WalletStoreState {
    isConnectWalletModalActive: boolean;
    setIsConnectWalletModalActive: (value: boolean) => void;
    walletProviderError: string;
    setWalletProviderError: (value: string) => void;
    trigger: string;
    setType: (value: string) => void;
    type: string;
    setStatus: (value: boolean) => void;
    status: boolean;
    setTrigger: (value: string) => void;
}

export const useWalletStore = create<WalletStoreState>((set) => ({
    isConnectWalletModalActive: false,
    setIsConnectWalletModalActive: (value) => set({ isConnectWalletModalActive: value }),
    status: true,
    setStatus: (value) => set({ status: value }),
    type: '',
    setType: (value) => set({ type: value }),
    walletProviderError: '',
    setWalletProviderError: (value) => set({ walletProviderError: value }),
    trigger: '',
    setTrigger: (value) => set({ trigger: value })
}));
