'use client';
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useUserStore } from "../../user.store";
import isJSON from "@helpers/isJSON.hl";
import {getKeys} from "@helpers/indexedDb.hl";
import USER_WALLET_TYPES from "@constants/userWallets.const";
import { ethers } from "ethers";
import { useWalletStore } from "@/store/zustand/wallet.store";
import {set} from "immutable";

const WalletStoreInitializer = () => {
    const {
        user: {
            externalId,
            meta
        }
    } = useUserStore();

    const {
        setType,
        setStatus,
        trigger
    } = useWalletStore();

    const checkWeb = async () => {
        try {
            const parsedUserMeta = isJSON(meta) ? JSON.parse(meta) : {};
            const key = await getKeys('userKeys');
            setType(parsedUserMeta?.connectType);
            if (parsedUserMeta?.connectType === USER_WALLET_TYPES.WEB2) {
                setStatus(
                    key?.type === USER_WALLET_TYPES.WEB2 &&
                    key.publicKey === parsedUserMeta?.connectAddress &&
                    parsedUserMeta?.connectType === USER_WALLET_TYPES.WEB2
                );
            } else if (parsedUserMeta?.connectType === USER_WALLET_TYPES.WEB3) {
                if (typeof (window as any).ethereum === 'undefined') return setStatus(false);
                const provider = new ethers.BrowserProvider((window as any).ethereum);
                const signer = await provider.getSigner();
                const address = await signer.getAddress();
                setStatus(
                    key?.type === USER_WALLET_TYPES.WEB3 &&
                    address === parsedUserMeta?.connectAddress &&
                    parsedUserMeta?.connectType === USER_WALLET_TYPES.WEB3
                );
            } else {
                setStatus(false);
            }
        } catch (e) {
            console.warn(e);
            setStatus(false);
        }
    };

    useEffect(() => {
        checkWeb();
    }, [externalId, meta, trigger]);

    return null;
}

export default WalletStoreInitializer;
