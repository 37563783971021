'use client';

// Components
import Text from '@/common/Text/index.server';
import InputComponent from '@/components/Form/Input/index.client';
import Button from '@/common/Button/index.client';

// Types
import { ITextContent } from '@/types/page.types';

// Helpers
import firstLetterToUpperCase from '@/helpers/firstLetterToUpperCase.hl';

// Styles
import styles from '../index.module.sass';

interface IProps {
  translation: ITextContent;
  mnemonicWords: string[];
  setMnemonicWords: React.Dispatch<React.SetStateAction<string[]>>;
  restoreWalletPin: () => void;
}

const RestoreWalletStep1Content = (props: IProps) => {
  const {
    translation: {
      metaData: {
        json: {
          step = '',
          enterMnemonicPhrase = '',
          enterMnemonicPhraseSubtitle = '',
          restore = ''
        } = {}
      } = {}
    } = {},
    mnemonicWords,
    setMnemonicWords,
    restoreWalletPin
  } = props;

  const changeHandlerWithMultiplePaste = (word: string, index: number) => {
    const words = word.split(' ');

    if (words?.length > 1) {
      return setMnemonicWords(words);
    }

    setMnemonicWords((prev: string[]) => {
      prev[index] = word;
      return [...prev];
    })
  }

  return (
    <>
      <Text
        tag="h6"
        size="y-medium"
        transform="uppercase"
        weight="400"
        fontFamily="copperplate"
        color="middle-gray"
        className={`${styles['connect-wallet-modal__step']} turn--center`}
      >
        {step} 1
      </Text>
      <Text
        tag="h5"
        size="big"
        transform="uppercase"
        weight="400"
        fontFamily="copperplate"
        className={`${styles['connect-wallet-modal__subtitled-title']} turn--center`}
      >
        {enterMnemonicPhrase}
      </Text>
      <Text
        size="minimum"
        weight="300"
        fontFamily="ubuntu"
        color="extra-gray"
        className={`${styles['connect-wallet-modal__subtitle']} turn--center`}
      >
        {enterMnemonicPhraseSubtitle}
      </Text>
      <div className={`${styles['connect-wallet-modal__mnemonic-words']} fl fl--gap-16 fl--wrap`}>
        {Array.from({ length: 12 }, (v, i) => i).map((val, index) => (
          <div key={index} className={styles['connect-wallet-modal__mnemonic-word']}>
            <InputComponent
              index={index + 1}
              name={`pin-${index}`}
              key={`pin-${index}`}
              value={mnemonicWords[index]}
              onChange={(_, word) => changeHandlerWithMultiplePaste(word, index)}
              classNames={{
                field: styles['connect-wallet-modal__mnemonic-word-field']
              }}
            />
          </div>
        ))}
      </div>
      <Button
        onClick={restoreWalletPin}
        disabled={mnemonicWords.some(word => !word)}
        className={styles['connect-wallet-modal__button']}
      >
        {restore}
      </Button>
    </>
  );
};

export default RestoreWalletStep1Content;
