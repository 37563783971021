import { useCookies } from 'react-cookie';
import {
    useQuery,
} from '@tanstack/react-query'
import { IText } from '@/types/text.types';
import { getText, getTexts } from '@/actions/texts';

const useTexts = (labels: string[]): [any | { [key: string]: IText }, { loading: boolean }] => {
    const [{ language }] = useCookies();
    const { isPending, data } = useQuery({
        queryKey: ['texts'],
        queryFn: () => labels?.length > 1 ? getTexts({ labels, language }) : getText({ label: labels?.[0], language }) as any
    });

    return [data || {}, { loading: isPending }];
};

export default useTexts;
