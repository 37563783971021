'use client';
import React, { useState } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    Tooltip,
    ResponsiveContainer
} from 'recharts';

// Components
import Text from '@/common/Text/index.server';
import ChartTooltip from '@/components/ScheduleTooltip/index.server';
import { GraphData } from '../../types';

// Styles
import styles from './index.module.sass';

interface ICharts {
    className?: string;
    translations?: { [key: string]: string };
    type: 'amount' | 'wallets' | 'both';
    data: {
        wallets: GraphData[];
        amount: GraphData[];
    }
}

interface ICustomizedAxisTick {
    x: number,
    y: number,
    payload: any,
    isActive: boolean
}

const CustomizedAxisTick = ({ x, y, payload, isActive }: ICustomizedAxisTick) => (
    <g transform={`translate(${x},${y})`}>
        <foreignObject x={-22} y={4} width={50} height={38} className='padding--10'>
            <Text
                size="tiny"
                fontFamily="ubuntu"
                weight="400"
                color={isActive ? 'white' : 'medium-gray'}
                className={isActive ? styles['chart-month-text'] : ''}
            >
                {payload.value}
            </Text>
        </foreignObject>
    </g>
);

const Charts = ({ className, type, data, translations }: ICharts): JSX.Element => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleMouseMove = (state: any) => {
        if (state && state.activeTooltipIndex !== undefined) {
            setActiveIndex(state.activeTooltipIndex);
        }
    };

    const handleMouseLeave = () => {
        setActiveIndex(null);
    };

    const getData = () => {
        if (type === 'both') {
            return data.amount.map((item, index) => ({
                ...item,
                wallets: data.wallets[index]?.amount || 0
            }));
        }
        return data[type];
    };

    return (
        <div className={className}>
            <ResponsiveContainer className="rechart-custom recharts-custom-cursor-hover" width="100%" height="100%">
                <LineChart
                    data={getData()}
                    margin={{
                        top: 40,
                        right: 30,
                        left: 20,
                        bottom: 10,
                    }}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                >
                    <XAxis
                        dataKey="name"
                        tickLine={false}
                        tick={(props) => (
                            <CustomizedAxisTick
                                {...props}
                                isActive={props.index === activeIndex}
                            />
                        )}
                    />
                    <Tooltip content={<ChartTooltip translations={translations} />} cursor={false} />
                    {type === 'both' ? (
                        <>
                            <Line
                                type="monotone"
                                dataKey="amount"
                                stroke="#6C1304"
                                strokeWidth={2}
                                dot={(props) => <CustomDot {...props} type="amount" activeIndex={activeIndex} />}
                            />
                            <Line
                                type="monotone"
                                dataKey="wallets"
                                stroke="#BCA08CCB"
                                strokeWidth={2}
                                dot={(props) => <CustomDot {...props} type="wallets" activeIndex={activeIndex} />}
                            />
                        </>
                    ) : (
                        <Line
                            type="monotone"
                            dataKey="amount"
                            stroke="#6C1304"
                            strokeWidth={2}
                            dot={<CustomDot activeIndex={activeIndex} />}
                        />
                    )}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

const CustomDot = ({ cx, cy, payload, activeIndex, type }: any) => {
    const isActive = activeIndex === payload.index;

    return (
        <svg
            x={cx - 6.5}
            y={cy - 6.5}
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {type === 'wallets' ? (
                <>
                    {isActive ? (
                        <circle
                            cx="6.5"
                            cy="6.5"
                            r="6"
                            fill="#BCA08CCB"
                        />
                    ) : (
                        <circle
                            cx="6.5"
                            cy="6.5"
                            r="6"
                            fill="#BCA08CCB"
                        />
                    )}
                    <circle
                        cx="6.5"
                        cy="6.5"
                        r="3"
                        fill="#CDCDCD"
                    />
                </>
            ) : (
                <>
                    {isActive ? (
                        <circle
                            cx="6.5"
                            cy="6.5"
                            r="6"
                            fill="#CDCDCD"
                            stroke="#6C1304"
                            strokeWidth="2"
                        />
                    ) : (
                        <>
                            <circle
                                cx="6.5"
                                cy="6.5"
                                r="6"
                                fill="#6C1304"
                            />
                            <circle
                                cx="6.5"
                                cy="6.5"
                                r="3"
                                fill="#120807"
                            />
                        </>
                    )}
                </>
            )}
        </svg>
    );
};

export default Charts;
