'use client';
import React from 'react';
import Icon from '@/common/Icon/index.client';

// Styles
import styles from './index.module.sass';
import LoaderBlur from '@/common/LoaderBlur/index.client';

interface IProps {
  isOpened: boolean;
  loading?: boolean,
  hideCloseButton?: boolean,
  setIsOpened?: (isOpened: boolean) => void;
  children: JSX.Element;
  classNames?: {
    dialogClass?: string,
    innerClass?: string,
    backdropClass?: string
  }
}

const ClientModal = (props: IProps) => {
  const {
    isOpened,
    loading = false,
    setIsOpened,
    hideCloseButton,
    children,
    classNames: {
      dialogClass = '',
      innerClass = '',
      backdropClass = ''
    } = {}
  } = props;

  const onClose = () => setIsOpened?.(false);

  return (
    <>
      <dialog className={`${styles['client-modal']} ${dialogClass || ''} scrollbar scrollbar--invisible`} open={!!isOpened}>
        <div className={`${styles['client-modal__inner']} ${innerClass || ''} relative--core`}>
          <LoaderBlur fetch={loading} />
          {!hideCloseButton ? (
            <Icon
              icon="flat/close"
              size={8}
              color="color--extra-gray"
              classNames={{
                wrapper: `${styles['client-modal__close-btn']} fl fl--justify-c fl--align-c cursor--custom-pointer`,
                icon: styles['client-modal__close-btn-icon']
              }}
              onClick={onClose}
            />
          ) : null}
          {children}
        </div>
      </dialog>
      <div
        onClick={onClose}
        className={`${styles['client-modal__backdrop']} ${!!isOpened ? styles['client-modal__backdrop--visible'] : ''} ${backdropClass} cursor--custom-pointer`}
      />
    </>
  );
};

export default ClientModal;
