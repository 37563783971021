import apple from './generated/colorful/apple.svgr';
import cardLock from './generated/colorful/card-lock.svgr';
import cardPada from './generated/colorful/card-pada.svgr';
import cardWallet from './generated/colorful/card-wallet.svgr';
import changeHero from './generated/colorful/change-hero.svgr';
import circleEmail from './generated/colorful/circle-email.svgr';
import circlePhone from './generated/colorful/circle-phone.svgr';
import circleRedBorder from './generated/colorful/circle-red-border.svgr';
import copied from './generated/colorful/copied.svgr';
import dash from './generated/colorful/dash.svgr';
import distortedSymbol from './generated/colorful/distorted-symbol.svgr';
import empireSymbol from './generated/colorful/empire-symbol.svgr';
import ethereum from './generated/colorful/ethereum.svgr';
import eyeHide from './generated/colorful/eye-hide.svgr';
import facebook from './generated/colorful/facebook.svgr';
import github from './generated/colorful/github.svgr';
import google from './generated/colorful/google.svgr';
import guardiansSymbol from './generated/colorful/guardians-symbol.svgr';
import linkedinWhite from './generated/colorful/linkedin-white.svgr';
import linkedin from './generated/colorful/linkedin.svgr';
import mailIcon from './generated/colorful/mail-icon.svgr';
import microsoft from './generated/colorful/microsoft.svgr';
import minusAccordion from './generated/colorful/minus-accordion.svgr';
import nextArrowThin from './generated/colorful/next-arrow-thin.svgr';
import nextArrow from './generated/colorful/next-arrow.svgr';
import nftSign from './generated/colorful/nft-sign.svgr';
import notificationError from './generated/colorful/notification-error.svgr';
import notificationSuccess from './generated/colorful/notification-success.svgr';
import notificationWarning from './generated/colorful/notification-warning.svgr';
import pada from './generated/colorful/pada.svgr';
import pdf from './generated/colorful/pdf.svgr';
import plusAccordion from './generated/colorful/plus-accordion.svgr';
import prevArrowThin from './generated/colorful/prev-arrow-thin.svgr';
import prevArrow from './generated/colorful/prev-arrow.svgr';
import raceDistorted from './generated/colorful/race-distorted.svgr';
import raceEmpire from './generated/colorful/race-empire.svgr';
import raceGuardians from './generated/colorful/race-guardians.svgr';
import raceSyndicate from './generated/colorful/race-syndicate.svgr';
import rejected from './generated/colorful/rejected.svgr';
import resourcesPlus from './generated/colorful/resources-plus.svgr';
import scannerBalances from './generated/colorful/scanner-balances.svgr';
import scannerTotalOwners from './generated/colorful/scanner-total-owners.svgr';
import scannerTxnCount from './generated/colorful/scanner-txn-count.svgr';
import scannerVolume from './generated/colorful/scanner-volume.svgr';
import statusCompleted from './generated/colorful/status-completed.svgr';
import statusPending from './generated/colorful/status-pending.svgr';
import statusRejected from './generated/colorful/status-rejected.svgr';
import successful from './generated/colorful/successful.svgr';
import syndicateSymbol from './generated/colorful/syndicate-symbol.svgr';
import twitter from './generated/colorful/twitter.svgr';
import usdt from './generated/colorful/usdt.svgr';
import warning from './generated/colorful/warning.svgr';
import x from './generated/colorful/x.svgr';

import archer from './generated/flat/archer.svgr';
import arrowDown from './generated/flat/arrow-down.svgr';
import arrowLeft from './generated/flat/arrow-left.svgr';
import arrowLong from './generated/flat/arrow-long.svgr';
import buttonArrowToRight from './generated/flat/button-arrow-to-right.svgr';
import clock from './generated/flat/clock.svgr';
import close from './generated/flat/close.svgr';
import copy from './generated/flat/copy.svgr';
import cross from './generated/flat/cross.svgr';
import draghiSymbol from './generated/flat/draghi-symbol.svgr';
import draghi from './generated/flat/draghi.svgr';
import eye from './generated/flat/eye.svgr';
import gamepad from './generated/flat/gamepad.svgr';
import global from './generated/flat/global.svgr';
import infinity from './generated/flat/infinity.svgr';
import integration from './generated/flat/integration.svgr';
import logout from './generated/flat/logout.svgr';
import luriSymbol from './generated/flat/luri-symbol.svgr';
import luri from './generated/flat/luri.svgr';
import menuBurger from './generated/flat/menu-burger.svgr';
import menuDownArrow from './generated/flat/menu-down-arrow.svgr';
import muskansSymbol from './generated/flat/muskans-symbol.svgr';
import muskans from './generated/flat/muskans.svgr';
import nft from './generated/flat/nft.svgr';
import noData from './generated/flat/no-data.svgr';
import padaGray from './generated/flat/pada-gray.svgr';
import pen from './generated/flat/pen.svgr';
import personalArea from './generated/flat/personal-area.svgr';
import play from './generated/flat/play.svgr';
import plus from './generated/flat/plus.svgr';
import refresh from './generated/flat/refresh.svgr';
import reopen from './generated/flat/reopen.svgr';
import sandySymbol from './generated/flat/sandy-symbol.svgr';
import search from './generated/flat/search.svgr';
import sideBarClose from './generated/flat/side-bar-close.svgr';
import sortVertical from './generated/flat/sort-vertical.svgr';
import star from './generated/flat/star.svgr';
import stonks from './generated/flat/stonks.svgr';
import storeAssetsNav from './generated/flat/store-assets-nav.svgr';
import storeDraghi from './generated/flat/store-draghi.svgr';
import storeTirians from './generated/flat/store-tirians.svgr';
import support from './generated/flat/support.svgr';
import swords from './generated/flat/swords.svgr';
import tiriansSymbol from './generated/flat/tirians-symbol.svgr';
import tirians from './generated/flat/tirians.svgr';
import tishianSymbol from './generated/flat/tishian-symbol.svgr';
import tishian from './generated/flat/tishian.svgr';
import tooltipIcon from './generated/flat/tooltip-icon.svgr';
import transactionTypeAirdrop from './generated/flat/transaction-type-airdrop.svgr';
import transactionTypeBurnt from './generated/flat/transaction-type-burnt.svgr';
import transactionTypeMinting from './generated/flat/transaction-type-minting.svgr';
import transactionTypeStaking from './generated/flat/transaction-type-staking.svgr';
import transactionTypeSwap from './generated/flat/transaction-type-swap.svgr';
import transactionTypeTransfer from './generated/flat/transaction-type-transfer.svgr';
import treantsSymbol from './generated/flat/treants-symbol.svgr';
import treants from './generated/flat/treants.svgr';
import uploadFile from './generated/flat/uploadFile.svgr';

const icons = {
  colorful: {
    apple,
    'card-lock': cardLock,
    'card-pada': cardPada,
    'card-wallet': cardWallet,
    'change-hero': changeHero,
    'circle-email': circleEmail,
    'circle-phone': circlePhone,
    'circle-red-border': circleRedBorder,
    copied,
    dash,
    'distorted-symbol': distortedSymbol,
    'empire-symbol': empireSymbol,
    ethereum,
    'eye-hide': eyeHide,
    facebook,
    github,
    google,
    'guardians-symbol': guardiansSymbol,
    'linkedin-white': linkedinWhite,
    linkedin,
    'mail-icon': mailIcon,
    microsoft,
    'minus-accordion': minusAccordion,
    'next-arrow-thin': nextArrowThin,
    'next-arrow': nextArrow,
    'nft-sign': nftSign,
    'notification-error': notificationError,
    'notification-success': notificationSuccess,
    'notification-warning': notificationWarning,
    pada,
    pdf,
    'plus-accordion': plusAccordion,
    'prev-arrow-thin': prevArrowThin,
    'prev-arrow': prevArrow,
    'race-distorted': raceDistorted,
    'race-empire': raceEmpire,
    'race-guardians': raceGuardians,
    'race-syndicate': raceSyndicate,
    rejected,
    'resources-plus': resourcesPlus,
    'scanner-balances': scannerBalances,
    'scanner-total-owners': scannerTotalOwners,
    'scanner-txn-count': scannerTxnCount,
    'scanner-volume': scannerVolume,
    'status-completed': statusCompleted,
    'status-pending': statusPending,
    'status-rejected': statusRejected,
    successful,
    'syndicate-symbol': syndicateSymbol,
    twitter,
    usdt,
    warning,
    x
  },
  flat: {
    archer,
    'arrow-down': arrowDown,
    'arrow-left': arrowLeft,
    'arrow-long': arrowLong,
    'button-arrow-to-right': buttonArrowToRight,
    clock,
    close,
    copy,
    cross,
    'draghi-symbol': draghiSymbol,
    draghi,
    eye,
    gamepad,
    global,
    infinity,
    integration,
    logout,
    'luri-symbol': luriSymbol,
    luri,
    'menu-burger': menuBurger,
    'menu-down-arrow': menuDownArrow,
    'muskans-symbol': muskansSymbol,
    muskans,
    nft,
    'no-data': noData,
    'pada-gray': padaGray,
    pen,
    'personal-area': personalArea,
    play,
    plus,
    refresh,
    reopen,
    'sandy-symbol': sandySymbol,
    search,
    'side-bar-close': sideBarClose,
    'sort-vertical': sortVertical,
    star,
    stonks,
    'store-assets-nav': storeAssetsNav,
    'store-draghi': storeDraghi,
    'store-tirians': storeTirians,
    support,
    swords,
    'tirians-symbol': tiriansSymbol,
    tirians,
    'tishian-symbol': tishianSymbol,
    tishian,
    'tooltip-icon': tooltipIcon,
    'transaction-type-airdrop': transactionTypeAirdrop,
    'transaction-type-burnt': transactionTypeBurnt,
    'transaction-type-minting': transactionTypeMinting,
    'transaction-type-staking': transactionTypeStaking,
    'transaction-type-swap': transactionTypeSwap,
    'transaction-type-transfer': transactionTypeTransfer,
    'treants-symbol': treantsSymbol,
    treants,
    uploadFile
  }
};

export default icons;