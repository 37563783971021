'use client';

// Components
import Text from '@/common/Text/index.server';
import InputComponent from '@/components/Form/Input/index.client';
import Button from '@/common/Button/index.client';

// Types
import { ITextContent } from '@/types/page.types';

// Helpers
import firstLetterToUpperCase from '@/helpers/firstLetterToUpperCase.hl';

// Styles
import styles from '../index.module.sass';

interface IProps {
  translation: ITextContent;
  mnemonicWords: string[];
  checkMnemonicWords: string[];
  setCheckMnemonicWords: React.Dispatch<React.SetStateAction<string[]>>;
  checkMnemonic: () => Promise<void>;
}

const ConnectWalletStep3Content = (props: IProps) => {
  const {
    translation: {
      metaData: {
        json: {
          step = '',
          enterMnemonicPhrase = '',
          enterMnemonicPhraseSubtitle = '',
          createWallet = ''
        } = {}
      } = {}
    } = {},
    mnemonicWords,
    checkMnemonicWords,
    setCheckMnemonicWords,
    checkMnemonic
  } = props;

  return (
    <>
      <Text
        tag="h6"
        size="y-medium"
        transform="uppercase"
        weight="400"
        fontFamily="copperplate"
        color="middle-gray"
        className={`${styles['connect-wallet-modal__step']} turn--center`}
      >
        {step} 3
      </Text>
      <Text
        tag="h5"
        size="big"
        transform="uppercase"
        weight="400"
        fontFamily="copperplate"
        className={`${styles['connect-wallet-modal__subtitled-title']} turn--center`}
      >
        {enterMnemonicPhrase}
      </Text>
      <Text
        size="minimum"
        weight="300"
        fontFamily="ubuntu"
        color="extra-gray"
        className={`${styles['connect-wallet-modal__subtitle']} turn--center`}
      >
        {enterMnemonicPhraseSubtitle}
      </Text>
      <div className={`${styles['connect-wallet-modal__mnemonic-words']} fl fl--gap-16 fl--wrap`}>
        {mnemonicWords?.map((val: string, index) => {
          const i = index % 3;

          return (
            <div key={index} className={styles['connect-wallet-modal__mnemonic-word']}>
              <InputComponent
                index={index + 1}
                name={`pin-${index}`}
                key={`pin-${index}`}
                value={i === 0 ? `${checkMnemonicWords[index]}` : '*****'}
                onChange={(_, word) => setCheckMnemonicWords((prev: string[]) => {
                  prev[index] = i === 0 ? word : prev[index];
                  return [...prev];
                })}
                classNames={{
                  field: styles['connect-wallet-modal__mnemonic-word-field']
                }}
              />
            </div>
          )
        })}
      </div>
      <Button
        onClick={checkMnemonic}
        disabled={checkMnemonicWords.some(word => !word)}
        className={styles['connect-wallet-modal__button']}
      >
        {createWallet}
      </Button>
    </>
  );
};

export default ConnectWalletStep3Content;
