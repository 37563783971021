'use client';
import { useState } from 'react';
import * as Yup from 'yup';

// Components
import Text from '@/common/Text/index.server';
import InputComponent from '@/components/Form/Input/index.client';
import Button from '@/common/Button/index.client';

// Types
import { ITextContent } from '@/types/page.types';

// Styles
import styles from '../index.module.sass';

interface IProps {
  translation: ITextContent;
  pin: string;
  checkPin: string;
  handlePinToValue: (_: string, value: string) => void;
  handleCheckPinToValue: (_: string, value: string) => void;
  connectWalletMnemonicPhrase: () => Promise<void>;
}

const ConnectWalletStep1Content = (props: IProps) => {
  const {
    translation: {
      metaData: {
        json: {
          step = '',
          generatePin = '',
          generatePinSubtitle = '',
          pinPlaceholder = '',
          generateMnemonicPhrase = '',
          fieldIsRequired = '',
          validationMinThreeSymbols = '',
          passwordsNeedBeSimilar = '',
          validationMaxSixSymbols = '',
          checkPinPlaceholder = ''
        } = {}
      } = {}
    } = {},
    pin = '',
    checkPin = '',
    handlePinToValue,
    handleCheckPinToValue,
    connectWalletMnemonicPhrase
  } = props;

  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const pinErrorsSchema = Yup.string()
    .required(fieldIsRequired)
    .min(3, validationMinThreeSymbols)
    .max(6, validationMaxSixSymbols);

  const checkPinErrorsSchema = Yup.string()
    .required(fieldIsRequired)
    .test('matches', passwordsNeedBeSimilar, function () {
      if (checkPin !== pin) {
        return this.createError({ message: passwordsNeedBeSimilar });
      }
      return true;
    });

  const handleButtonClick = () => {
    setIsButtonClicked(true);
    connectWalletMnemonicPhrase();
  }

  const getError = (schema: Yup.StringSchema<string, Yup.AnyObject, undefined, "">, value: string): string | undefined => {
    try {
      schema.validateSync(pin);
      return undefined;
    } catch (error) {
      return (error as Yup.ValidationError).message;
    }
  };

  return (
    <>
      <Text
        tag="h6"
        size="y-medium"
        transform="uppercase"
        weight="400"
        fontFamily="copperplate"
        color="middle-gray"
        className={`${styles['connect-wallet-modal__step']} turn--center`}
      >
        {step} 1
      </Text>
      <Text
        tag="h5"
        size="big"
        transform="uppercase"
        weight="400"
        fontFamily="copperplate"
        className={`${styles['connect-wallet-modal__subtitled-title']} turn--center`}
      >
        {generatePin}
      </Text>
      <Text
        size="minimum"
        weight="300"
        fontFamily="ubuntu"
        color="extra-gray"
        className={`${styles['connect-wallet-modal__subtitle']} turn--center`}
      >
        {generatePinSubtitle}
      </Text>
      <InputComponent
        name="pin"
        placeholder={pinPlaceholder}
        value={pin}
        onChange={handlePinToValue as any}
        meta={{ error: getError(pinErrorsSchema, pin), touched: isButtonClicked }}
        maxLength={6}
        classNames={{ wrapper: "margin--b-16" }}
        type="password"
      />
      <InputComponent
        name="pin"
        placeholder={checkPinPlaceholder}
        value={checkPin}
        onChange={handleCheckPinToValue as any}
        meta={{ error: getError(checkPinErrorsSchema, checkPin), touched: isButtonClicked }}
        maxLength={6}
        type="password"
        classNames={{ wrapper: styles['connect-wallet-modal__last-input'] }}
      />
      <Button
        onClick={handleButtonClick}
        disabled={!Boolean(pin || checkPin) || isButtonClicked ? !!getError(pinErrorsSchema, pin) || !!getError(checkPinErrorsSchema, checkPin) : false}
        className={styles['connect-wallet-modal__button']}
      >
        {generateMnemonicPhrase}
      </Button>
    </>
  );
};

export default ConnectWalletStep1Content;
