import Link from 'next/link';
import Image from 'next/image';

// Helpers
import getFullPath from '@/helpers/getFullPath.hl';

// Styles
import logoStyles from './index.module.sass';

import logoImg from '/public/img/poliada-logo.svg'

interface LogoTypes {
  classNames?: {
    wrapper?: string;
    icon?: string;
  },
  theme?: string;
  lang?: string;
  path?: string;
}

const Logo = (props: LogoTypes) => {
  const {
    classNames: {
      wrapper = '',
      icon = ''
    } = {},
    lang = '',
    path = ''
  } = props;

  return (
    <Link href={path ? path : getFullPath('/', lang)} className={`${logoStyles.logo} ${wrapper} block--core width--fit-content cursor--custom-pointer fl fl--align-c fl--justify-c padding--h-4`}>
      <Image
        src={logoImg}
        width={38}
        height={48}
        alt="logo"
        className={`${icon} ${logoStyles.logo__icon}`}
      />
    </Link>
  );
};

export default Logo;
