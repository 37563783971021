import { create } from 'zustand';

// State types
interface States {
    user?: any,
    set: (entity: any) => void;
}

const useUserStore = create<States>()(
    (set) => ({
        user: {},
        set: (entity: any) => set((state) => {
            return { user: { ...(state?.user || {}), ...entity } };
        })
    })
);

export {
    useUserStore
};
