import { IAsset } from '@/types/crm/coreBanking/assets.types';
import { create } from 'zustand';

// State types
interface States {
    currencies?: {
        [symbol: string]: {
            balance: number,
            address: string,
            symbol: string
        } & IAsset;
    },
    set: (entity: any) => void;
    isBlockchainLoaded?: boolean;
    setBlockchainLoaded: (loading: boolean) => void;
}

const useAssetsStore = create<States>()(
    (set) => ({
        set: (entities: any) => set((state) => ({ ...state, currencies: { ...state.currencies, ...entities } })),
        setBlockchainLoaded: (loading: boolean) => set((state) => ({ ...state, isBlockchainLoaded: loading })),
    }),

);

export {
    useAssetsStore
};