'use client'

import ReactDOM from 'react-dom'

export function PreloadResources() {
  ReactDOM.preconnect('https://cdnjs.cloudflare.com', { crossOrigin: 'anonymous' })
  ReactDOM.preconnect('https://res.cloudinary.com', { crossOrigin: 'anonymous' })

  return null;
}
