import { Contract, formatUnits } from "ethers";
import getProvider from "./getProvider.hl";
import {
    CURRENCIES,
    PADA_ABI,
    PADA_CONTRACT_ADDRESS,
    PADA_DEFAULT_PRECISION,
    USDT_ABI,
    USDT_CONTRACT_ADDRESS,
    USDT_DEFAULT_PRECISION
} from "@/constants/token.const";

const formatNumberToString = (value: number): string => {
    const [integerPart, decimalPart] = String(value).split('.');
    return `${integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}${decimalPart ? `.${decimalPart}` : ''}`;
}

const formatNumberValue = (opts: {
    value: any;
    precision?: number;
    translations: {
        billionSuffix: string;
        millionSuffix: string;
        thousandSuffix: string;
    }
}): any => {
    const { value, precision, translations } = opts;
    if (typeof value === 'number') {
        if (value >= 1000000000) {
            return `${(value / 1000000000).toFixed(precision || 0)}${translations.billionSuffix}`;
        } else if (value >= 1000000) {
            return `${(value / 1000000).toFixed(precision || 0)}${translations.millionSuffix}`;
        } else if (value >= 1000) {
            return `${(value / 1000).toFixed(precision || 0)}${translations.thousandSuffix}`;
        } else {
            return value.toString();
        }
    }
    return value;
}

const syncBalances = async (opts: {
    externalId?: string;
    set: (entity: any) => void
}): Promise<boolean> => {
    const { externalId, set } = opts;
    try {
        if (externalId) {
            const provider = getProvider();

            const padaContract = new Contract(PADA_CONTRACT_ADDRESS, PADA_ABI, provider);
            const padaBalance = await padaContract.balanceOf(externalId);
            const padaFormattedBalance = +formatUnits(padaBalance, PADA_DEFAULT_PRECISION);
            const padaPrepared = { address: externalId, balance: padaFormattedBalance, symbol: CURRENCIES.PADA };
            
            const usdtContract = new Contract(USDT_CONTRACT_ADDRESS, USDT_ABI, provider);
            const usdtBalance = await usdtContract.balanceOf(externalId);
            const usdtFormattedBalance = +formatUnits(usdtBalance, USDT_DEFAULT_PRECISION);
            const usdtPrepared = { address: externalId, balance: usdtFormattedBalance, symbol: CURRENCIES.USDT };

            set({
                [CURRENCIES.PADA]: padaPrepared,
                [CURRENCIES.USDT]: usdtPrepared
            });
        }
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}


export {
    formatNumberToString,
    formatNumberValue,
    syncBalances
}
