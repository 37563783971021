const PADA_CONTRACT_ADDRESS = process.env.NEXT_PUBLIC_PADA_CONTRACT_ADDRESS || '';

const PADA_ABI = [
    "function approve(address spender, uint256 amount) public returns (bool)",
    "function decimals() view returns (uint8)",
    "function symbol() view returns (string)",
    "function balanceOf(address a) view returns (uint)",
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            }
        ],
        "name": "allowance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];

const EXCHANGE_CONTRACT_ADDRESS = process.env.NEXT_PUBLIC_EXCHANGE_CONTRACT_ADDRESS || '';
const EXCHANGE_ABI = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "user",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "exchange",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];

const USDT_CONTRACT_ADDRESS = process.env.NEXT_PUBLIC_USDT_CONTRACT_ADDRESS || '';
const USDT_ABI = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    "function decimals() view returns (uint8)",
    "function balanceOf(address a) view returns (uint)",
    "function transfer(address receiver, uint256 numTokens) public override returns (bool)",
];

const LBP_CONTRACT_ADDRESS = process.env.NEXT_PUBLIC_LBP_CONTRACT_ADDRESS as string;
const LBP_ABI = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "tokenIn",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amountIn",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "timestamp",
                "type": "uint256"
            }
        ],
        "name": "swap",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "tokenIn",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amountIn",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "timestamp",
                "type": "uint256"
            }
        ],
        "name": "getAmountTo",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "amountTo",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];

const WALLET_FACTORY_ABI = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "internalType": "address[]",
                "name": "guardians",
                "type": "address[]"
            },
            {
                "internalType": "uint256",
                "name": "recoveryThreshold",
                "type": "uint256"
            }
        ],
        "name": "createWallet",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "owner",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "address",
                "name": "walletAddress",
                "type": "address"
            }
        ],
        "name": "WalletCreated",
        "type": "event"
    }
];

const SMART_WALLET_ABI = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "dest",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "value",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "func",
                "type": "bytes"
            }
        ],
        "name": "execute",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address[]",
                "name": "dest",
                "type": "address[]"
            },
            {
                "internalType": "uint256[]",
                "name": "values",
                "type": "uint256[]"
            },
            {
                "internalType": "bytes[]",
                "name": "func",
                "type": "bytes[]"
            }
        ],
        "name": "executeBatch",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": false,
                "internalType": "bytes",
                "name": "returnData",
                "type": "bytes"
            }
        ],
        "name": "ExecuteFailed",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "getNonce",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];

const ENTRYPOINT_ADDRESS = process.env.NEXT_PUBLIC_ENTRYPOINT_CONTRACT_ADDRESS as string;

const ENTRYPOINT_ABI = [
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "sender",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "nonce",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bytes",
                        "name": "initCode",
                        "type": "bytes"
                    },
                    {
                        "internalType": "bytes",
                        "name": "callData",
                        "type": "bytes"
                    },
                    {
                        "internalType": "bytes32",
                        "name": "accountGasLimits",
                        "type": "bytes32"
                    },
                    {
                        "internalType": "uint256",
                        "name": "preVerificationGas",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bytes32",
                        "name": "gasFees",
                        "type": "bytes32"
                    },
                    {
                        "internalType": "bytes",
                        "name": "paymasterAndData",
                        "type": "bytes"
                    },
                    {
                        "internalType": "bytes",
                        "name": "signature",
                        "type": "bytes"
                    }
                ],
                "internalType": "struct PackedUserOperation",
                "name": "userOp",
                "type": "tuple"
            }
        ],
        "name": "getUserOpHash",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "sender",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "nonce",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bytes",
                        "name": "initCode",
                        "type": "bytes"
                    },
                    {
                        "internalType": "bytes",
                        "name": "callData",
                        "type": "bytes"
                    },
                    {
                        "internalType": "bytes32",
                        "name": "accountGasLimits",
                        "type": "bytes32"
                    },
                    {
                        "internalType": "uint256",
                        "name": "preVerificationGas",
                        "type": "uint256"
                    },
                    {
                        "internalType": "bytes32",
                        "name": "gasFees",
                        "type": "bytes32"
                    },
                    {
                        "internalType": "bytes",
                        "name": "paymasterAndData",
                        "type": "bytes"
                    },
                    {
                        "internalType": "bytes",
                        "name": "signature",
                        "type": "bytes"
                    }
                ],
                "internalType": "struct PackedUserOperation[]",
                "name": "ops",
                "type": "tuple[]"
            },
            {
                "internalType": "address payable",
                "name": "beneficiary",
                "type": "address"
            }
        ],
        "name": "handleOps",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];

const PADA_PAYMASTER_ADDRESS = process.env.NEXT_PUBLIC_PADA_PAYMASTER_CONTRACT_ADDRESS as string;

const USDT_PAYMASTER_ADDRESS = process.env.NEXT_PUBLIC_USDT_PAYMASTER_CONTRACT_ADDRESS as string;

const WALLET_FACTORY_ADDRESS = process.env.NEXT_PUBLIC_WALLET_FACTORY_CONTRACT_ADDRESS as string;

const UNDERGROUND_CONTRACT_ADDRESS = process.env.NEXT_PUBLIC_UNDERGROUND_CONTRACT_ADDRESS || '';
const UNDERGROUND_ABI = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "_account",
                "type": "address"
            }
        ],
        "name": "getAllData",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "balance",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "rewards",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "stakeTimestamp",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "minimumLockPeriod",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "minimumStakeAmount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "minimumUnstakeAmount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "minimumClaimRewardAmount",
                        "type": "uint256"
                    }
                ],
                "internalType": "struct Underground.AllData",
                "name": "",
                "type": "tuple"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "stake",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_amount",
                "type": "uint256"
            }
        ],
        "name": "unstake",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "getBalance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "balance",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "getReward",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "reward",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getStatistics",
        "outputs": [
            {
                "components": [
                    {
                        "internalType": "uint256",
                        "name": "membersCount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "padaVolume",
                        "type": "uint256"
                    }
                ],
                "internalType": "struct Underground.Statistic",
                "name": "",
                "type": "tuple"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
];

const NFT_MINTING_CONTRACT_ADDRESS = process.env.NEXT_PUBLIC_NFT_MINTING_CONTRACT_ADDRESS || '';
const NFT_MINTING_ABI = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_id",
                "type": "uint256"
            }
        ],
        "name": "mint",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_tokenid",
                "type": "uint256"
            }
        ],
        "name": "getTemplatePrice",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "wallet",
                "type": "address"
            }
        ],
        "name": "ownItems",
        "outputs": [
            {
                "internalType": "uint256[]",
                "name": "",
                "type": "uint256[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_tokenid",
                "type": "uint256"
            }
        ],
        "name": "templateUri",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_tokenid",
                "type": "uint256"
            }
        ],
        "name": "uri",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_tokenid",
                "type": "uint256"
            }
        ],
        "name": "getPrice",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
];

const CURRENCIES = {
    PADA: 'pada',
    USDT: 'usdt',
    AMBER: 'amber'
}

const PADA_DEFAULT_PRECISION = 18;
const USDT_DEFAULT_PRECISION = 18;
const PADA_DASHBOARD_PRECISION_UI = 2;
const USDT_DEFAULT_PRECISION_UI = 2;
const PADA_TOKEN_SCANNER_PRECISION_UI = 4;
const USDT_PRECISION_UI = 2;
const PADA_PRECISION_UI = 2

const IPFS_PATH = process.env.NEXT_PUBLIC_IPFS_PATH || 'https://ipfs.io/ipfs/';

const TRANSACTION_TYPE = {
    TRANSFER: 'Transfer',
    SWAP: 'Swap',
    MINTING: 'Minting',
    BURNT: 'Burnt',
    STAKING: 'Staking',
    AIRDROP: 'Airdrop',
    REWARD: 'Reward',
    WALLET: 'Wallet'
}

const AMBER_TO_PADA_RATE = 80;

const BENEFICIARY_ADDRESS = process.env.NEXT_PUBLIC_BENEFICIRY_ADDRESS as string;

const USDT_PRICE_PER_TOKEN = process.env.NEXT_PUBLIC_USDT_PRICE_PER_TOKEN as string;
const PADA_PRICE_PER_TOKEN = process.env.NEXT_PUBLIC_PADA_PRICE_PER_TOKEN as string;

const BALANCE_POLLING_INTERVAL = 2500;

export {
    BALANCE_POLLING_INTERVAL,
    USDT_PRECISION_UI,
    PADA_PRECISION_UI,
    AMBER_TO_PADA_RATE,
    PADA_DASHBOARD_PRECISION_UI,
    PADA_TOKEN_SCANNER_PRECISION_UI,
    TRANSACTION_TYPE,
    IPFS_PATH,
    NFT_MINTING_CONTRACT_ADDRESS,
    NFT_MINTING_ABI,
    PADA_CONTRACT_ADDRESS,
    PADA_ABI,
    EXCHANGE_CONTRACT_ADDRESS,
    EXCHANGE_ABI,
    USDT_CONTRACT_ADDRESS,
    USDT_ABI,
    LBP_CONTRACT_ADDRESS,
    LBP_ABI,
    CURRENCIES,
    USDT_DEFAULT_PRECISION,
    PADA_DEFAULT_PRECISION,
    UNDERGROUND_CONTRACT_ADDRESS,
    UNDERGROUND_ABI,
    WALLET_FACTORY_ABI,
    WALLET_FACTORY_ADDRESS,
    SMART_WALLET_ABI,
    ENTRYPOINT_ADDRESS,
    PADA_PAYMASTER_ADDRESS,
    USDT_PAYMASTER_ADDRESS,
    ENTRYPOINT_ABI,
    BENEFICIARY_ADDRESS,
    USDT_PRICE_PER_TOKEN,
    PADA_PRICE_PER_TOKEN,
    USDT_DEFAULT_PRECISION_UI
}
