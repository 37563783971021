const isJSON = (jsonString?: string | null) => {
    if (!jsonString) return false;

    try {
        JSON.parse(jsonString);
        return true;
    } catch (e) {
        return false;
    }
}

export default isJSON;
