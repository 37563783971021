"use client";
import { LazyMotion, domAnimation, m } from "framer-motion";

const Slides = (props: {
    children: React.ReactNode,
    delay: number,
    coordinates: {
        x?: number, y?: number
    },
    margin?: string,
    className?: string
}): React.ReactNode => {
    const { children, delay, coordinates, margin, className } = props;

    return (
        <LazyMotion features={domAnimation}>
            <m.div
                viewport={{ once: true, margin: 0 || margin }}
                initial={{ ...coordinates, opacity: 0 }}
                whileInView={{ y: 0, x: 0, opacity: 1 }}
                transition={{ duration: .5, delay }}
                className={className || ''}
            >
                {
                    children
                }
            </m.div>
        </LazyMotion>
    )
}

export default Slides;