 const decryptPrivateKey = async ({
                               encryptedDataStr,
                               password,
                               saltStr,
                               ivStr
 }: {
     encryptedDataStr: string,
     password: string,
     saltStr: string,
     ivStr: string
 }) => {
    const encryptedData = JSON.parse(atob(encryptedDataStr));
    const salt = JSON.parse(atob(saltStr));
    const iv = JSON.parse(atob(ivStr));
    const enc = new TextEncoder();
    const keyMaterial = await window.crypto.subtle.importKey(
        "raw",
        enc.encode(password),
        { name: "PBKDF2" },
        false,
        ["deriveKey"]
    );
    const derivedKey = await window.crypto.subtle.deriveKey(
        {
            name: "PBKDF2",
            salt: new Uint8Array(salt),
            iterations: 100000,
            hash: "SHA-256"
        },
        keyMaterial,
        { name: "AES-GCM", length: 256 },
        true,
        ["decrypt"]
    );
    const decrypted = await window.crypto.subtle.decrypt(
        { name: "AES-GCM", iv: new Uint8Array(iv) },
        derivedKey,
        new Uint8Array(encryptedData)
    );
    const dec = new TextDecoder();
    return dec.decode(decrypted);
}

const encryptPrivateKey = async ({ privateKey, password }: { privateKey: string, password: string }) => {
    const enc = new TextEncoder();
    const keyMaterial = await window.crypto.subtle.importKey(
        "raw",
        enc.encode(password),
        { name: "PBKDF2" },
        false,
        ["deriveKey"]
    );
    const salt = window.crypto.getRandomValues(new Uint8Array(16));
    const key = await window.crypto.subtle.deriveKey(
        {
            name: "PBKDF2",
            salt: salt,
            iterations: 100000,
            hash: "SHA-256"
        },
        keyMaterial,
        { name: "AES-GCM", length: 256 },
        true,
        ["encrypt"]
    );
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const encrypted = await window.crypto.subtle.encrypt(
        { name: "AES-GCM", iv: iv },
        key,
        enc.encode(privateKey)
    );
    return {
        encryptedData: btoa(JSON.stringify(Array.from(new Uint8Array(encrypted)))),
        salt: btoa(JSON.stringify(Array.from(salt))),
        iv: btoa(JSON.stringify(Array.from(iv)))
    };
}

export { encryptPrivateKey, decryptPrivateKey };
