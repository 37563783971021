"use client"

import { useState, useRef } from 'react';
import loaderStyles from './index.module.sass'
import { motion, animate } from "framer-motion";

const draw = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: (i: number) => {
        const delay = 1 + i * 0.5;
        return {
            pathLength: 1,
            opacity: 1,
            transition: {
                pathLength: { delay, duration: 1, bounce: 0 },
                opacity: { delay, duration: 1 }
            }
        };
    }
};
  

const Loader = () => {
    const [hide, setHide] = useState(false)
    const refs = useRef([] as any);
    
    const handlerComplete = async () => {
        const main = refs.current['main'];
        const svg = refs.current['svg'];

        const sequence = [
            [svg, { opacity: 0 }, { ease: "easeInOut", duration: 0.5 }],
            [main, { opacity: 0 }, { ease: "easeInOut", duration: 1 }]
        ] as any;
        await animate(sequence);
        setHide(true);
    }

    return (
        <div 
            ref={(el: any) => refs.current['main'] = el} 
            className={`${loaderStyles.loader} fl fl--align-c fl--justify-c ${hide ? `none--core` : ''}`}
        >
            <div className="fl fl--justify-c" ref={(el: any) => refs.current['svg'] = el}>
                <motion.svg 
                    onAnimationComplete={handlerComplete}
                    initial="hidden"
                    whileInView="visible"
                    viewBox="0 0 1277 178" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <motion.path variants={draw} d="M29.4406 106.339V172.511H0.5V4.29785H75.9058C85.1379 4.29785 93.0994 5.55761 99.7935 7.65089C106.475 9.74029 112.305 13.0781 116.879 17.6546C121.47 22.2493 124.798 27.2482 126.879 33.4943C128.969 39.7699 130.227 47.3119 130.227 55.2792C130.227 63.2434 128.97 70.3636 126.879 76.642C124.8 82.8811 121.056 88.2992 116.887 92.4734C112.303 96.6413 106.884 99.9797 99.7848 102.488C93.0926 104.58 85.134 105.839 75.9058 105.839H29.9406H29.4406V106.339ZM67.3936 81.0979V81.5198H67.8936C79.3244 81.5198 87.8904 79.4047 93.5005 75.0864L93.5097 75.0793L93.5186 75.0718C99.1371 70.3145 101.708 63.8308 101.708 55.2792C101.708 46.7276 99.1371 40.2438 93.5186 35.4866L93.5097 35.4791L93.5005 35.472C87.9007 31.1616 79.3443 28.6165 67.8936 28.6165H29.9406H29.4406V29.1165V80.5979V81.0979H29.9406H67.3936Z" stroke="white"/>
                    <motion.path variants={draw} stroke="#6C1304" d="M295.5 132C301.213 132 306.869 130.875 312.147 128.689C317.424 126.503 322.22 123.298 326.259 119.259C330.298 115.22 333.503 110.424 335.689 105.147C337.875 99.8691 339 94.2125 339 88.5C339 82.7875 337.875 77.1309 335.689 71.8533C333.503 66.5756 330.298 61.7802 326.259 57.7409C322.22 53.7015 317.424 50.4973 312.147 48.3112C306.869 46.1252 301.212 45 295.5 45L295.5 88.5V132Z"/>
                    <motion.path variants={draw} d="M203.337 89.8814C203.337 46.0265 236.585 9.22949 279.087 3.10725V31.686C253.336 38.6241 234.387 62.357 234.387 90.3034C234.387 117.836 253.342 141.562 279.087 148.915V176.656C236.583 170.537 203.337 134.155 203.337 89.8814Z" stroke="white"/>
                    <motion.path variants={draw} d="M438.989 148.192H522.407V172.511H409.548V4.29785H438.489V147.692V148.192H438.989Z" stroke="white"/>
                    <motion.path variants={draw} d="M638.796 4.29785V172.511H609.855V4.29785H638.796Z" stroke="white"/>
                    <motion.path variants={draw} d="M859.142 172.089H827.729L769.041 4.29785H800.873L859.142 172.089Z" stroke="white"/>
                    <motion.path variants={draw} d="M762.354 149.364L762.354 149.372V149.38C762.354 162.186 751.613 172.933 738.817 172.933C726.015 172.933 715.28 162.602 715.28 149.38C715.28 136.569 725.605 125.828 738.817 125.828C752.044 125.828 762.766 136.58 762.354 149.364Z" stroke="#6C1304"/>
                    <motion.path variants={draw} d="M1076.55 49.1249L1076.55 49.1315C1080.32 58.7565 1082 70.4968 1081.16 83.9424L1081.16 83.958V83.9736C1081.16 98.2829 1079.48 110.891 1076.12 121.805C1072.76 132.717 1067.74 141.935 1061.04 149.47C1054.36 156.998 1045.57 162.861 1035.1 166.633C1024.62 170.408 1012.03 172.511 997.319 172.511H937.937V73.9241H966.878V147.27V147.77H967.378H999.427H999.434C1001.96 147.77 1004.94 147.77 1008.34 147.345C1012.17 146.919 1016 146.067 1019.83 144.791C1023.67 143.509 1027.93 141.378 1031.75 138.825C1035.59 136.266 1039.44 132.846 1042.43 128.137C1045.42 123.865 1047.97 118.333 1050.08 111.556C1052.22 105.153 1053.06 97.075 1053.06 87.7714C1053.06 78.4427 1051.79 69.9643 1050.1 62.7602C1048.39 55.5154 1045.41 49.5363 1041.14 44.4097C1036.87 39.2841 1031.74 35.4339 1024.91 32.87C1018.51 30.3081 1010.43 29.0384 1000.69 29.0384H968.221H938.781V4.71973H998.162C1011.61 4.71973 1023.37 6.40239 1033.85 9.75965L1033.86 9.76245L1033.87 9.76492C1044.34 12.6977 1053.12 17.7222 1060.23 24.4167L1060.24 24.4222L1060.24 24.4275C1067.34 30.6937 1072.78 39.0599 1076.55 49.1249Z" stroke="white"/>
                    <motion.path variants={draw} d="M1275.36 172.089H1243.95L1185.26 4.29785H1217.09L1275.36 172.089Z" stroke="white"/>
                    <motion.path variants={draw} d="M1131.92 149.38C1131.92 136.569 1142.24 125.828 1155.46 125.828C1168.25 125.828 1178.99 136.575 1178.99 149.38C1178.99 162.186 1168.25 172.933 1155.46 172.933C1142.65 172.933 1131.92 162.602 1131.92 149.38Z" stroke="#6C1304"/>
                </motion.svg>
            </div>
        </div>
    )
}

export default Loader;