'use client';
// Components
import Text from '@/common/Text/index.server';
import Icon from '@/common/Icon/index.client';
import Slider from 'react-slick';
import Image from 'next/image';
import LoaderBlur from '@/common/LoaderBlur/index.client';

// Types
import { ISliderDataItem } from '../types';

// Styles
import styles from './index.module.sass';
import './slider.sass';

interface IProps {
  setCurrentSlide: React.Dispatch<React.SetStateAction<number>>,
  sliderRef: React.RefObject<Slider>,
  preparedData: ISliderDataItem[]
}

const BalancesSlider = (props: IProps) => {
  const {
    setCurrentSlide,
    sliderRef,
    preparedData
  } = props;

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    swipe: false,
    beforeChange: (_: number, newIndex: number) => setCurrentSlide(newIndex),
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return !preparedData.length ? (
    <LoaderBlur fetch={true} />
  ) : (
    <>
      <div className={`${styles['balances-slider']} width--100`}>
        <Slider
          {...settings}
          ref={sliderRef}
          className="balances-slider"
        >
          {preparedData.map((item) => {
            const { 
              name = '', 
              translation = '', 
              value = '', 
              icon = '', 
              bgIcon = '', 
              image = '', 
              size = 24 
            } = item;

            return (
              <div className={styles['balances-slider__item']} key={`asset - ${name}`}>
                <div className="relative--core fl fl--dir-col fl--align-c padding--16">
                  <Text
                    size="small"
                    weight="400"
                    transform="uppercase"
                    fontFamily="copperplate"
                    className="margin--b-8"
                  >
                    {translation}
                  </Text>
                  <div className="relative--core z--1 fl fl--align-c">
                    <Text
                      size="x-big"
                      weight="400"
                      transform="uppercase"
                      fontFamily="copperplate"
                      className={styles['balances-slider__item-amount']}
                    >
                      {value}
                    </Text>
                    {
                      icon ? (
                        <Icon
                          icon={icon}
                          size={24}
                          classNames={{
                            icon: `${styles['balances-slider__currency-icon']} ${styles[`balances-slider__currency-icon--${name}`]}`
                          }}
                        />
                      ) : image ? (
                        <Image
                          src={image}
                          width={24}
                          height={24}
                          className={`${styles['balances-slider__currency-icon']} ${styles[`balances-slider__currency-icon--${name}`]}`}
                          alt={translation}
                        />
                      ) : null
                    }
                  </div>
                  {
                    bgIcon ? (
                      Array(4).fill(null)?.map((_: any, idx: number) => (
                        <Icon
                          key={`${name}-bg-icon-${idx}`}
                          icon={bgIcon}
                          size={size}
                          classNames={{
                            icon: `${styles['balances-slider__bg-currency-icon']} ${styles[`balances-slider__bg-currency-icon--${name}-${idx}`]}`
                          }}
                        />
                      ))
                    ) : image ? (
                      Array(4).fill(null)?.map((_: any, idx: number) => (
                        <Image
                          key={`${name}-bg-image-${idx}`}
                          src={image}
                          width={size}
                          height={size}
                          className={`${styles['balances-slider__bg-currency-icon']} ${styles[`balances-slider__bg-currency-icon--${name}-${idx}`]}`}
                          alt={translation}
                        />
                      ))
                    ) : null
                  }
                </div>
              </div>
            )
          })}
        </Slider>
      </div>

    </>
  );
}

export default BalancesSlider;
