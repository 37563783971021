'use client'

import React, { lazy, useEffect, useState } from 'react'
import { usePathname, useSearchParams, useRouter } from 'next/navigation';

// Providers
import ReactQueryProvider from '@/providers/ReactQuery/index.client';

// Components
import ClientModal from '@/common/Modal/Client/index.client';

// Types
import { ITextContent } from '@/types/page.types';

interface IProps {
    translations: ITextContent;
    lang: string;
    isModalOpen?: boolean;
    closeModalHandle?: () => void;
    onSuccessHandler?: () => void;
    isLoading?: boolean;
}

const OauthModal = (props: IProps) => {
    const {
        translations: {
            metaData: {
                json: translations = {}
            } = {}
        } = {},
        lang = '',
        isModalOpen,
        closeModalHandle,
        onSuccessHandler,
        isLoading
    } = props;
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const { replace } = useRouter()
    const [Component, setComponent] = useState<any>(null);
    const oauth = searchParams.get('oauth') as string;
    const form = searchParams.get('form') as string;
    const noRedirect = searchParams.get('no-redirect') as string || isModalOpen;
    const kyc = searchParams.get('kyc') as string;
    const formsMapping = {
        login: 'Login',
        signup: 'SignUp',
        'forgot-password': 'ForgotPassword',
        'restore-password-by-old': 'ResetPasswordByOld'
    } as { [key: string]: string };

    const handleCloseModal = () => {
        const params = new URLSearchParams(searchParams.toString());
        params.delete('oauth');
        params.delete('form');
        params.delete('no-redirect');
        params.delete('kyc');
        return replace(`${pathname}?${params.toString()}`, { scroll: false });
    }

    const [formState, setFormState] = useState(isModalOpen ? 'login' : null);

    const getComponent = async () => {
        try {
            const component = lazy(() => import(`./components/${formsMapping[formState || form] || formsMapping.login}/index.client`)) as any;
            setComponent(component);
        } catch (e) {
            console.warn(e);
        }
    };

    useEffect(() => {
        getComponent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState, form]);

    return (
        <ReactQueryProvider>
            {
                Component ? (
                    <ClientModal isOpened={isModalOpen || !!oauth} setIsOpened={closeModalHandle || handleCloseModal} {...(isLoading && { isLoading })}>
                        <>
                            {
                                Component ?
                                    <Component
                                        translations={translations}
                                        lang={lang}
                                        noRedirect={noRedirect}
                                        kyc={kyc}
                                        handleCloseModal={onSuccessHandler || handleCloseModal}
                                        isStateControled={isModalOpen}
                                        setFormState={setFormState}
                                    />
                                    : null
                            }
                        </>
                    </ClientModal>
                ) : null
            }
        </ReactQueryProvider>
    )
}

export default OauthModal