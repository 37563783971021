// Types
import {
  IDashboardStatisticResponse,
  IUndergroundGraphResponse,
  IUndergroundStatisticResponse
} from "@/types/token.types";

const getUndergroundStatistics = async (): Promise<IUndergroundStatisticResponse | null> => {
  try {
    const response = await fetch(
      '/api/statistics/underground',
      {
        next: { revalidate: 1 },
      }
    );

    return response.json() || null;
  } catch (e) {
    return null;
  }
};

const getDashboardStatistics = async (): Promise<IDashboardStatisticResponse | null> => {
  try {
    const response = await fetch(
      '/api/statistics/dashboard',
      {
        next: { revalidate: 1 },
      }
    );

    return response.json() || null;
  } catch (e) {
    return null;
  }
}

const getUndergroundGraph = async (opts: {
  graph: 'membersCount' | 'padaVolume';
  interval: 'day' | 'month';
}): Promise<IUndergroundGraphResponse | null> => {
  try {
    const { graph, interval } = opts;
    const searchParams = new URLSearchParams({
      graph,
      interval
    });

    const response = await fetch(
      `/api/statistics/underground/graph?${searchParams.toString()}`,
      {
        next: { revalidate: 1 },
      }
    );

    return response.json() || null;
  } catch (e) {
    return null;
  }
};

export {
  getUndergroundStatistics,
  getUndergroundGraph,
  getDashboardStatistics
};
