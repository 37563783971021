const INVESTORS = {
    INVESTORS_SCENE: 'investors-scene',
    INVESTORS_IMAGINE_A_GAME: 'investors-imagine-a-game',
    INVESTORS_WHAT_IS_POLIADA: 'investors-what-is-poliada',
    INVESTORS_WHY_IS_POLIADA: 'investors-why-is-poliada',
    INVESTORS_PROCESS: 'investors-process',
    INVESTORS_TOKENOMICS: 'investors-tokenomics',
    INVESTORS_ECONOMY: 'investors-economy',
    INVESTORS_INVESTMENTS: 'investors-investments',
    INVESTORS_CTA: 'investors-cta',
    INVESTORS_FAQ: 'investors-faq'
}

const ACCOUNT = {
    ACCOUNT_DASHBOARD: 'account-dashboard',
    CONNECT_WALLET_MODAL: 'connect-wallet-modal',
    KYC_MODAL: 'kyc-modal',
    INVEST_MODAL: 'invest-modal',
    EXIST_INVESTMENT_MODAL: 'exist-investment-modal'
}

const DASHBOARD = {
    DASHBOARD_SCENE: 'dashboard-scene',
    DASHBOARD_CHARTS: 'dashboard-charts'
}

const ABOUT_GAME = {
    ABOUT_GAME_SCENE: 'about-game-scene',
    ABOUT_GAME_HEROES: 'about-game-heroes',
    ABOUT_GAME_WIN_BATTLES: 'about-game-win-battles',
    ABOUT_GAME_TACTICAL_BONUSES: 'about-game-tactical-bonuses',
    ABOUT_GAME_PARTICIPATE: 'about-game-participate',
    ABOUT_GAME_LOCATIONS: 'about-game-locations',
    ABOUT_GAME_CHEST: 'about-game-chest',
    ABOUT_GAME_RESOURCES_AND_CHARACTERISTICS: 'about-game-resources-and-characteristics',
    ABOUT_GAME_TRADE: 'about-game-trade',
    ABOUT_GAME_RESOURCE_EXCHANGE: 'about-game-resource-exchange',
    ABOUT_GAME_RESOURCE_CONVERTING: 'about-game-resource-converting',
    ABOUT_GAME_SHOP: 'about-game-shop'
}

const TEXTS = {
    CHANGE_USERNAME: 'change-username',
    I18N: 'i18n',
    FOOTER: 'footer',
    HOME_SCENE: 'home-scene',
    HOME_NEWS: 'home-news',
    HOME_STEPS: 'home-steps',
    HOME_MARKETS: 'home-markets',
    HOME_INDUSTRIES: 'home-industries',
    CONTACTS_FORM: 'contacts-form',
    CONTACTS_FAQ: 'contacts-faq',
    CONTACT_US: 'contact-us',
    NOTIFICATIONS: 'notifications',
    ADVANTAGES: 'advantages',
    ECONOMY_SCENE: 'economy-scene',
    ...INVESTORS,
    ...DASHBOARD,
    ...ACCOUNT,
    TESTNET_INFO: 'testnet-info',
    SITE_TESTNET_INFO: 'site-testnet-info',
    PRESALE_MODAL: 'presale-modal',
    ...ABOUT_GAME
};

const NOTIFICATIONS_KEYS = {
    SIGNUP__VERIFY_EMAIL: 'signup__verify-email',
    SIGNUP__REGISTER: 'signup__register',
    LOGIN: 'login',
    CHANGE_USERNAME: 'change-username',
    ADD_PHONE_NUMBER: 'add-phone-number',
    RESET_PASSWORD_BY_OLD: 'reset-password-by-old',
    FORGOT_PASSWORD__SEND_EMAIL: 'forgot-password__send-email',
    FORGOT_PASSWORD_RESET: 'forgot-password__reset' 
}

export default TEXTS;

export {
    NOTIFICATIONS_KEYS
};
