import { useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { useOauthStore } from '@/store/zustand/oauth.store';
import { useUserStore } from '@/store/zustand/user.store';

// Actions
import logout from '@/actions/oauth/logout';

const useLogout = () => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const router = useRouter();
  const { set } = useOauthStore();
  const { set: setUserDetails } = useUserStore();
  const pathname = usePathname();

  const logoutHandler = async () => {
    setIsLoggingOut(true);
    try {
      await logout();
      if (pathname.includes('account')) {
        router.push('/');
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoggingOut(false);
      set({ isAuthorized: false });
      setUserDetails({ externalId: undefined, meta: undefined })
    }
  };

  return {
    isLoggingOut,
    logoutHandler
  };
};

export default useLogout;
