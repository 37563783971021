'use client';

// Components
import Text from '@/common/Text/index.server';
import ClientModal from '@/common/Modal/Client/index.client';
import Button from '@/common/Button/index.client';
import InputComponent from '@/components/Form/Input/index.client';
import PinWindow from '@/components/PinModal/PinWindow/index.client';
import TransactionCreatedModalWindow from '@/components/TransactionCreatedModal/TransactionCreatedModalWindow/index.client';
import HorizontalRule from '@/common/HorizontalRule/index.server';
import InfoPlate from '../InfoPlate/index.client';
import Icon from '@/common/Icon/index.client';

// Types
import { ITextContent } from '@/types/page.types';

// Decorators
import withAuthAndWalletChecks from '@/decorators/withAuthAndWalletChecks.dec';

// Styles
import styles from './index.module.sass';

interface IProps {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  translations: ITextContent;
  stakeAmount: string;
  setStakeAmount: React.Dispatch<React.SetStateAction<string>>;
  isTokenExecuting: boolean;
  handleStaking: () => Promise<any>;
  pinModalOpen: boolean;
  execStaking: (privateKey?: string) => Promise<void>;
  hashString: string;
  setHashString: React.Dispatch<React.SetStateAction<string>>;
  lang: string;
  connectWalletModalText: ITextContent;
  fee?: number;
}

const StakingModal = (props: IProps) => {
  const {
    modalOpen,
    setModalOpen,
    stakeAmount,
    setStakeAmount,
    isTokenExecuting,
    handleStaking,
    translations,
    translations: {
      metaData: {
        json: {
          stakeYourTokensNow = '',
          amount = '',
          stakeNow = '',
          fee: feeText = ''
        } = {}
      } = {}
    } = {},
    pinModalOpen = false,
    execStaking,
    hashString = '',
    setHashString,
    lang,
    fee
  } = props;

  const handleCloseModal = () => {
    setHashString('');
    setModalOpen(false);
  }

  return (
    <ClientModal isOpened={modalOpen} {...(!isTokenExecuting && { setIsOpened: handleCloseModal })} loading={isTokenExecuting}>
      {pinModalOpen ? (
        <PinWindow
          onComplete={execStaking}
          translations={translations}
        />
      ) : hashString ? (
        <TransactionCreatedModalWindow
          hash={hashString}
          translation={translations}
          lang={lang}
        />
      ) : (
        <div className={styles['staking-modal']}>
          <Text
            tag="h5"
            size="big"
            weight="400"
            fontFamily="copperplate"
            transform="uppercase"
            className={`${styles['staking-modal__title']} turn--center`}
          >
            {stakeYourTokensNow}
          </Text>
          <HorizontalRule className={styles['staking-modal__hr']} />
          <InputComponent
            name="amount"
            placeholder="1000"
            value={stakeAmount}
            onChange={(_, val) => setStakeAmount(val)}
            label={amount}
            type="number"
            currency={{
              value: 'Pada',
              icon: 'colorful/pada'
            }}
            classNames={{
              wrapper: "margin--b-24"
            }}
          />
          <InfoPlate translations={translations} />
          <div className={`${styles['staking-modal__last-row']} fl fl--align-c fl--justify-b fl--gap-24 margin--t-24`}>
            <Text
              size="x-small"
              fontFamily="ubuntu"
              color="extra-gray"
            >
              {feeText}
            </Text>
            <div className="fl fl--align-c fl--gap-8">
              <Text
                size="small"
                fontFamily="copperplate"
                weight="400"
                color="extra-gray"
              >
                {fee?.toFixed(3) || 0}
              </Text>
              <Icon
                icon="colorful/pada"
                size={16}
              />
            </div>
          </div>
          <Button
            onClick={handleStaking}
            disabled={!+stakeAmount || isTokenExecuting}
            className={`${styles['staking-modal__button']} width--100`}
          >
            {stakeNow}
          </Button>
        </div>
      )}
    </ClientModal>
  );
};

export default withAuthAndWalletChecks(StakingModal);
