import { BALANCE_POLLING_INTERVAL } from '@/constants/token.const';
import { syncBalances } from '@/helpers/token.hl';
import { useAssetsStore } from '@/store/zustand/assets.store';
import { useUserStore } from '@/store/zustand/user.store';
import React, { ComponentType, useEffect, useState } from 'react';

const withCryptoBalances = <P extends object>(PageComponent: ComponentType<P>) => {
  return function WrappedComponent(props: any) {
    let interval: NodeJS.Timer | null;
    const {
      user: {
        externalId,
      }
    } = useUserStore();

    const {
      set,
      setBlockchainLoaded
    } = useAssetsStore();

    useEffect(() => {
      if (interval) {
        clearInterval(interval);
      }

      interval = setInterval(async () => {
        if (await syncBalances({
          externalId,
          set
        })) {
          setBlockchainLoaded(true);
        }
      },
       10000);
      return () => {
        if (interval) {
          clearInterval(interval);
        }
      }
    }, [externalId]);

    return (
      <PageComponent {...props} />
    );
  };
};

export default withCryptoBalances;