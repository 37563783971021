'use client';
import Link from 'next/link';

// Types
import { IMenu, IMenuSingleLanguage } from '@/types/menu.types';

// Helpers
import getFullPath from '@/helpers/getFullPath.hl';

// Components
import FadeInAnimation from '@/components/Animations/FadeIn/index.client';
import Icon from '@/common/Icon/index.client';

// Styles
import menuStyles from './index.module.sass';

interface MenuTypes {
    menu: IMenuSingleLanguage[],
    lang: string,
    mobile?: boolean
}

const Menu = (props: MenuTypes) => {
    const {
        menu,
        lang,
        mobile
    } = props;

    const handleRouting = () => {
        document.body.removeAttribute('style');
    }

    return (
        <ul className={`${menuStyles['menu']} ${mobile ? menuStyles['menu--mobile'] : ''} fl fl--justify-b`}>
            {
                menu?.map((menuItem: IMenuSingleLanguage, i: number) => {
                    const { label: menuLabel, uri, name, children } = menuItem;
                    const fullPath = getFullPath(uri, lang)
                    const delay = 0 + i * 0.2;
                    if (children) {
                        return (
                            <li key={menuLabel} className={`${menuStyles.menu__item} font--300 relative--core cursor--custom-pointer`}>
                                <FadeInAnimation delay={delay}>
                                    <div className={`${menuStyles['menu__item-link']} text--default fl fl-align-c cursor--custom-pointer`}>
                                        {name}
                                        <Icon
                                            size={18}
                                            icon="flat/menu-down-arrow"
                                            classNames={{ wrapper: 'margin--l-8' }}
                                            color="white"
                                        />
                                    </div>
                                    <ul className={`${menuStyles.menu__submenu} absolute--core cursor--custom-pointer`}>
                                        {
                                            children?.map((el: IMenu) => {
                                                const { label, uri, name } = el;
                                                const fullPath = getFullPath(uri, lang)
                                                return (
                                                    <li key={label} className={`${menuStyles['menu__submenu-item']} font--300 text--small cursor--custom-pointer`}>
                                                        <Link className={`${menuStyles['menu__submenu-item-link']} link--secondary cursor--custom-pointer`} href={fullPath}>{name?.[lang as string]}</Link>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </FadeInAnimation>
                            </li>
                        )
                    }
                    return (
                        <li className={menuStyles.menu__item} key={menuLabel}>
                            <FadeInAnimation delay={delay}>
                                <Link onClick={handleRouting} scroll={true} href={fullPath} className="link--secondary text--default font--300 cursor--custom-pointer">{name}</Link>
                            </FadeInAnimation>
                        </li>
                    )
                })
            }
        </ul>
    )
}

export default Menu;