"use client";
import React from 'react';
import Text from '@/common/Text/index.server';

import styles from './index.module.sass';
import { ITextContent } from '@/types/page.types';
import Accordion from '@/components/Accordion/index.server';

interface IProps {
    translations: ITextContent;
    advantages: ITextContent;
}

const Advantages = ({ translations, advantages }: IProps) => {
    const {
        title = '',
        metaData: {
            json: { items = [] } = {},
        } = {},
    } = advantages;

    const {
        metaData: {
            json: {
                advantages: advantagesTranslation = 'Advantages',
                forHolders = 'for holders'
            } = {}
        } = {}
    } = translations

    return (
        <div className={`${styles.advantages} indent--footer`}>
            <div className={`${styles.advantages__wrapper} margin--center`}>
                <div className={`${styles.advantages__heading} margin--center`}>
                    <div className='fl fl--dir-col fl--align-st fl--justify-st'>
                        <Text tag="h2" color="light" size="y-huge" weight="400">
                            {advantagesTranslation}
                        </Text>
                        <Text tag="h2" color="light" size="y-huge" weight="400">
                            {forHolders}
                        </Text>
                    </div>
                </div>
                <Accordion className="fl fl--dir-col fl--gap-24" items={items} />
            </div>
        </div>
    );
};

export default Advantages;
