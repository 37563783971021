'use client';

// Components
import Text from '@/common/Text/index.server';
import Button from '@/common/Button/index.client';

// Types
import { ITextContent } from '@/types/page.types';

// Styles
import styles from '../index.module.sass';

interface IProps {
  text: ITextContent;
  translation: ITextContent;
  connectWallet: () => Promise<void>;
}

const RestoreWalletMetamaskContent = (props: IProps) => {
  const {
    text: {
      metaData: {
        json: {
          restoreWalletMetamaskTitle = '',
          restoreWalletMetamaskSubtitle = ''
        } = {}
      } = {}
    } = {},
    translation: {
      metaData: {
        json: {
          restore = ''
        } = {}
      } = {}
    } = {},
    connectWallet
  } = props;

  return (
    <>
      <Text
        tag="h5"
        size="big"
        transform="uppercase"
        weight="400"
        fontFamily="copperplate"
        className={styles['connect-wallet-modal__subtitled-title']}
      >
        {restoreWalletMetamaskTitle}
      </Text>
      <Text
        size="minimum"
        fontFamily="ubuntu"
        color="extra-gray"
        className={styles['connect-wallet-modal__subtitle']}
      >
        {restoreWalletMetamaskSubtitle}
      </Text>
      <Button
        onClick={connectWallet}
        className={styles['connect-wallet-modal__button']}
      >
        {restore}
      </Button>
    </>
  );
};

export default RestoreWalletMetamaskContent;
