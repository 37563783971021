import Menu from './Menu/index.client';
import SidebarMenu from './SidebarMenu/index.client';
import SideBar from './SideBar/index.client';
import About from './About/index.client';
import Underground from './Underground/index.client';
import InvestorsProcess from './Investors/Process/index.client';
import InvestorsTokenomics from './Investors/Tokenomics/index.client';
import ConnectWalletModal from './ConnectWalletModal/index.client';
import TestnetPlug from './TestnetPlug/index.client';
import ClientBalances from './ClientBalances/index.client';
import LottieAnimation from './LottieAnimation/index.client';

export {
    Menu,
    SideBar,
    About,
    SidebarMenu,
    Underground,
    InvestorsProcess,
    InvestorsTokenomics,
    ConnectWalletModal,
    TestnetPlug,
    ClientBalances,
    LottieAnimation
}
