'use client';

// Types
import { ITextContent } from '@/types/page.types';

// Components
import Advantages from './components/Advantages/index.client';
import Scene from './components/Scene/index.client';

// Providers
import ReactQueryProvider from '@/providers/ReactQuery/index.client';

interface IProps {
  translations: ITextContent;
  advantages: ITextContent;
  lang: string;
  connectWalletModalText: ITextContent;
}

const Underground = (props: IProps) => {
  const {
    advantages,
    translations,
    lang,
    connectWalletModalText
  } = props;

  return (
    <ReactQueryProvider>
      <Scene
        translations={translations}
        lang={lang}
        connectWalletModalText={connectWalletModalText}
      />
      <Advantages translations={translations} advantages={advantages} />
    </ReactQueryProvider>
  );
};

export default Underground;
