import { ArgsProps } from 'antd/lib/notification/interface';
import { notification } from 'antd';
import Icon from '../Icon/index.client';

import './index.sass';

const createNotification = (type: 'success' | 'error' | 'warning') => (props: ArgsProps) => {
  notification[type]({
    icon: <Icon size={24} icon={`colorful/notification-${type}`} />,
    closeIcon: <Icon size={14} icon="flat/close" />,
    ...props
  });
};

const Notification = {
  success: createNotification('success'),
  error: createNotification('error'),
  warning: createNotification('warning'),
};

export {
  Notification
};
