'use client';
import { useState } from 'react';

// Components
import Text from '@/common/Text/index.server';
import FadeIn from '@/components/Animations/FadeIn/index.client';
import ArcComponent from '@/components/Arc/index.client';
import Image from 'next/image';

// Types
import { ITextContent } from '@/types/text.types';

// Styles
import styles from './index.module.sass';
import Icon from '@/common/Icon/index.client';

interface IProps {
  text: ITextContent;
  translations: ITextContent;
}

const InvestorsTokenomics = (props: IProps) => {
  const {
    text: {
      title = '',
      text = '',
      metaData: {
        json: {
          ico = '',
          totalTokens: totalTokensText = '',
          pricePerToken: pricePerTokenText = '',
          presaleDates: presaleDatesText = '',
          presaleDateStart = '',
          presaleDateEnd = ''
        } = {}
      } = {}
    } = {},
    translations: {
      metaData: {
        json: {
          quantity = '',
          price = ''
        } = {}
      } = {}
    } = {}
  } = props;

  const [percentageLeft, setPercentageLeft] = useState(10);
  const [percentageRight, setPercentageRight] = useState(10);

  const chars = Object.assign([], ico);

  const totalTockens = "70 000 000";
  const pricePerToken = "0.03"
  const numberOfTockens = "1 000 000 000"

  return (
    <section className={`${styles.tokenomics} indent--investors-section`}>
      <div className="container fl fl--dir-col fl--align-c">
        <FadeIn>
          <Text tag="h5" size="y-huge" weight="400" className={`${styles.tokenomics__title} font--family-copperplate`}>
            {title}
          </Text>
        </FadeIn>
        <FadeIn className={styles.tokenomics__content}>
          <div className={`${styles['tokenomics__stats-mobile']} relative--core`}>
            <Text size="x-const" weight="400" fontFamily="copperplate">
              {numberOfTockens} <span className="text--small color--extra-gray">/ {totalTockens}</span>
            </Text>
            <Text size="x-const" weight="400" fontFamily="copperplate" className="fl fl--align-c fl--gap-12">
              {pricePerToken} USDT = 1
              <Icon icon="flat/pada-gray" size={32} />
            </Text>
            <Text size="small" weight="400" fontFamily="copperplate" className={`${styles['tokenomics__adaptive-statistic-percent-text']} ${styles['tokenomics__adaptive-statistic-percent-text--top']} link--secondary link--backlight`}>
              {percentageLeft >= 0 ? '+' : '-'} {Math.abs(percentageLeft)}%
              <Image
                src="/img/arrow-adaptive-top.svg"
                alt="arrow"
                width={32}
                height={32}
                className={`${styles['tokenomics__adaptive-icon']} ${percentageLeft < 0 ? styles['tokenomics__adaptive-icon--down'] : styles['tokenomics__adaptive-icon--up']}`}
              />
            </Text>
            <Text size="small" weight="400" fontFamily="copperplate" className={`${styles['tokenomics__adaptive-statistic-percent-text']} ${styles['tokenomics__adaptive-statistic-percent-text--bottom']} link--secondary link--backlight`}>
              {percentageRight >= 0 ? '+' : '-'} {Math.abs(percentageRight)}%
              <Image
                src="/img/arrow-adaptive-top.svg"
                alt="arrow"
                width={32}
                height={32}
                className={`${styles['tokenomics__adaptive-icon']} ${percentageRight < 0 ? styles['tokenomics__adaptive-icon--down'] : styles['tokenomics__adaptive-icon--up']}`}
              />
            </Text>
          </div>
          <p className={`${styles.tokenomics__chars} font--uppercase font--400 color--light font--family-copperplate`}>
            {
              chars?.map((item: string) => {
                return (
                  <FadeIn key={item} delay={0.2}>
                    <div className={styles['tokenomics__chars-item']}>
                      {item}
                    </div>
                  </FadeIn>
                )
              })
            }
          </p>
          <div className={styles.tokenomics__circle}>
            <div className={styles['tokenomics__circle-wrapper']}>
              <div className={styles['tokenomics__circle-content-wrapper']}>
                <div className={`${styles['tokenomics__circle-text-wrapper']} turn--center`}>
                  <Text size="x-default-subtitle-laptop-l" className={styles['tokenomics__circle-title']} fontFamily="copperplate" transform="uppercase" weight="400">
                    {totalTokensText}
                  </Text>
                  <Text size="huge" fontFamily="copperplate" transform="uppercase" weight="400">
                    {totalTockens}
                  </Text>
                </div>
                <div className={`${styles['tokenomics__circle-text-wrapper']} turn--center`}>
                  <Text size="x-default-subtitle-laptop-l" className={styles['tokenomics__circle-title']} fontFamily="copperplate" transform="uppercase" weight="400">
                    {pricePerTokenText}
                  </Text>
                  <Text size="huge" fontFamily="copperplate" transform="uppercase" weight="400">
                    {pricePerToken} USDT
                  </Text>
                </div>
              </div>
              <div className={`${styles['tokenomics__statistic-percent']} ${styles['tokenomics__statistic-percent--left']} ${percentageLeft <= 0 ? styles['tokenomics__statistic-percent--down'] : styles['tokenomics__statistic-percent--up']} relative--core fl fl--align-c fl--gap-12 fl--justify-c`}>
                <Text size="small" weight="400" fontFamily="copperplate" className={`${styles['tokenomics__statistic-percent-text']} ${styles['tokenomics__statistic-percent-text--left']} link--secondary link--backlight`}>
                  {percentageLeft >= 0 ? '+' : '-'} {Math.abs(percentageLeft)}%
                </Text>
                <Text size="minimum" fontFamily="ubuntu" color="extra-gray" className={`${styles['tokenomics__statistic-percent-text-label']} ${styles['tokenomics__statistic-percent-text-label--left']}`}>
                  {quantity}
                </Text>
                <Image
                  src={`/img/${percentageLeft < 0 ? 'arrow-top-right-side' : 'arrow-top-left-side'}.svg`}
                  width={50}
                  height={124}
                  alt="arrow"
                  className={`${styles['tokenomics__icon']} ${styles['tokenomics__icon--left']} ${percentageLeft <= 0 ? styles['tokenomics__icon--down-left'] : styles['tokenomics__icon--up']}`}
                />
              </div>
              <ArcComponent
                className={styles['tokenomics__circle-line']}
                percentageLeft={percentageLeft}
                setPercentageLeft={setPercentageLeft}
              />
              <div className={`${styles['tokenomics__statistic-percent']} ${styles['tokenomics__statistic-percent--right']} ${percentageLeft <= 0 ? styles['tokenomics__statistic-percent--down'] : styles['tokenomics__statistic-percent--up']} relative--core fl fl--align-c fl--gap-12 fl--justify-c`}>
                <Text size="small" weight="400" fontFamily="copperplate" className={`${styles['tokenomics__statistic-percent-text']} ${styles['tokenomics__statistic-percent-text--right']} link--secondary link--backlight`}>
                  {percentageRight >= 0 ? '+' : '-'} {Math.abs(percentageRight)}%
                </Text>
                <Text size="minimum" fontFamily="ubuntu" color="extra-gray" className={`${styles['tokenomics__statistic-percent-text-label']} ${styles['tokenomics__statistic-percent-text-label--right']}`}>
                  {price}
                </Text>
                <Image
                  src={`/img/${percentageRight < 0 ? 'arrow-top-left-side' : 'arrow-top-right-side'}.svg`}
                  width={50}
                  height={124}
                  alt="arrow"
                  className={`${styles['tokenomics__icon']} ${styles['tokenomics__icon--right']} ${percentageRight <= 0 ? styles['tokenomics__icon--down-right'] : styles['tokenomics__icon--up']}`}
                />
              </div>
            </div>
          </div>
          <Text size="small" weight="400" transform="uppercase" className={`${styles.tokenomics__date} font--family-copperplate`}>
            {presaleDatesText}
            <div className={styles['tokenomics__date-values']}>
              {presaleDateStart} - {presaleDateEnd}
            </div>
          </Text>
        </FadeIn>
      </div>
    </section>
  );
};

export default InvestorsTokenomics;
