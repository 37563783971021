import axios from "axios";

const updateUser = async (data: any): Promise<any> => {
    try {
        await axios('/api/user',
            {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json'
                },
                data: JSON.stringify(data)
            }
        );

        return true;
    } catch (e) {
        throw e;
    }
}


export default updateUser;
