/* eslint-disable react-hooks/exhaustive-deps */
'use client'

import AssetsStoreInitializer from "./assets/index.client";
import OauthStoreInitializer from "./oauth/index.client";
import UserStoreInitializer from "./user/index.client";
import WalletStoreInitializer from "./wallet/index.client";

const ClientSideStoreInitializer = (props: any) => {
  const {
    oauth,
    assets,
    user
  } = props;

  return (
    <>
      <OauthStoreInitializer data={oauth} />
      <UserStoreInitializer data={user} />
      <AssetsStoreInitializer data={assets} />
      <WalletStoreInitializer />
    </>
  );
}

export default ClientSideStoreInitializer;
