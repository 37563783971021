'use client';
import { useState } from 'react';

// Components
import Text from '@/common/Text/index.server';
import InputComponent from '@/components/Form/Input/index.client';
import Button from '@/common/Button/index.client';
import { Notification } from '@/common/Notification';

// Types
import { ITextContent } from '@/types/page.types';

// Styles
import styles from '../index.module.sass';

interface IProps {
  translation: ITextContent;
  mnemonicWords: string[];
  connectWalletCheckMnemonicPhrase: () => Promise<void>;
}

const ConnectWalletStep2Content = (props: IProps) => {
  const {
    translation: {
      metaData: {
        json: {
          step = '',
          generateMnemonicPhrase = '',
          generateMnemonicPhraseSubtitle = '',
          copyPhrase = '',
          phraseCopied = '',
          continue: continueText = ''
        } = {}
      } = {}
    } = {},
    mnemonicWords,
    connectWalletCheckMnemonicPhrase
  } = props;

  const [copied, setCopied] = useState<boolean>(false);

  const copyPhraseHandler = () => {
    try {
      navigator.clipboard.writeText(mnemonicWords.join(' '))
      setCopied(true);
      setTimeout(() => setCopied(false), 3000)
    } catch (e) {
      Notification.error({
        message: 'Error',
        description: 'something went wrong'
      })
    }
  }

  return (
    <>
      <Text
        tag="h6"
        size="y-medium"
        transform="uppercase"
        weight="400"
        fontFamily="copperplate"
        color="middle-gray"
        className={`${styles['connect-wallet-modal__step']} turn--center`}
      >
        {step} 2
      </Text>
      <Text
        tag="h5"
        size="big"
        transform="uppercase"
        weight="400"
        fontFamily="copperplate"
        className={`${styles['connect-wallet-modal__subtitled-title']} turn--center`}
      >
        {generateMnemonicPhrase}
      </Text>
      <Text
        size="minimum"
        weight="300"
        fontFamily="ubuntu"
        color="extra-gray"
        className={`${styles['connect-wallet-modal__subtitle']} turn--center`}
      >
        {generateMnemonicPhraseSubtitle}
      </Text>
      <div className={`${styles['connect-wallet-modal__mnemonic-words']} fl fl--gap-16 fl--wrap`}>
        {mnemonicWords?.map((val: string, index) => (
          <div key={index} className={styles['connect-wallet-modal__mnemonic-word']}>
            <InputComponent
              index={index + 1}
              name={`pin-${index}`}
              value={val}
              classNames={{
                field: styles['connect-wallet-modal__mnemonic-word-field']
              }}
            />
          </div>
        ))}
      </div>
      <Button
        type="secondary"
        size="small"
        disabled={copied}
        onClick={copyPhraseHandler}
        className={`width--fit-content margin--center ${copied ? styles['connect-wallet-modal__mnemonic-words-copied-button'] : ''}`}
      >
        <Text
          color={copied ? 'dark-green' : ''}
        >
          {copied ? phraseCopied : copyPhrase}
        </Text>
      </Button>
      <Button
        onClick={connectWalletCheckMnemonicPhrase}
        className={`${styles['connect-wallet-modal__button']} margin--t-24`}
      >
        {continueText}
      </Button>
    </>
  );
};

export default ConnectWalletStep2Content;
