import {
    ENTRYPOINT_ABI,
    ENTRYPOINT_ADDRESS,
    PADA_ABI,
    PADA_CONTRACT_ADDRESS,
    PADA_PRICE_PER_TOKEN, USDT_ABI,
    USDT_CONTRACT_ADDRESS,
    USDT_PAYMASTER_ADDRESS,
    USDT_PRICE_PER_TOKEN
} from "@constants/token.const";
import { Contract, ethers } from "ethers";

const execute = async ({ executeData, smartWallet, signer, provider, paymaster }: any) => {
    const res = await fetch(`/api/smart-wallet/user-op-with-hash?executeData=${executeData}&sender=${await smartWallet.getAddress()}&paymaster=${paymaster}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const {
        userOp,
        maxFeeCost
    } = await res.json();

    const requiredToken = BigInt(maxFeeCost.value) * BigInt((paymaster === USDT_PAYMASTER_ADDRESS ? USDT_PRICE_PER_TOKEN : PADA_PRICE_PER_TOKEN) as string);

    const contract = new Contract(paymaster === USDT_PAYMASTER_ADDRESS ? USDT_CONTRACT_ADDRESS : PADA_CONTRACT_ADDRESS, paymaster === USDT_PAYMASTER_ADDRESS ? USDT_ABI : PADA_ABI, provider);
    const approvePaymasterData = contract.interface.encodeFunctionData("approve", [paymaster, ethers.parseUnits(requiredToken.toString(), 18)]);

    const executeApproveData = await smartWallet.interface.encodeFunctionData('execute', [paymaster === USDT_PAYMASTER_ADDRESS ? USDT_CONTRACT_ADDRESS : PADA_CONTRACT_ADDRESS, 0, approvePaymasterData]);

    const resApprove = await fetch(`/api/smart-wallet/user-op-with-hash?executeData=${executeApproveData}&sender=${await smartWallet.getAddress()}&paymaster=${paymaster}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const {
        userOp: userOpApprove,
    } = await resApprove.json();

    const preparedApproveUserOp = Object.fromEntries(
        Object.entries(userOpApprove)
            .map(([key, value]: any) => {
                if (value && value.__type === 'BigInt') {
                    return [key, BigInt(value.value)];
                } else {
                    return [key, value];
                }
            })
    );

    const entryPoint = new Contract(ENTRYPOINT_ADDRESS, ENTRYPOINT_ABI, provider)
    const approveUserOpHash = await entryPoint.getUserOpHash({
        ...preparedApproveUserOp,
        signature: '0x',
    });

    preparedApproveUserOp.signature = await signer.signMessage(ethers.getBytes(approveUserOpHash));

    const resExecuteApprove = await fetch(`/api/smart-wallet/execute`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userOp: preparedApproveUserOp }, (_, value) => {
            if (typeof value === 'bigint') {
                return { __type: 'BigInt', value: value.toString() };
            } else {
                return value;
            }
        })
    });

    if (!resExecuteApprove.ok) {
        throw new Error('Failed Approve');
    }

    const preparedUserOp = Object.fromEntries(
        Object.entries(userOp)
            .map(([key, value]: any) => {
                if (value && value.__type === 'BigInt') {
                    return [key, BigInt(value.value)];
                } else {
                    return [key, value];
                }
            })
    );

    preparedUserOp.nonce += BigInt(1)

    const userOpHash = await entryPoint.getUserOpHash({
        ...preparedUserOp,
        signature: '0x',
    });


    preparedUserOp.signature = await signer.signMessage(ethers.getBytes(userOpHash));

    const resExecute = await fetch(`/api/smart-wallet/execute`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userOp: preparedUserOp }, (_, value) => {
            if (typeof value === 'bigint') {
                return { __type: 'BigInt', value: value.toString() };
            } else {
                return value;
            }
        })
    });

    if (!resExecute.ok) {
        throw new Error('Failed');
    }

    const { hash } = await resExecute.json();

    return hash;
}

export default execute;
