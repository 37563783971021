'use client';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import withGoogleRecaptcha from '@/decorators/withGoogleRecaptcha';

// Components
import Button from '@/common/Button/index.client';
import InputComponent from '../../../Form/Input/index.client';
import { Notification } from '@/common/Notification';

// Helpers
import { errorsStringify, getErrorStatus } from '@/helpers/error.hl';

// Hooks
import useTexts from '@/hooks/useTexts';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

// Constants
import TEXTS, { NOTIFICATIONS_KEYS } from '@/constants/texts.const';

// Types
import { ITextContent } from '@/types/page.types';

// Styles
import styles from './index.module.sass';

interface IProps {
  translations: ITextContent;
}

const SubscribeForm = (props: IProps) => {
  const {
    translations: {
      metaData: {
        json: {
          emailIsNotCorrect = '',
          fieldIsRequired = '',
          email: emailPlaceholder = '',
          subscribe = '',
          investorsSubsribeNotificationEmail: {
            title = '',
            description = ''
          } = {}
        } = {}
      } = {}
    } = {}
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [notifications] = useTexts([TEXTS.NOTIFICATIONS]) || [];

  const schema = Yup.object().shape({
    email: Yup.string().email(emailIsNotCorrect).required(fieldIsRequired)
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handlerSubmit = async () => {
    if (!executeRecaptcha) return;
    try {
      setIsLoading(true);
      const gReCaptchaToken = await executeRecaptcha("investorsSubscribe");
      await axios({
        url: `${location?.origin}/api/mails/subscribe/investors`,
        method: 'POST',
        data: {
          gReCaptchaToken,
          email
        }
      });
      Notification.success({
        message: title,
        description,
        duration: 10
      });
    } catch (error: any) {
      const status = getErrorStatus(error);
        const {
          metaData: {
            json: {
              error: {
                [NOTIFICATIONS_KEYS.SIGNUP__VERIFY_EMAIL]: {
                  [status]: {
                    title = '',
                    description = ''
                  } = {}
                } = {}
              } = {}
            } = {}
          } = {}
        } = notifications;
        const message = errorsStringify(error);
        Notification.error({
          message: title || message,
          description
        });
    } finally {
      setIsLoading(false);
    }
  };

  const {
    values: {
      email = ''
    },
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    isSubmitting
  } = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: schema,
    onSubmit: handlerSubmit
  });

  return (
    <form onSubmit={handleSubmit} className={`${styles['subscribe-form']} relative--core margin--t-32`}>
      <InputComponent
        name="email"
        value={email}
        disabled={isSubmitting}
        onChange={setFieldValue as any}
        placeholder={emailPlaceholder?.[0]?.toUpperCase() + emailPlaceholder?.slice(1)}
        meta={{ error: errors.email, touched: touched.email }}
        classNames={{
          wrapper: styles['subscribe-form__input-wrapper'],
          field: styles['subscribe-form__input-field']
        }}
      />
      <Button
        htmlType="submit"
        disabled={!email || !!errors.email || isSubmitting || isLoading}
        className={styles['subscribe-form__button']}
      >
        {subscribe}
      </Button>
    </form>
  );
};

export default withGoogleRecaptcha(SubscribeForm);