"use client";
import React, { useState, useRef } from 'react';

// Components
import Text from '@/common/Text/index.server';
import Icon from '@/common/Icon/index.client';

// Styles
import styles from '../index.module.sass';

interface IAccordionItemProps {
    id: number;
    title: string;
    firstSubtitle: string;
    secondSubtitle?: string;
}

const AccordionItem = ({ id, title, firstSubtitle, secondSubtitle }: IAccordionItemProps) => {
    const [height, setHeight] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    const toggleAccordion = () => {
        const contentHeight = contentRef.current?.clientHeight || 0;
        setHeight(isActive ? 0 : contentHeight);
        setIsActive(!isActive);
    };

    return (
        <li
            onClick={toggleAccordion}
            className={`${styles.accordion__card} ${isActive ? styles['accordion__card--active'] : ''} cursor--custom-pointer`}
        >
            <div className={`${styles.accordion__heading} ${isActive ? 'margin--b-24' : ''}`}>
                <Text size={isActive ? 'x-const' : 'z-medium'} tag="h2" color="light" weight="400" transform="uppercase" className={styles['accordion__element-title']}>
                    {title}
                </Text>
                <Icon size={24} icon={`colorful/${isActive ? 'minus-accordion' : 'plus-accordion'}`} />
            </div>
            <div className={styles['accordion__card-subcontent']} style={{ height: `${height}px` }}>
                <div ref={contentRef}>
                    <p className={`${styles['accordion__card-content']} color--extra-gray font--300 font--family-ubuntu ${isActive ? styles['accordion__card-content--active'] : ''}`}>
                        {firstSubtitle}
                    </p>
                    {secondSubtitle ? (
                        <p className={`${styles['accordion__card-content']} color--extra-gray font--300 font--family-ubuntu ${isActive ? styles['accordion__card-content--active'] : ''} margin--t-10`}>
                            {secondSubtitle}
                        </p>
                    ) : null}
                </div>
            </div>
        </li>
    );
};

export default AccordionItem;
