"use client";
import { LazyMotion, domAnimation, m } from "framer-motion";
import { MouseEventHandler } from "react";

const FadeIn = (props: {
    children: React.ReactNode,
    delay?: number,
    key?: string
    duration?: number,
    className?: string,
    margin?: string,
    reference?: {
        onRef: (el: any) => void
    },
    onClick?: MouseEventHandler<HTMLDivElement> | undefined;
}): React.ReactNode => {
    const {
        children,
        key,
        delay,
        className,
        duration,
        margin,
        reference: {
            onRef = () => { }
        } = {},
        onClick
    } = props;

    return (
        <LazyMotion features={domAnimation}>
            <m.div
                ref={(el: any) => onRef(el)}
                className={className}
                viewport={{ once: true, margin: 0 || margin }}
                initial={{ opacity: 0, y: 30 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: duration || 1, delay }}
                {...(key ? { key } : {})}
                {...(onClick ? { onClick } : {})}
            >
                {
                    children
                }
            </m.div>
        </LazyMotion>
    )
}

export default FadeIn;