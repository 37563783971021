const isJSON = (jsonString?: string) => {
    if (!jsonString) return false;
    try {
        JSON.parse(jsonString);
        return true;
    } catch (e) {
        return false;
    }
}

export {
    isJSON
};
