'use client'
import React, { useState } from 'react';
import Link from 'next/link';

// Components
import Icon from '@/common/Icon/index.client';

// Constants
import { DEFAULT_LOCALE } from '@/configuration/locales';

// styles
import styles from './sidebar-menu.module.sass';

// types
import { IMenu, IMenuSingleLanguage } from '@/types/menu.types';
import getFullPath from '@/helpers/getFullPath.hl';


const SideBarMenu = (props: { sublinkClass?: string, content?: IMenu, main?: IMenuSingleLanguage[], lang: string, onCloseMenu: () => void }): JSX.Element | null => {
    const { main, lang, content: { children } = {} , onCloseMenu} = props;
    const iterate = children || main || null;
    const [heights, setHeights] = useState({} as { [key: string]: number });

    if (!iterate) return null;
    let refs = {} as {[key: string]: any };

    const menuHandler = (label: string) => {
        const height = (refs as any)?.[label]?.clientHeight;

        if (heights[label]) {
            setHeights({
                [label]: 0
            })
        } else {
            setHeights({
                [label]: height
            })
        }
    }

    return (
        <ul className={`${styles['sidebar-menu']} fl fl--dir-col fl--justify-c`}>
            <>
                {
                    iterate?.map((item: any) => {
                        const {
                            uri,
                            label,
                            name,
                            options: {
                                nonDisplayedChildren = false
                            } = {},
                            children
                        } = item;

                        const text = name?.[lang] || name[DEFAULT_LOCALE] || name;

                        if (children?.length && !nonDisplayedChildren) {

                            return (
                                <li
                                    key={label}
                                    className="width--100"
                                >
                                    <div className="relative--core fl fl--dir-col fl--align-c fl--justify-c">
                                        <div
                                            className={`
                                                ${styles['sidebar-menu__link']}
                                                ${styles['sidebar-menu__link--parent']}
                                                ${heights[label] ? `${styles['sidebar-menu__link--active-icon']} link--backlight` : ""}
                                                link--secondary font--no-wrap relative--core font--family-ubuntu cursor--custom-pointer
                                            `}
                                            onClick={() => menuHandler(label)}
                                        >
                                            {text}
                                            <Icon
                                                size={18}
                                                icon="flat/menu-down-arrow"
                                                classNames={{ wrapper: `${styles['sidebar-menu__arrow']} absolute--core`, icon: `${styles['sidebar-menu__arrow']}` }}
                                                color="white"
                                            />
                                        </div>
                                        <div
                                            className={`${styles['sidebar-menu__subcontent']} ${heights[label] ? styles['sidebar-menu__subcontent--active'] : '' }`}
                                            style={{ height: `${heights[label] || 0}px`, ...(heights[label] ? { marginTop: '22px', border: '1px solid #2F1A1A' } : { border: '1px solid transparent' }) }}
                                        >
                                            <div
                                                className={`${styles['sidebar-menu__submenu']} relative--core`}
                                                ref={el => refs[label] = el}
                                            >
                                                <SideBarMenu
                                                    key={label}
                                                    sublinkClass={styles['sidebar-menu__link--sublink']}
                                                    content={item}
                                                    lang={lang}
                                                    onCloseMenu={onCloseMenu}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            )
                        }
                        else {
                            return (
                                <li key={label} className="fl fl--justify-c">
                                    <Link className={`${styles['sidebar-menu__link']} link--secondary fl fl--justify-c relative--core font--no-wrap font--family-ubuntu cursor--custom-pointer`} href={getFullPath(uri, lang)}>
                                        <span onClick={onCloseMenu} className="relative--core">{text}</span>
                                    </Link>
                                </li>
                            )
                        }
                    })
                }
            </>
        </ul>
    )
}

export default SideBarMenu;