"use client"
import React, { useEffect, useState } from 'react'
import { Contract, ethers } from 'ethers';
import { usePathname } from 'next/navigation';

// Components
import Text from '@/common/Text/index.server';
import Icon from '@/common/Icon/index.client';
import ArcComponent from '../../../Arc/index.client';
import Button from '@/common/Button/index.client';
import FadeInAnimation from '@/components/Animations/FadeIn/index.client';
import SlidesAnimation from '@/components/Animations/Slides/index.client';
import CustomSelect from '@/components/Select/index.client';
import Charts from '../Charts/index.client';
import StakingModal from '../StakingModal/index.client';
import UnstakeModal from '../UnstakeModal/index.client';
import { Notification } from '@/common/Notification';

// Types
import { ITextContent } from '@/types/page.types';
import { IUndergroundGraphResponse, IUndergroundStatisticResponse } from '@/types/token.types';
import { GraphData } from '../../types';

// Styles
import styles from './index.module.sass';

// Constants
import {
    PADA_DEFAULT_PRECISION,
    UNDERGROUND_ABI,
    UNDERGROUND_CONTRACT_ADDRESS
} from '@/constants/token.const';
import USER_WALLET_TYPES from '@/constants/userWallets.const';
import { DEFAULT_LOCALE } from '@/configuration/locales';

// Actions
import { getUndergroundGraph, getUndergroundStatistics } from '@/actions/statistics';

// Helpers
import { getKeys } from '@/helpers/indexedDb.hl';
import getProvider from '@/helpers/getProvider.hl';
import { formatNumberValue } from '@/helpers/token.hl';
import {
    execStakingOperation,
    execUnstakingOperation
} from '@/helpers/staking.hl';

// Store
import { useUserStore } from '@/store/zustand/user.store';
import { useUpdateUrlParams } from '@/hooks/useUpdateUrlParams';
import { useWalletStore } from '@/store/zustand/wallet.store';

interface IProps {
    translations: ITextContent;
    lang?: string;
    connectWalletModalText: ITextContent;
}

const SHORT_MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const SHORT_DAY_NAMES = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const Scene = (props: IProps) => {
    const {
        translations,
        translations: {
            metaData: {
                json: {
                    totalAmount = '',
                    newWallets = '',
                    wallets = '',
                    maxTotalSupply = '',
                    stackingNow = '',
                    stakeNow = '',
                    unstakeNow = '',
                    errorTranslation = '',
                    billionSuffix = '',
                    millionSuffix = '',
                    thousandSuffix = '',
                    months = '',
                    days = '',
                    notificationTitles = {},
                    notificationDescriptions = {}
                } = {}
            } = {}
        } = {},
        lang = DEFAULT_LOCALE,
        connectWalletModalText
    } = props;

    const selectData = [
        { value: 'months', label: months },
        { value: 'days', label: days }
    ];

    const {
        user: {
            externalId = '',
            id: userId = ''
        } = {},
        set
    } = useUserStore();

    const {
        type: walletType,
        status: walletStatus
    } = useWalletStore();

    const { updateUrlParams } = useUpdateUrlParams();
    const pathname = usePathname();

    const [percentageLeft, setPercentageLeft] = React.useState(10);
    const [percentageRight, setPercentageRight] = React.useState(10);
    const [selectedSecondRange, setSelectedSecondRange] = useState(selectData[0]);
    const [selectedFirstRange, setSelectedFirstRange] = useState(selectData[0]);
    const [selectedBothRange, setSelectedBothRange] = useState(selectData[0]);
    const [statistic, setStatistic] = useState<{
        statistic: IUndergroundStatisticResponse | null;
        membersCountGraph: IUndergroundGraphResponse | null;
        padaVolumeGraph: IUndergroundGraphResponse | null;
    } | null>(null);
    const [graphData, setGraphData] = useState<{ wallets: GraphData[], amount: GraphData[] }>({ wallets: [], amount: [] });
    const [bothGraphData, setBothGraphData] = useState<{ wallets: GraphData[], amount: GraphData[] }>({ wallets: [], amount: [] });

    const [stakeModalOpen, setStakeModalOpen] = useState<boolean>(false);
    const [stakeAmount, setStakeAmount] = useState<string>('');
    const [unstakeAmount, setUnstakeAmount] = useState<string>('');
    const [unstakeModalOpen, setUnstakeModalOpen] = useState<boolean>(false);
    const [pinModalOpen, setPinModalOpen] = useState<boolean>(false);
    const [undergroundBalance, setUndergroundBalance] = useState<string>('');
    const [isTokenExecuting, setIsTokenExecuting] = useState<boolean>(false);
    const [hashString, setHashString] = useState<string>('');

    const [stakingReward, setStakingReward] = useState('0');

    const handleChangeFirstSelect = (value: any) => {
        setSelectedFirstRange(value);
    };

    const handleChangeSecondSelect = (value: any) => {
        setSelectedSecondRange(value);
    };

    const handleChangeAdaptiveSelect = (value: any) => {
        setSelectedBothRange(value);
    };

    const getStatistic = async () => {
        const [statistic, membersCountGraph, padaVolumeGraph] = await Promise.all([
            getUndergroundStatistics(),
            getUndergroundGraph({
                interval: 'month',
                graph: 'membersCount'
            }),
            getUndergroundGraph({
                interval: 'month',
                graph: 'padaVolume'
            })
        ]);

        const membersCountGraphData = membersCountGraph?.periods.map((e) => ({
            name: SHORT_MONTH_NAMES[new Date(e.date).getUTCMonth()],
            amount: e.value
        }))?.reverse();
        const padaVolumeGraphData = padaVolumeGraph?.periods.map((e) => ({
            name: SHORT_MONTH_NAMES[new Date(e.date).getUTCMonth()],
            amount: e.value
        }))?.reverse();

        setGraphData({ wallets: membersCountGraphData || [], amount: padaVolumeGraphData || [] });
        setBothGraphData({ wallets: membersCountGraphData || [], amount: padaVolumeGraphData || [] });
        setPercentageLeft(statistic?.stakingMonthChangePercent.membersCount ? Math.abs(statistic.stakingMonthChangePercent.membersCount) : 0);
        setPercentageRight(statistic?.stakingMonthChangePercent.membersCount ? Math.abs(statistic.stakingMonthChangePercent.padaVolume) : 0);

        setStatistic({
            statistic,
            membersCountGraph,
            padaVolumeGraph,
        });
    }

    const fetchFirstGraph = async (opts: {
        members?: boolean;
        volume?: boolean;
    }) => {
        let wallets = graphData.wallets;
        let amount = graphData.amount;
        if (opts.members) {
            const range = (opts?.members && opts.volume ? selectedBothRange : selectedFirstRange)
            const membersCountGraph = await getUndergroundGraph({
                interval: range.value === 'months' ? 'month' : 'day',
                graph: 'membersCount'
            });

            wallets = membersCountGraph?.periods.map((e) => ({
                name: range.value === 'months' ? SHORT_MONTH_NAMES[new Date(e.date).getUTCMonth()] : SHORT_DAY_NAMES[new Date(e.date).getUTCDay()],
                amount: e.value
            }))?.reverse() || [];
        }

        if (opts.volume) {
            const range = (opts?.members && opts.volume ? selectedBothRange : selectedSecondRange)
            const padaVolumeGraph = await getUndergroundGraph({
                interval: range.value === 'months' ? 'month' : 'day',
                graph: 'padaVolume'
            });

            amount = padaVolumeGraph?.periods.map((e) => ({
                name: range.value === 'months' ? SHORT_MONTH_NAMES[new Date(e.date).getUTCMonth()] : SHORT_DAY_NAMES[new Date(e.date).getUTCDay()],
                amount: e.value
            }))?.reverse() || [];
        }

        (opts?.members && opts.volume ? setBothGraphData : setGraphData)({
            amount,
            wallets
        });
    }

    const canExecuteStakeOperation = async (): Promise<boolean> => {
        if (!userId) {
            updateUrlParams({
                deleteParams: true,
                setParams: {
                    oauth: 'true',
                    form: 'signup',
                }
            });
            return false;
        }

        if (!walletStatus) {
            updateUrlParams({
                newPathName: '/account/test-net'
            });
            return false;
        }

        if (isTokenExecuting) {
            return false;
        }

        return true;
    }

    const handleStaking = async (): Promise<any> => {
        try {
            if (!await canExecuteStakeOperation()) {
                return;
            }

            const keys = await getKeys('userKeys');

            if (keys.type === USER_WALLET_TYPES.WEB3) {
                return execStaking();
            }

            setPinModalOpen(true);
        } catch (e) {
            Notification.warning({
                message: notificationTitles?.warning,
                description: notificationDescriptions?.somethingWentWrong
            });
        }
    };

    const handleUnstaking = async (): Promise<any> => {
        try {
            await canExecuteStakeOperation();

            const keys = await getKeys('userKeys');

            if (keys.type === USER_WALLET_TYPES.WEB3) {
                return execUnstaking();
            }

            setPinModalOpen(true);
        } catch (e) {
            Notification.warning({
                message: notificationTitles?.warning,
                description: notificationDescriptions?.somethingWentWrong
            });
        }
    };

    const execStaking = async (privateKey?: string) => execStakingOperation({
        privateKey,
        isTokenExecuting,
        setPinModalOpen,
        setIsTokenExecuting,
        setHashString,
        setStakeAmount,
        stakeAmount,
        externalId,
        setUserStore: set,
        errorTranslation
    });

    const execUnstaking = async (privateKey?: string) => execUnstakingOperation({
        privateKey,
        isTokenExecuting,
        setPinModalOpen,
        setIsTokenExecuting,
        setHashString,
        setUnstakeAmount,
        unstakeAmount,
        externalId,
        setUserStore: set,
        errorTranslation
    });

    const syncUndergroundBalance = async () => {
        try {
            if (!externalId) {
                return;
            }
            const provider = getProvider();

            const undergroundContract = new Contract(UNDERGROUND_CONTRACT_ADDRESS, UNDERGROUND_ABI, provider);
            const balance = await undergroundContract.getBalance(externalId);
            const formattedBalance = ethers.formatUnits(balance, PADA_DEFAULT_PRECISION);
            setUndergroundBalance(formattedBalance)

            const stakingReward = await undergroundContract.getReward(externalId);
            const formattedStakingBalance = ethers.formatUnits(stakingReward, PADA_DEFAULT_PRECISION);
            setStakingReward(formattedStakingBalance);
        } catch (error) {
            return;
        }
    }

    useEffect(() => {
        fetchFirstGraph({
            members: true
        });
    }, [selectedFirstRange]);

    useEffect(() => {
        fetchFirstGraph({
            volume: true
        });
    }, [selectedSecondRange]);

    useEffect(() => {
        fetchFirstGraph({
            members: true,
            volume: true
        });
    }, [selectedBothRange]);

    useEffect(() => {
        syncUndergroundBalance();
    }, [externalId]);

    useEffect(() => {
        getStatistic();
    }, []);

    return (
        <div className={`${styles.scene} indent--page background--underground overflow--hidden`}>
            <div className='container height--100'>
                <FadeInAnimation delay={0.3}>
                    <h1 className={`${styles.scene__title} turn--center font--uppercase`}>
                        Underground
                    </h1>
                </FadeInAnimation>
                <div className={`width--100 ${styles['scene__content-wrapper']} fl fl--justify-b fl--align-st`}>
                    <div className={styles['scene__schedule-wrapper']}>
                        <div className='fl fl--dir-col fl--align-st'>
                            <Text color='light' size='small' weight='400' tag='h5' className={`${styles['scene__schedule-title']} font--uppercase`} fontFamily='copperplate'>{stackingNow}</Text>
                            <Text color='light' weight='400' size='x-const' tag='h5'>{statistic?.statistic?.staking.membersCount}
                                <Text color='medium-gray' weight='300' tag='span' size='small' fontFamily='ubuntu' className='font--normal'>
                                    {wallets}
                                </Text>
                            </Text>
                        </div>
                        <div className={styles['scene__schedule-statistic-wrapper']}>
                            <div className={`${styles['scene__schedule-statistic-header']} fl fl--justify-b fl--align-st`}>
                                <CustomSelect
                                    options={selectData.slice()}
                                    defaultOption={selectData[0]}
                                    onChange={handleChangeFirstSelect}
                                />
                                <div className='turn--right'>
                                    <Text tag='h5' size='y-medium' fontFamily="copperplate" color='light' weight='400'>
                                        {statistic?.membersCountGraph?.lastPeriodChange}
                                        <Text size='tiny' weight='400' tag='span' color='medium-gray' fontFamily='ubuntu'>{newWallets}</Text>
                                    </Text>
                                </div>
                            </div>
                            <Charts type="wallets" data={graphData} translations={translations?.metaData?.json} className={styles.scene__schedule} />
                        </div>
                    </div>
                    <SlidesAnimation delay={0.4} coordinates={{
                        y: 100,
                        x: 0
                    }}>
                        <div className={styles.scene__circle}>
                            <div className={styles['scene__circle-wrapper']}>
                                <div className={styles['scene__circle-content-wrapper']}>
                                    <div className={`${styles['scene__circle-text-wrapper']} turn--center`}>
                                        <h4 className={`${styles['scene__circle-title']} font--400 font--family-copperplate color--white`}>{totalAmount}</h4>
                                        <h3 className={`${styles['scene__circle-count']} color--white font--400 font--family-copperplate font--uppercase`}>
                                            {formatNumberValue({
                                                value: statistic?.statistic?.padaTotalAmount || 0,
                                                precision: 2,
                                                translations: {
                                                    billionSuffix,
                                                    millionSuffix,
                                                    thousandSuffix
                                                }
                                            })}
                                        </h3>
                                    </div>
                                    <div className={`${styles['scene__circle-text-wrapper']} turn--center`}>
                                        <h4 className={`${styles['scene__circle-title']} font--400 font--family-copperplate color--white`}>{maxTotalSupply}</h4>
                                        <h3 className={`${styles['scene__circle-count']} color--white font--400 font--family-copperplate font--uppercase`}>1Billion</h3>
                                    </div>
                                    <Button className={`${styles.scene__button} color--white`} onClick={() => parseFloat(undergroundBalance) > 0 ? setUnstakeModalOpen(true) : setStakeModalOpen(true)}>{undergroundBalance && parseFloat(undergroundBalance) > 0 ? unstakeNow : stakeNow}</Button>
                                </div>
                                <div className={`${styles['scene__statistic-percent']} ${styles['scene__statistic-percent--left']} ${percentageLeft <= 0 ? styles['scene__statistic-percent--down'] : styles['scene__statistic-percent--up']} relative--core fl fl--align-c fl--gap-12 fl--justify-c`}>
                                    <Text size="small" color='light' weight="400" fontFamily="copperplate" className={`${styles['scene__statistic-percent-text']} link--backlight link--secondary ${styles['scene__statistic-percent-text--left']}`}>
                                        {percentageLeft < 0 ? '-' : '+'} {percentageLeft.toFixed(2)}%
                                    </Text>
                                    <div className={
                                        `${styles['scene__icon']} ${styles['scene__icon--left']} ${percentageLeft <= 0 ? styles['scene__icon--down-left'] : styles['scene__icon--up']}`} />
                                </div>
                                <ArcComponent
                                    className={styles['scene__circle-line']}
                                    percentageLeft={percentageLeft}
                                    setPercentageLeft={setPercentageLeft}
                                />
                                <div className={`${styles['scene__statistic-percent']} ${styles['scene__statistic-percent--right']} ${percentageLeft <= 0 ? styles['scene__statistic-percent--down'] : styles['scene__statistic-percent--up']} relative--core fl fl--align-c fl--gap-12 fl--justify-c`}>
                                    <Text size="small" color='light' weight="400" fontFamily="copperplate" className={`${styles['scene__statistic-percent-text']} link--backlight link--secondary ${styles['scene__statistic-percent-text--right']}`}>
                                        {percentageRight < 0 ? '-' : '+'} {percentageRight.toFixed(2)}%
                                    </Text>
                                    <div className={
                                        `${styles['scene__icon']} ${styles['scene__icon--right']} ${percentageLeft <= 0 ? styles['scene__icon--down-right'] : styles['scene__icon--up']}`} />
                                </div>
                            </div>
                        </div>
                    </SlidesAnimation>
                    <div className={styles['scene__schedule-wrapper']}>
                        <div className={styles['scene__schedule-heading']}>
                            <Text color='light' size='small' weight='400' tag='h5' className={`${styles['scene__schedule-title']} font--uppercase`} fontFamily='copperplate'>{stackingNow}</Text>
                            <div className='fl fl--align-c fl--gap-12'>
                                <Text color='light' weight='400' size='x-const' tag='h5'>{statistic?.statistic?.staking.padaVolume || 0}</Text>
                                <Icon classNames={{
                                    icon: styles['scene__company-logo']
                                }} icon="flat/pada-gray" />
                            </div>
                        </div>
                        <div className={styles['scene__schedule-statistic-wrapper']}>
                            <div className={`${styles['scene__schedule-statistic-header']} fl fl--justify-b fl--align-c`}>
                                <div className='fl fl--gap-4'>
                                    <CustomSelect
                                        options={selectData.slice()}
                                        defaultOption={selectData[0]}
                                        onChange={handleChangeSecondSelect}
                                    />
                                </div>
                                <div className='fl fl--align-c fl--gap-8'>
                                    <Text color='light' fontFamily="copperplate" weight='400' size='y-medium' tag='h5'>{statistic?.padaVolumeGraph?.lastPeriodChange || 0}</Text>
                                    <Icon size={20} icon="flat/pada-gray" />
                                </div>
                            </div>
                            <Charts type="amount" data={graphData} translations={translations?.metaData?.json} className={styles.scene__schedule} />
                        </div>
                    </div>
                </div>
                <div className={`${styles['scene__adaptive-statistic-text-wrapper']} margin--b-42`}>
                    <Text color='light' size='small' weight='400' tag='h5' className={`${styles['scene__schedule-title']} font--uppercase`} fontFamily='copperplate'>{stackingNow}</Text>
                    <div className="relative--core">
                        <Text color='light' weight='400' size='x-const' tag='h5'>{statistic?.statistic?.staking.membersCount}{" "}
                            <Text color='medium-gray' weight='300' tag='span' size='small' fontFamily='ubuntu' className='font--normal'>/
                                {wallets}
                            </Text>
                        </Text>
                        <Text size="small" color='light' weight="400" fontFamily="copperplate" className={`${styles['scene__adaptive-statistic-percent-text']} link--backlight link--secondary`}>
                            {percentageRight < 0 ? '-' : '+'} {percentageRight.toFixed(2)} %
                            <div className={`${styles['scene__adaptive-icon']} ${percentageRight <= 0 ? styles['scene__adaptive-icon--down'] : styles['scene__adaptive-icon--up']}`} />
                        </Text>
                    </div>
                    <div className="relative--core fl fl--align-c fl--gap-12 fl--justify-c">
                        <Text color='light' weight='400' size='x-const' tag='h5'>{statistic?.statistic?.staking.padaVolume}</Text>
                        <Icon size={32} icon="flat/pada-gray" />
                        <Text size="small" color='light' weight="400" fontFamily="copperplate" className={`${styles['scene__adaptive-statistic-percent-text']} link--backlight link--secondary`}>
                            {percentageLeft < 0 ? '-' : '+'} {percentageLeft.toFixed(2)}%
                            <div className={
                                `${styles['scene__adaptive-icon']} ${percentageLeft <= 0 ? styles['scene__adaptive-icon--down'] : styles['scene__adaptive-icon--up']}`
                            } />
                        </Text>
                    </div>
                </div>
                <div className={`${styles['scene__adaptive-statistic-wrapper']} fl fl--justify-b fl--align-c margin--b-20`}>
                    <CustomSelect
                        options={selectData.slice()}
                        defaultOption={selectData[0]}
                        onChange={handleChangeAdaptiveSelect}
                    />
                    <div className={styles['scene__adaptive-statistic-text']}>
                        <Text color="light" weight="400" fontFamily="copperplate" size="x-const-subtitle">
                            {statistic?.statistic?.staking.membersCount}{" "}
                            <Text fontFamily="ubuntu" size="tiny" weight="400" tag="span" color="medium-gray">/ {newWallets}</Text>
                        </Text>
                        <div className='fl fl--align-c fl--gap-8'>
                            <Text color='light' weight='400' size='x-const-subtitle' fontFamily="copperplate" tag='h5'>{statistic?.statistic?.staking.padaVolume}</Text>
                            <Icon size={18} icon="flat/pada-gray" />
                        </div>
                    </div>
                </div>
                <Charts
                    data={bothGraphData}
                    type="both"
                    translations={translations?.metaData?.json}
                    className={styles['scene__adaptive-chart']} />
            </div>
            {
                stakeModalOpen ? (
                    <StakingModal
                        translations={translations}
                        modalOpen={stakeModalOpen}
                        setModalOpen={setStakeModalOpen}
                        stakeAmount={stakeAmount}
                        setStakeAmount={setStakeAmount}
                        isTokenExecuting={isTokenExecuting}
                        handleStaking={handleStaking}
                        pinModalOpen={pinModalOpen}
                        execStaking={execStaking as any}
                        hashString={hashString}
                        setHashString={setHashString}
                        lang={lang}
                        connectWalletModalText={connectWalletModalText}
                    />
                ) : null
            }
            {
                unstakeModalOpen ? (
                    <UnstakeModal
                        unstakeAmount={unstakeAmount}
                        setUnstakeAmount={setUnstakeAmount}
                        translations={translations}
                        unstakeModalOpen={unstakeModalOpen}
                        setUnstakeModalOpen={setUnstakeModalOpen}
                        isTokenExecuting={isTokenExecuting}
                        handleUnstaking={handleUnstaking}
                        undergroundBalance={undergroundBalance}
                        undergroundBalanceGift={stakingReward}
                        pinModalOpen={pinModalOpen}
                        execUnstaking={execUnstaking}
                        hashString={hashString}
                        setHashString={setHashString}
                        lang={lang}
                        connectWalletModalText={connectWalletModalText}
                    />
                ) : null
            }
        </div>
    )
}

export default Scene;
