'use client';

// Components
import Text from '@/common/Text/index.server';
import Icon from '@/common/Icon/index.client';

// Types
import { ITextContent } from '@/types/page.types';

// Styles
import styles from './index.module.sass';

interface IProps {
  translations: ITextContent;
}

const InfoPlate = (props: IProps) => {
  const {
    translations: {
      metaData: {
        json: {
          stakeInfoPlate = ''
        } = {}
      } = {}
    } = {}
  } = props;

  return (
    <div className={`${styles['info-plate']} padding--l-18 padding--r-16 padding--v-16 radius--16`}>
      <Icon
        icon="flat/info"
        size={14}
        color="color--extra-gray"
        />
      <Text
        size="minimum"
        fontFamily="ubuntu"
        color="extra-gray"
      >
        {stakeInfoPlate}
      </Text>
    </div>
  );
};

export default InfoPlate;
