import React from 'react';

// Components
import AccordionItem from './AccordionItem/index.client';

// Styles
import styles from './index.module.sass';

interface IAccordionProps {
    items: {
        title: string;
        firstSubtitle: string;
        secondSubtitle?: string;
    }[],
    className?: string;
}

const Accordion = ({ items, className }: IAccordionProps) => {
    return (
        <ul className={`${styles.accordion} ${className || ''}`}>
            {items?.map((item, idx) => (
                <AccordionItem
                    key={idx}
                    id={idx}
                    title={item.title}
                    firstSubtitle={item.firstSubtitle}
                    secondSubtitle={item.secondSubtitle}
                />
            ))}
        </ul>
    );
};

export default Accordion;
