'use client';
import ReactQueryProvider from '@/providers/ReactQuery/index.client';

// Components
import Text from '@/common/Text/index.server';
import FadeIn from '@/components/Animations/FadeIn/index.client';
import SubscribeForm from './SubscribeForm/index.client';

// Types
import { ITextContent } from '@/types/text.types';

// Styles
import styles from './index.module.sass';

interface IStep {
  title: string;
  text: string;
  list?: string[];
}

interface IProps {
  text: ITextContent;
  translations: ITextContent;
}

const InvestorsProcess = (props: IProps) => {
  const {
    text: {
      metaData: {
        json: {
          steps = []
        } = {}
      } = {}
    } = {},
    translations
  } = props;

  return (
    <section className={styles.process}>
      {steps?.map((step: IStep, i: number) => {
        const { title = '', text = '', list = [] } = step;

        const isFirst = i === 0;

        return (
          <div
            {...(isFirst ? { id: 'subscribe' } : {})}
            key={`process step - ${title}`}
            className={`${styles.process__step} ${isFirst ? styles['process__step--first'] : ''}`}
          >
            <FadeIn className={`${styles['process__step-container']} container`}>
              <span className={`${styles['process__step-index']} fl fl--align-c color--extra-gray font--400 font--family-copperplate`}>
                0{i}
              </span>
              <div className={styles['process__step-content']}>
                <Text tag="h2" size={isFirst ? "y-huge" : "xl-big"} weight="400" color={isFirst ? "light" : "extra-gray"} className="margin--b-32 font--family-copperplate">
                  {title}
                </Text>
                <Text size={isFirst ? "default" : "default"} color="extra-gray" className="font--family-ubuntu">
                  {text}
                </Text>
                {
                  list?.length ? (
                    <ul className="margin--t-24 padding--l-16 fl fl--dir-col fl--gap-16">
                      {list?.map((item) => (
                        <li
                          key={`process step - ${title} - li - ${item}`}
                          className={`${styles['process__step-list-item']} padding--l-24 color--extra-gray font--300 text--default font--family-ubuntu`}
                        >
                          <b className="font--500">
                            {item?.split('|')?.[0]}
                          </b>
                          {item?.split('|')?.[1]}
                        </li>
                      ))}
                    </ul>
                  ) : null
                }
                {
                  isFirst ? (
                    <ReactQueryProvider>
                      <SubscribeForm translations={translations} />
                    </ReactQueryProvider>
                  ) : null
                }
              </div>
            </FadeIn>
          </div>
        )
      })}
    </section>
  );
};

export default InvestorsProcess;
