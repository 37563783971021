import { DEFAULT_LOCALE } from "@/configuration/locales";
import { getClientCookie } from "@/helpers/clientCookies.hl";
import { IText, ITextContent } from "@/types/text.types";

const getTextWithSingleLanguage = (props: { text: IText | IText[], language: string }): ITextContent | { [key: string]: ITextContent } => {
    const {
        text
    } = props;
    const language = getClientCookie('lang') || DEFAULT_LOCALE
  
  if(Array.isArray(text)) {
    const hashOfTexts = text.reduce((acc, item) => {
        return {
          ...acc,
          [item.label]: { ...item, ...item.contents[language] }
        }
      }, {})
    return hashOfTexts;
  } else {
    return { ...text, ...text.contents[language] };
  }
}

const getText = async (
  props: {
    language: string,
    label: string
  }
): Promise<ITextContent> => {
  try {
    const {
        label,
        language
    } = props;

    const response = await fetch(`/api/texts/${label}`, {
      next: { revalidate: 1 },
    });
    const text = await response.json();

    return {
        ...getTextWithSingleLanguage({ text, language }) as ITextContent
    };
  } catch (e) {
    return {} as ITextContent;
  }
};

const getTexts = async (
  props: {
    labels: string[],
    filters?: string,
    language: string
  }
): Promise<{ [key: string]: ITextContent }> => {
  const {
    labels = [],
    filters,
    language
} = props;

  const textsFilter = JSON.stringify({ label: { in: [...labels] } });

  try {
    const response = await fetch(
      `/api/texts?filter=${textsFilter}${
        filters ? `&${filters}` : ""
      }`,
      {
        next: { revalidate: 1 },
      }
    );
    const text = await response.json();
    return {
      ...getTextWithSingleLanguage({ text: text?.items, language }) as { [key: string]: ITextContent }
    };
  } catch (e) {
    return {};
  }
};

export { 
  getText,
  getTexts
};
