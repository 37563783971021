import { ethers } from "ethers";

let index = 0;

const NETWORK_URLS: string[] = (process.env.NEXT_PUBLIC_NETWORK as string)?.split(',');

const getProvider = (isServer = false): ethers.AbstractProvider => {
    if (process.env.NODE_ENV === 'development' && ['http://padatoken:8545', 'http://localhost:8545'].includes(NETWORK_URLS?.[0])) {
        return ethers.getDefaultProvider(isServer ? 'http://padatoken:8545' : 'http://localhost:8545');
    }

    index = index >= NETWORK_URLS?.length ? 0 : index;
    const provider = ethers.getDefaultProvider(NETWORK_URLS?.[index]);
    index++;
    return provider;
};

export default getProvider;
