/* eslint-disable react-hooks/exhaustive-deps */
import { IAsset } from "@/types/crm/coreBanking/assets.types";
import { useEffect } from "react";
import { useOauthStore } from "../../oauth.store";

const OauthStoreInitializer = (props: { data: IAsset[] }) => {
    const {
        data
    } = props;
    const {
        set
    } = useOauthStore();

    useEffect(() => {
        if (!data) return;
        set(data);
    }, [data]);

    return null;
}

export default OauthStoreInitializer;