'use client';
import React, { useState } from 'react';
import { ethers } from 'ethers';
import { useMutation } from '@tanstack/react-query';
import { useUserStore } from '@/store/zustand/user.store';
import getUserDetails from '@/actions/user/get';
import updateUser from '@/actions/user/update';
import { useAssetsStore } from '@/store/zustand/assets.store';

// Components
import LoaderBlur from '@/common/LoaderBlur/index.client';
import Text from '@/common/Text/index.server';
import ClientModal from '@/common/Modal/Client/index.client';
import Button from '@/common/Button/index.client';
import { Notification } from '@/common/Notification';

// Helpers
import { saveKeys } from '@helpers/indexedDb.hl';
import isJSON from "@helpers/isJSON.hl";

// Types
import { ITextContent } from '@/types/page.types';
import USER_WALLET_TYPES from '@constants/userWallets.const';

// Styles
import styles from './index.module.sass';
import { useWalletStore } from "@/store/zustand/wallet.store";

interface IProps {
  translations: ITextContent;
}

const ConnectWalletErrorModal = (props: IProps) => {
  const {
    translations: {
      metaData: {
        json: {
          walletErrors: {
            installMetamask = '',
            universalError = '',
            modalTitle = ''
          } = {},
          connect
        } = {}
      } = {}
    } = {}
  } = props;

  const {
    walletProviderError,
    setWalletProviderError
  } = useWalletStore();

  const [loading, setLoading] = useState(false);
  const {
    set: setUserDetails,
    user: {
      meta
    }
  } = useUserStore();

  const {
    set
  } = useAssetsStore();

  const { isPending: isUpdating, mutateAsync } = useMutation({
    mutationKey: ['update-user'],
    mutationFn: updateUser,
    onSuccess: async () => {
      const data = await getUserDetails();
      setUserDetails(data);
    }
  });

  const handleCloseClick = () => {
    setWalletProviderError('');
  }

  const connectMetamask = async () => {
    try {
      if (typeof (window as any).ethereum !== 'undefined') {
        setLoading(true);
        const provider = new ethers.BrowserProvider((window as any).ethereum);

        await provider.send("eth_requestAccounts", []);

        const wallet = await provider.getSigner();

        const publicKey = await wallet.getAddress();

        await saveKeys({ id: 'userKeys', type: USER_WALLET_TYPES.WEB3, publicKey });

        const response = await fetch('/api/smart-wallet/create', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ publicKey }),
        });

        if (!response.ok) {
          throw new Error('Failed to create smart wallet on backend');
        }

        const data = await response.json();

        await mutateAsync({
          externalId: data.address,
          meta: JSON.stringify({
            ...(isJSON(meta) ? JSON.parse(meta) : {}),
            connectType: USER_WALLET_TYPES.WEB3,
            connectAddress: publicKey
          })
        });

        set({ [data.symbol]: data });

        handleCloseClick();
        setWalletProviderError('');
      } else {
        setWalletProviderError(installMetamask);
      }
    } catch (error) {
      Notification.error({
        message: 'Something went wrong',
      });
      setWalletProviderError(`${universalError} error`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ClientModal isOpened={!!walletProviderError} setIsOpened={handleCloseClick}>
      <div className={styles['connect-wallet-error-modal']}>
        <LoaderBlur fetch={loading} />
        <Text
          tag="h5"
          size="x-const-subtitle"
          transform="uppercase"
          weight="400"
          fontFamily="copperplate"
          className="margin--b-8"
        >
          {modalTitle}
        </Text>
        <Text
          size="minimum"
          fontFamily="ubuntu"
          color="extra-gray"
          className="margin--b-24"
        >
          {walletProviderError}
        </Text>
        <Button
          onClick={connectMetamask}
          className="width--100"
          disabled={isUpdating}
        >
          <div className={styles['connect-wallet-error-modal__metamask-icon']} />
          {connect} Metamask
        </Button>
      </div>
    </ClientModal>
  );
};

export default ConnectWalletErrorModal;
