import React from 'react';

import styles from './index.module.sass';

interface ILoaderBlurTypes {
    fetch: boolean;
}

const LoaderBlur = (props: ILoaderBlurTypes) => {
    const { fetch } = props;
    return (
        <>
            {fetch ? (
                <div className={`${styles.loader} fl fl--align-c fl--justify-c`}>
                    <div className={styles.loader__circle} />
                </div>
            ) : null}
        </>
    )
}

export default LoaderBlur;
