// Types
import { IAsset } from "@/types/crm/coreBanking/assets.types";
import {CURRENCIES} from "@constants/token.const";

const getAssets = async (): Promise<{ [key: number]: IAsset } | null> => {
  try {
    const response = await fetch(
      `/api/assets?filters=${JSON.stringify({
        currencies: [CURRENCIES.AMBER]
      })}`,
      {
        next: { revalidate: 1 },
      }
    );

    return response.json();
  } catch (e) {
    return null;
  }
};


export {
  getAssets
};
