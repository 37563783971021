import React, { useState, useEffect } from 'react';
import { useOauthStore } from '@/store/zustand/oauth.store';
import { useWalletStore } from '@/store/zustand/wallet.store';
import { useUserStore } from '@/store/zustand/user.store';
import getUserDetails from '@/actions/user/get';

// Components
import OauthModal from '@/components/OauthModal/index.client';
import { ConnectWalletModal } from '@/components/index.client';

// Helpers
import { isJSON } from '@/helpers/object.hl';

const withAuthAndWalletChecks = <P extends any>(
  WrappedComponent: React.ComponentType<P>
) => {
  const WithChecks: React.FC<P> = (props: any) => {
    const { setModalOpen, translations, lang, connectWalletModalText } = props || {};
    const { isAuthorized } = useOauthStore();
    const { status: walletStatus, setTrigger } = useWalletStore();
    const {
      user: { externalId, meta },
      set: setUserDetails,
    } = useUserStore();

    const [userMeta, setUserMeta] = useState<{ connectAddress?: string }>({});
    const [isLoading, setIsLoading] = useState(false);

    const [isModalsActive, setIsModalsActive] = useState({
      isAuthModalOpen: false,
      isConnectWalletOpen: false,
      isWrappedModalOpen: false,
      isOpen: true,
    });

    const checkStatusesHandle = () => {
      if (isModalsActive.isOpen) {
        if (!isAuthorized) {
          return setIsModalsActive((prev) => ({
            ...prev,
            isAuthModalOpen: true,
          }));
        } else if (walletStatus && userMeta?.connectAddress && externalId) {
          setIsModalsActive((prev) => ({
            ...prev,
            isAuthModalOpen: false,
            isConnectWalletOpen: false,
            isWrappedModalOpen: true,
          }));
        } else if (
          !isLoading &&
          ((!externalId && !userMeta?.connectAddress) ||
            (!externalId && userMeta?.connectAddress))
        ) {
          setIsModalsActive((prev) => ({
            ...prev,
            isAuthModalOpen: false,
            isConnectWalletOpen: true,
          }));
        }
      } else {
        handleCloseAll();
      }
    };

    const handleCloseAll = () => {
      setIsModalsActive({
        isAuthModalOpen: false,
        isConnectWalletOpen: false,
        isWrappedModalOpen: false,
        isOpen: false,
      });
      if (setModalOpen) setModalOpen(false);
    };

    const onSuccessHandler = async () => {
      setIsLoading(true);
      const data = await getUserDetails();
      setUserDetails(data);
      setIsLoading(false);
      setTrigger(`f${(~~(Math.random() * 1e8)).toString(16)}`);
    };

    useEffect(() => {
      checkStatusesHandle();
    }, [
      isModalsActive.isOpen,
      isAuthorized,
      walletStatus,
      externalId,
      userMeta?.connectAddress,
      isLoading,
    ]);

    useEffect(() => {
      if (isJSON(meta)) {
        setUserMeta(JSON.parse(meta));
      }
    }, [meta]);

    return (
      <>
        {isModalsActive.isAuthModalOpen ? (
          <OauthModal
            isModalOpen={isModalsActive.isAuthModalOpen}
            closeModalHandle={handleCloseAll}
            onSuccessHandler={onSuccessHandler}
            translations={translations}
            lang={lang}
            isLoading={isLoading}
          />
        ) : null}
        {isModalsActive.isConnectWalletOpen ? (
          <ConnectWalletModal
            isModalOpen={isModalsActive.isConnectWalletOpen}
            closeModalHandle={handleCloseAll}
            onSuccessHandler={() => setIsLoading(true)}
            text={connectWalletModalText}
            translation={translations}
            lang={lang}
          />
        ) : null}
        {isModalsActive.isWrappedModalOpen ? (
          <WrappedComponent
            {...props}
            modalOpen={isModalsActive.isWrappedModalOpen}
            setModalOpen={handleCloseAll}
          />
        ) : null}
      </>
    );
  };

  return WithChecks;
};

export default withAuthAndWalletChecks;
