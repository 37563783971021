"use client"
import React, { useEffect, useRef, useState } from 'react';
import type { LottiePlayer } from 'lottie-web';

interface AnimationTypes {
  className?: string,
  path: string
}

const LottieAnimation = (props: AnimationTypes) => {
  const { className, path } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [lottie, setLottie] = useState<LottiePlayer | null>(null);

  useEffect(() => {
    import('lottie-web').then((Lottie) => setLottie(Lottie.default));
  }, []);

  useEffect(() => {
    if (lottie && ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path,
      });

      return () => animation.destroy();
    }
  }, [lottie]);

  return (
    <div className={className} ref={ref}/>
  );
};

export default LottieAnimation;