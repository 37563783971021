'use client';

// Components
import Text from '@/common/Text/index.server';
import Button from '@/common/Button/index.client';

// Types
import { ITextContent } from '@/types/page.types';

// Styles
import styles from '../index.module.sass';

interface IProps {
  translation: ITextContent;
  handleCloseClick: () => void;
}

const RestoreWalletStep3Content = (props: IProps) => {
  const {
    translation: {
      metaData: {
        json: {
          step = '',
          walletRestored = '',
          walletRestoredSubtitle = '',
          close = ''
        } = {}
      } = {}
    } = {},
    handleCloseClick
  } = props;

  return (
    <>
      <Text
        tag="h5"
        size="big"
        transform="uppercase"
        weight="400"
        fontFamily="copperplate"
        className={`${styles['connect-wallet-modal__subtitled-title']} turn--center`}
      >
        {walletRestored}
      </Text>
      <Text
        size="minimum"
        weight="300"
        fontFamily="ubuntu"
        color="extra-gray"
        className={`${styles['connect-wallet-modal__subtitle']} turn--center`}
      >
        {walletRestoredSubtitle}
      </Text>
      <Button
        onClick={handleCloseClick}
        className={styles['connect-wallet-modal__button']}
      >
        {close}
      </Button>
    </>
  );
};

export default RestoreWalletStep3Content;
