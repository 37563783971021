'use client';
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useUserStore } from "../../user.store";
import getUserDetails from "@/actions/user/get";
import { CLIENT_POLLING_INTERVAL } from "@/constants/clients";
import { IClient } from "@/types/crm/core/clients.types";

const UserStoreInitializer = (props: { data: IClient }) => {
    const {
        data
    } = props;
    const {
        set
    } = useUserStore();

    let interval: any;
    useEffect(() => {
        if (!data?.id) return;
        set(data);

        if (interval) clearInterval(interval);

        interval = setInterval(async () => {
            try {
                const user = await getUserDetails();
                set(user);
            } catch (e) {
                console.error(e);
            }
        }, CLIENT_POLLING_INTERVAL);

        return () => {
            if (interval) clearInterval(interval);
        }
    }, [data]);

    return null;
}

export default UserStoreInitializer;
