import { Contract, ethers } from "ethers";

// Components
import { Notification } from "@/common/Notification";

// Constants
import {
  PADA_ABI,
  PADA_CONTRACT_ADDRESS,
  PADA_DEFAULT_PRECISION,
  PADA_PAYMASTER_ADDRESS,
  SMART_WALLET_ABI,
  UNDERGROUND_ABI,
  UNDERGROUND_CONTRACT_ADDRESS
} from "@/constants/token.const";
import USER_WALLET_TYPES from "@/constants/userWallets.const";

// Helpers
import { getKeys } from "@helpers/indexedDb.hl";
import getProvider from "@helpers/getProvider.hl";
import { syncBalances } from "@helpers/token.hl";
import execute from "@helpers/executeOperation.hl";

const execStakingOperation = async (opts: {
  isTokenExecuting: boolean;
  privateKey?: string;
  setPinModalOpen: (value: boolean) => void;
  setIsTokenExecuting: (value: boolean) => void;
  setHashString: (value: string) => void;
  setStakeAmount: (value: string) => void;
  stakeAmount: string;
  externalId: string;
  setUserStore: (value: any) => void;
  errorTranslation: string;
}) => {
  const {
    setUserStore,
    isTokenExecuting,
    privateKey,
    setPinModalOpen,
    setIsTokenExecuting,
    setHashString,
    setStakeAmount,
    stakeAmount,
    externalId,
    errorTranslation
  } = opts;

  if (isTokenExecuting) {
    console.warn('Staking already running');
    return;
  }

  setPinModalOpen(false);

  const keys = await getKeys('userKeys');

  if (keys.publicKey && ((keys.type === USER_WALLET_TYPES.WEB3 && typeof (window as any).ethereum !== 'undefined') || privateKey)) {
    try {
      setIsTokenExecuting(true);

      let provider;
      let signer;

      if (keys.type === USER_WALLET_TYPES.WEB3) {
        provider = new ethers.BrowserProvider((window as any).ethereum);

        const accounts = await provider.listAccounts();

        if (accounts.length !== 1) {
          console.warn('Wallet misconfigured');
          setIsTokenExecuting(false);
          return;
        }

        signer = await provider.getSigner();
      } else {
        provider = getProvider();
        signer = new ethers.Wallet(privateKey as string, provider);
      }

      const amount = ethers.parseUnits(stakeAmount, PADA_DEFAULT_PRECISION);

      const padaContract = new Contract(PADA_CONTRACT_ADDRESS, PADA_ABI, provider);
      const smartWallet = new Contract(externalId, SMART_WALLET_ABI, provider);
      const undergroundContract = new Contract(UNDERGROUND_CONTRACT_ADDRESS, UNDERGROUND_ABI, provider);

      const approveData = padaContract.interface.encodeFunctionData("approve", [UNDERGROUND_CONTRACT_ADDRESS, amount]);
      const undergroundData = await undergroundContract.interface.encodeFunctionData('stake', [amount]);

      const executeData = await smartWallet.interface.encodeFunctionData('executeBatch', [[PADA_CONTRACT_ADDRESS, UNDERGROUND_CONTRACT_ADDRESS], [0, 0], [approveData, undergroundData]]);

      const hash = await execute({
        executeData,
        smartWallet,
        signer,
        provider,
        paymaster: PADA_PAYMASTER_ADDRESS
      });

      setHashString(hash);
      setStakeAmount('');
    } catch (error: any) {
      Notification.error({
        message: errorTranslation,
        description: error.message
      });
      console.error('Could not connect to wallet', error);
    } finally {
      setIsTokenExecuting(false);
      syncBalances({
        externalId,
        set: setUserStore
      });
    }
  } else {
    console.error('Please install MetaMask or another wallet provider.');
  }
};

const execUnstakingOperation = async (opts: {
  isTokenExecuting: boolean;
  privateKey?: string;
  setPinModalOpen: (value: boolean) => void;
  setIsTokenExecuting: (value: boolean) => void;
  setHashString: (value: string) => void;
  setUnstakeAmount: (value: string) => void;
  unstakeAmount: string;
  externalId: string;
  setUserStore: (value: any) => void;
  errorTranslation: string;
}) => {
  const {
    setUserStore,
    isTokenExecuting,
    privateKey,
    setPinModalOpen,
    setIsTokenExecuting,
    setHashString,
    setUnstakeAmount,
    unstakeAmount,
    externalId,
    errorTranslation
  } = opts;
  if (isTokenExecuting) {
    console.warn('Staking already running');
    return;
  }

  setPinModalOpen(false);

  const keys = await getKeys('userKeys');

  if (keys.publicKey && ((keys.type === USER_WALLET_TYPES.WEB3 && typeof (window as any).ethereum !== 'undefined') || privateKey)) {
    try {
      setIsTokenExecuting(true);

      let provider;
      let signer;

      if (keys.type === USER_WALLET_TYPES.WEB3) {
        provider = new ethers.BrowserProvider((window as any).ethereum);

        const accounts = await provider.listAccounts();

        if (accounts.length !== 1) {
          console.warn('Wallet misconfigured');
          setIsTokenExecuting(false);
          return;
        }

        signer = await provider.getSigner();
      } else {
        provider = getProvider();
        signer = new ethers.Wallet(privateKey as string, provider);
      }
      const amount = ethers.parseUnits(unstakeAmount, PADA_DEFAULT_PRECISION);

      const smartWallet = new Contract(externalId, SMART_WALLET_ABI, provider);
      const undergroundContract = new Contract(UNDERGROUND_CONTRACT_ADDRESS, UNDERGROUND_ABI, provider);

      const undergroundData = await undergroundContract.interface.encodeFunctionData('unstake', [amount]);
      const executeData = await smartWallet.interface.encodeFunctionData('execute', [UNDERGROUND_CONTRACT_ADDRESS, 0, undergroundData]);

      const hash = await execute({
        executeData,
        smartWallet,
        signer,
        provider,
        paymaster: PADA_PAYMASTER_ADDRESS
      });

      setHashString(hash);
      setUnstakeAmount('');
    } catch (error: any) {
      Notification.error({
        message: errorTranslation,
        description: error.message
      });
      console.error('Could not connect to wallet', error);
    } finally {
      setIsTokenExecuting(false);
      syncBalances({
        externalId,
        set: setUserStore
      });
    }
  } else {
    console.error('Please install MetaMask or another wallet provider.');
  }
}


export {
  execStakingOperation,
  execUnstakingOperation
};