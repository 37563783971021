import { usePathname, useRouter, useSearchParams } from "next/navigation";

interface IUpdateUrlParams {
  newPathName?: string;
  setParams?: Record<string, string>;
  deleteParams?: string[] | true;
}

const useUpdateUrlParams = () => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const { replace } = useRouter();

  const getUpdatedPath = (props?: IUpdateUrlParams) => {
    const {
      newPathName = '',
      setParams = {},
      deleteParams = []
    } = props || {};

    const params = deleteParams === true
      ? new URLSearchParams()
      : new URLSearchParams(searchParams.toString());

    if (Array.isArray(deleteParams)) {
      deleteParams.forEach(key => {
        params.delete(key);
      });
    }

    Object.entries(setParams).forEach(([key, value]) => {
      params.set(key, value);
    });

    return `${newPathName || pathname}${Array.from(params.entries()).length > 0 ? `?${params.toString()}` : ''}`;
  };

  const updateUrlParams = (props?: IUpdateUrlParams) => {
    const newPath = getUpdatedPath(props || {});
    replace(newPath, { scroll: false });
  };

  return { updateUrlParams, getUpdatedPath };
}

export {
  useUpdateUrlParams
}
