"use client"

import React, { ReactChild } from 'react';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import './tooltip.sass';
import Icon from '../Icon/index.client';

const TooltipComponent = (props: { text: string, children: ReactChild, placement: TooltipPlacement, singleRow?: boolean }): JSX.Element => {
    const { 
      text, 
      children, 
      placement,
      singleRow
    } = props;
  return (
    <Tooltip 
      mouseLeaveDelay={0}
      destroyTooltipOnHide
      placement={placement}
      title={
        <div className='fl fl--align-c'>
            <Icon
                icon="flat/tooltip-icon"
                size={16}
                classNames={{ wrapper: 'margin--r-8 relative--core' }}
            />
            {text}
        </div>
      }
      arrow={false}
      overlayClassName={singleRow ? "single-row-tooltip" : ""}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipComponent;
