import Link from 'next/link';
import linkStyles from './index.module.sass'
import { UrlObject } from 'url';

interface ILink {
    href: string | UrlObject;
    target?: string;
    children?: React.ReactNode;
    className?: string;
    scroll?: boolean;
    handleClick?: () => void;
}

const LinkComponent = (props: ILink) => {
    const { href, target, children, className, scroll, handleClick } = props;
    return (
        <Link onClick={handleClick} scroll={scroll} href={href} target={target} className={`${linkStyles.link} ${className} font--underline hover--underline cursor--custom-pointer`}>
            {children}
        </Link>
    )
}

export default LinkComponent;