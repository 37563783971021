'use client';
import { useState } from 'react';

// Components
import Text from '@/common/Text/index.server';
import TestnetInfoModal from '../TestnetInfoModal/index.client';

// Constants
import { NET_TYPES } from '@/constants/net.const';

// Types
import { ITextContent } from '@/types/page.types';

// Styles
import styles from './index.module.sass';

interface IProps {
  translations: ITextContent;
  text: ITextContent;
  isAccount?: boolean;
  netType?: string;
}

const TestnetPlug = (props: IProps) => {
  const {
    translations: {
      metaData: {
        json: {
          findOutMore = ''
        } = {}
      } = {}
    },
    text: {
      title = '',
      metaData: {
        json: {
          mainNet: {
            title: mainNetTitle = ''
          } = {}
        } = {}
      } = {}
    } = {},
    isAccount,
    netType
  } = props;
  const [isTestnetInfoModalOpen, setIsTestnetInfoModalOpen] = useState(false);

  return (
    <>
      {
        isTestnetInfoModalOpen ? (
          <TestnetInfoModal
            isAccount={isAccount}
            isOpened={isTestnetInfoModalOpen}
            setIsOpened={setIsTestnetInfoModalOpen}
          />
        ) : null
      }
      <div
        onClick={() => setIsTestnetInfoModalOpen(true)}
        className={`${styles['testnet-plug']} ${styles[`testnet-plug--${netType}`]} cursor--custom-pointer width--100 fl fl--align-c fl--justify-c padding--v-12`}
      >
        <div className="container">
          <Text
            size="x-minimum"
            fontFamily="ubuntu"
            color="extra-gray"
          >
            {`${netType === NET_TYPES.MAIN_NET ? mainNetTitle : title} `}
            <Text
              tag="span"
              size="x-minimum"
              weight="500"
              fontFamily="ubuntu"
              color="extra-gray"
              className={`${styles['testnet-plug__link']}`}
            >
              {`${findOutMore} →`}
            </Text>
          </Text>
        </div>
      </div>
    </>
  );
};

export default TestnetPlug;
