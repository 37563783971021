'use client';
import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';

// Hooks
import { useAssetsStore } from '@/store/zustand/assets.store';

// Components
import Text from '@/common/Text/index.server';
import Icon from '@/common/Icon/index.client';
import BalancesSlider from './BalancesSlider/index.client';
import Button from '@/common/Button/index.client';

// Icons
import rubinImg from '../../../public/img/rubin.svg';

// Store
import { useWalletStore } from '@/store/zustand/wallet.store';
import { useUserStore } from '@/store/zustand/user.store';

// Constants
import { CURRENCIES, PADA_DASHBOARD_PRECISION_UI, USDT_PRECISION_UI } from '@/constants/token.const';
import { ITextContent } from '@/types/text.types';
import USER_WALLET_TYPES from '@/constants/userWallets.const';

//  Decorators
import withCryptoBalances from '@/decorators/withCryptoBalances.dec';

// Types
import { ISliderDataItem } from './types';

// Helpers
import { isJSON } from '@/helpers/object.hl';

// Styles
import styles from './index.module.sass';

interface IProps {
    text: ITextContent;
    translations: ITextContent;
    isInventory?: boolean;
    classNames?: {
        wrapper?: string;
        content?: string;
    };
}

const ClientBalances = (props: IProps) => {
    const {
        translations: {
            metaData: {
                json: {
                    pada = '',
                    amber = '',
                    myBalances = '',
                    apply = '',
                    restore = ''
                } = {}
            } = {}
        } = {},
        text: {
            metaData: {
                json: {
                    createWalletTitle = '',
                    createWalletText = '',
                    restoreWalletTitle = '',
                    restoreWalletText = '',
                    deployingWalletTitle = '',
                    deployingWalletText = ''
                } = {}
            } = {}
        },
        classNames: {
            wrapper: wrapperClassName = '',
            content: contentClassName = ''
        } = {},
        isInventory
    } = props;


    const [userMeta, setUserMeta] = useState<{ connectAddress?: string; }>({});
    const [preparedData, setPreparedData] = useState<ISliderDataItem[]>([]);

    const sliderRef = useRef<Slider>(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    const {
        setIsConnectWalletModalActive,
        type,
        status
    } = useWalletStore();

    const {
        user: {
            externalId = '',
            meta = ''
        } = {}
    } = useUserStore();

    const {
        currencies,
        isBlockchainLoaded
    } = useAssetsStore((store) => store);

    const isTablet = useMediaQuery({
        query: '(max-width: 1023px)'
    });

    const prepareData = (currencies: any): ISliderDataItem[] => {
        return [
            {
                name: "pada",
                translation: pada,
                value: currencies?.PADA?.balance?.toFixed(PADA_DASHBOARD_PRECISION_UI).replace(/\.?0+$/, '') || 0,
                icon: "flat/pada-gray",
                bgIcon: "colorful/pada"
            },
            {
                name: "amber",
                translation: amber,
                value: currencies?.amber?.balance?.available || 0,
                image: "/img/amber.svg",
            },
            {
                name: "usdt",
                translation: "usdt",
                value: currencies?.[CURRENCIES.USDT]?.balance?.toFixed(USDT_PRECISION_UI).replace(/\.?0+$/, '') || 0,
                icon: "colorful/usdt",
                bgIcon: "colorful/usdt"
            }
        ];
    }

    useEffect(() => {
        if (!currencies) return;

        const data = prepareData(currencies);
        setPreparedData(data);
    }, [currencies])

    useEffect(() => {
        if (isJSON(meta)) {
            setUserMeta(JSON.parse(meta));
        }
    }, [meta])

    return (
        <div className={`${styles.balances} ${isInventory ? styles['balances--inventory'] : ''} ${wrapperClassName ? wrapperClassName : ''}`}>
            <Text
                tag="h4"
                size="x-const-subtitle"
                weight="400"
                transform="uppercase"
                fontFamily="copperplate"
                className="margin--b-24"
            >
                {myBalances}
                <Text
                    tag="span"
                    size="x-const-subtitle"
                    transform="uppercase"
                    weight="400"
                    className={`${styles['balances__title-mobile']} ${isInventory ? styles['balances__title-mobile--inventory'] : ''}`}
                >
                    {` (${preparedData?.length})`}
                </Text>
            </Text>
            {
                status && userMeta?.connectAddress && externalId ? (
                    <>
                        <div className={`${styles.balances__content} ${isInventory ? styles['balances__content--inventory'] : ''} overflow--hidden ${contentClassName ? contentClassName : ''}`}>
                            {
                                currencies?.[CURRENCIES.AMBER] ? (
                                    <div className={`${styles['balances__item-wrapper']} ${styles[`balances__item-wrapper--${CURRENCIES.AMBER}`]} padding--24`}>
                                        <Text
                                            size="small"
                                            weight="400"
                                            transform="uppercase"
                                            fontFamily="copperplate"
                                            className="margin--b-8"
                                        >
                                            {currencies?.[CURRENCIES.AMBER]?.currency}
                                        </Text>
                                        <div className="fl fl--align-c">
                                            <Text
                                                size="x-const"
                                                weight="400"
                                                transform="uppercase"
                                                fontFamily="copperplate"
                                                className={`${styles['balances__amount-text']} margin--r-12`}
                                            >
                                                {currencies?.[CURRENCIES.AMBER]?.balance?.available}
                                            </Text>
                                            <Image
                                                alt="rubin"
                                                src={rubinImg}
                                                width={24}
                                                height={24}
                                                className={styles.balances__img}
                                            />
                                        </div>
                                        <Image
                                            alt="rubin"
                                            src={rubinImg}
                                            width={59}
                                            height={59}
                                            className={`${styles['balances__amber-img']} ${styles['balances__amber-img--1']}`}
                                        />
                                        <Image
                                            alt="rubin"
                                            src={rubinImg}
                                            width={49}
                                            height={49}
                                            className={`${styles['balances__amber-img']} ${styles['balances__amber-img--2']}`}
                                        />
                                        <Image
                                            alt="rubin"
                                            src={rubinImg}
                                            width={39}
                                            height={39}
                                            className={`${styles['balances__amber-img']} ${styles['balances__amber-img--3']}`}
                                        />
                                        <Image
                                            alt="rubin"
                                            src={rubinImg}
                                            width={60}
                                            height={60}
                                            className={`${styles['balances__amber-img']} ${styles['balances__amber-img--4']}`}
                                        />
                                        <div className={`${styles['balances__divider']} margin--t-24 width--100`} />
                                    </div>
                                ) : null
                            }
                            {
                                currencies?.PADA ? (
                                    <div className={`${styles['balances__item-wrapper']} ${styles[`balances__item-wrapper--${CURRENCIES.PADA}`]}`}>
                                        <Text
                                            size="small"
                                            weight="400"
                                            transform="uppercase"
                                            fontFamily="copperplate"
                                            className="margin--b-8"
                                        >
                                            {currencies?.PADA?.symbol}
                                        </Text>
                                        <div className="fl fl--align-c">
                                            <Text
                                                size="x-const"
                                                weight="400"
                                                transform="uppercase"
                                                fontFamily="copperplate"
                                                className={`${styles['balances__amount-text']} margin--r-12`}
                                            >
                                                {currencies?.PADA?.balance?.toFixed(PADA_DASHBOARD_PRECISION_UI).replace(/\.?0+$/, '')}
                                            </Text>
                                            <Icon icon="flat/pada-gray" size={24} />
                                        </div>
                                        <Icon
                                            icon="colorful/pada"
                                            size={78}
                                            classNames={{
                                                wrapper: `${styles['balances__pada-icon']} ${styles['balances__pada-icon--1']}`
                                            }}
                                        />
                                        <Icon
                                            icon="colorful/pada"
                                            size={32}
                                            classNames={{
                                                wrapper: `${styles['balances__pada-icon']} ${styles['balances__pada-icon--2']}`
                                            }}
                                        />
                                        <Icon
                                            icon="colorful/pada"
                                            size={50}
                                            classNames={{
                                                wrapper: `${styles['balances__pada-icon']} ${styles['balances__pada-icon--3']}`
                                            }}
                                        />
                                        <div className={`${styles['balances__divider']} margin--t-24 width--100`} />
                                    </div>
                                ) : null
                            }
                            {
                                currencies?.[CURRENCIES.USDT] ? (
                                    <div className={`${styles['balances__item-wrapper']} ${styles[`balances__item-wrapper--${CURRENCIES.USDT}`]} padding--24`}>
                                        <Text
                                            size="small"
                                            weight="400"
                                            transform="uppercase"
                                            fontFamily="copperplate"
                                            className="margin--b-8"
                                        >
                                            {currencies?.[CURRENCIES.USDT]?.symbol}
                                        </Text>
                                        <div className="fl fl--align-c">
                                            <Text
                                                size="x-const"
                                                weight="400"
                                                transform="uppercase"
                                                fontFamily="copperplate"
                                                className={`${styles['balances__amount-text']} margin--r-12`}
                                            >
                                                {currencies?.[CURRENCIES.USDT]?.balance?.toFixed(USDT_PRECISION_UI).replace(/\.?0+$/, '')}
                                            </Text>
                                            <Icon icon="colorful/usdt" size={24} />
                                        </div>
                                        <Icon
                                            icon="colorful/usdt"
                                            size={64}
                                            classNames={{
                                                wrapper: `${styles['balances__usdt-icon']} ${styles['balances__usdt-icon--1']}`
                                            }}
                                        />
                                        <Icon
                                            icon="colorful/usdt"
                                            size={28}
                                            classNames={{
                                                wrapper: `${styles['balances__usdt-icon']} ${styles['balances__usdt-icon--2']}`
                                            }}
                                        />
                                        <Icon
                                            icon="colorful/usdt"
                                            size={44}
                                            classNames={{
                                                wrapper: `${styles['balances__usdt-icon']} ${styles['balances__usdt-icon--3']}`
                                            }}
                                        />
                                        <Icon
                                            icon="colorful/usdt"
                                            size={37}
                                            classNames={{
                                                wrapper: `${styles['balances__usdt-icon']} ${styles['balances__usdt-icon--4']}`
                                            }}
                                        />
                                    </div>
                                ) : null
                            }
                        </div>
                        <div className={`${styles['balances__content-mobile']} ${isInventory ? styles['balances__content-mobile--inventory'] : ''} ${contentClassName ? contentClassName : ''}`}>
                            <BalancesSlider
                                setCurrentSlide={setCurrentSlide}
                                preparedData={preparedData}
                                sliderRef={sliderRef}
                            />
                            <div className={`${styles['balances__slider-controls']} ${isInventory ? styles['balances__slider-controls--inventory'] : ''} margin--t-24`}>
                                <Icon
                                    icon="colorful/prev-arrow"
                                    size={16}
                                    classNames={{
                                        icon: `${styles['balances__slider-controls-arrow']} ${currentSlide <= 0 ? styles['balances__slider-controls-arrow--disabled'] : 'cursor--custom-pointer'}`
                                    }}
                                    onClick={() => sliderRef.current?.slickPrev()}
                                />
                                <div className="fl fl--align-c fl--gap-8">
                                    {Array.from({ length: isTablet ? preparedData.length : preparedData.length - 1 }).map((_, index) => (
                                        <span
                                            key={index}
                                            className={`${styles['balances__slider-controls-indicator']} ${index === currentSlide ? styles['balances__slider-controls-indicator--active'] : ''}`}
                                        />
                                    ))}
                                </div>
                                <Icon
                                    icon="colorful/next-arrow"
                                    size={16}
                                    classNames={{
                                        icon: `${styles['balances__slider-controls-arrow']} ${currentSlide >= preparedData.length - 2 ? styles['balances__slider-controls-arrow--disabled'] : 'cursor--custom-pointer'}`
                                    }}
                                    onClick={() => sliderRef.current?.slickNext()}
                                />
                            </div>
                        </div>
                    </>
                ) : !externalId && userMeta?.connectAddress ? (
                    <div className={`${styles['balances__connect-wallet-card']} ${styles['balances__connect-wallet-card--restore-wallet']} ${isInventory ? styles['balances__connect-wallet-card--inventory'] : ''}`}>
                        <Text
                            tag="h4"
                            size="x-const-subtitle"
                            weight="400"
                            transform="uppercase"
                            fontFamily="copperplate"
                            className="margin--b-8"
                        >
                            {deployingWalletTitle}
                        </Text>
                        <Text
                            size="small"
                            color="extra-gray"
                            fontFamily="ubuntu"
                        >
                            {deployingWalletText}
                        </Text>
                    </div>
                ) : !externalId && !userMeta?.connectAddress ? (
                    <div className={`${styles['balances__connect-wallet-card']} ${isInventory ? styles['balances__connect-wallet-card--inventory'] : ''}`}>
                        <div className={`${styles['balances__connect-wallet-card-text-wrapper']} ${isInventory ? styles['balances__connect-wallet-card-text-wrapper--inventory'] : ''}`}>
                            <Text
                                tag="h4"
                                size="x-const-subtitle"
                                weight="400"
                                transform="uppercase"
                                fontFamily="copperplate"
                                className="margin--b-8"
                            >
                                {createWalletTitle}
                            </Text>
                            <Text
                                size="small"
                                color="extra-gray"
                                fontFamily="ubuntu"
                            >
                                {createWalletText}
                            </Text>
                        </div>
                        <Button
                            size="narrow"
                            onClick={() => setIsConnectWalletModalActive(true)}
                            className={`${styles['balances__connect-wallet-card-button']} ${isInventory ? styles['balances__connect-wallet-card-button--inventory'] : ''}`}
                        >
                            {apply}
                        </Button>
                    </div>
                ) : type === USER_WALLET_TYPES.WEB2 ? (
                    <div className={`${styles['balances__connect-wallet-card']} ${styles['balances__connect-wallet-card--restore-wallet']} ${isInventory ? styles['balances__connect-wallet-card--inventory'] : ''}`}>
                        <div className={`${styles['balances__connect-wallet-card-text-wrapper']} ${isInventory ? styles['balances__connect-wallet-card-text-wrapper--inventory'] : ''}`}>
                            <Text
                                tag="h4"
                                size="x-const-subtitle"
                                weight="400"
                                transform="uppercase"
                                fontFamily="copperplate"
                                className="margin--b-8"
                            >
                                {restoreWalletTitle}
                            </Text>
                            <Text
                                size="small"
                                color="extra-gray"
                                fontFamily="ubuntu"
                            >
                                {restoreWalletText}
                            </Text>
                        </div>
                        <Button
                            size="narrow"
                            onClick={() => setIsConnectWalletModalActive(true)}
                            className={`${styles['balances__connect-wallet-card-button']} ${isInventory ? styles['balances__connect-wallet-card-button--inventory'] : ''}`}
                        >
                            {restore}
                        </Button>
                    </div>
                ) : type === USER_WALLET_TYPES.WEB3 ? (
                    <div className={`${styles['balances__connect-wallet-card']} ${styles['balances__connect-wallet-card--restore-wallet']} ${isInventory ? styles['balances__connect-wallet-card--inventory'] : ''}`}>
                        <div className={`${styles['balances__connect-wallet-card-text-wrapper']} ${isInventory ? styles['balances__connect-wallet-card-text-wrapper--inventory'] : ''}`}>
                            <Text
                                tag="h4"
                                size="x-const-subtitle"
                                weight="400"
                                transform="uppercase"
                                fontFamily="copperplate"
                                className="margin--b-8"
                            >
                                {restoreWalletTitle}
                            </Text>
                            <Text
                                size="small"
                                color="extra-gray"
                                fontFamily="ubuntu"
                            >
                                {restoreWalletText}
                            </Text>
                        </div>
                        <Button
                            size="narrow"
                            onClick={() => setIsConnectWalletModalActive(true)}
                            className={`${styles['balances__connect-wallet-card-button']} ${isInventory ? styles['balances__connect-wallet-card-button--inventory'] : ''}`}
                        >
                            <div className={styles['dashboard__metamask-icon']} />
                            {restore}
                        </Button>
                    </div>
                ) : null
            }
        </div>
    )
}

export default withCryptoBalances(ClientBalances);
