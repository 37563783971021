import { create } from 'zustand';

// State types
interface OauthState {
    isAuthorized?: boolean,
    id?: string,
    email?: string,
    set: (entity: any) => void;
}

const useOauthStore = create<OauthState>()(
    (set) => ({
        set: (entity: any) => set(() => {
            return { ...entity };
        })
    })
);

export {
    useOauthStore
};