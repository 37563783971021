import { UrlObject } from "url";

const getFullPath = (uri: string, lang: string, options?: UrlObject) => {
    const fullPath = uri.includes('https') ? uri : (uri.startsWith('#') ? uri : `/${lang}/${uri}`);

    if (!options) return fullPath;
    
    return {
        pathname: fullPath,
        ...(options ? { ...options } : {})
    }
};

export default getFullPath;
