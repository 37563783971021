import axios from "axios";

const getUserDetails = async (): Promise<any> => {
  try {
    const response = await axios('/api/user',
      {
        method: 'GET'
      }
    );

    return response?.data;
  } catch (e) {
    throw e;
  }
}

export default getUserDetails;
